export const alertSliceMobileView = (set) => ({
  alertSliceMobile: {
    view: false,
    forUser: 'no user',
  },

  setAlertSliceMobile: () =>
    set(() => ({ alertSliceMobile: { view: true, forUser: 'no user' } })),
  clearAlertSliceMobile: () =>
    set(() => ({ alertSliceMobile: { view: false, forUser: false } })),
  clearAlertSliceMobileForUser: () =>
    set(() => ({ alertSliceMobile: { view: false, forUser: 'user' } })),

  alertTurnScreenMobile: {
    view: false,
    forUser: 'no user',
  },
  setAlertTurnScreenMobile: () =>
    set(() => ({ alertTurnScreenMobile: { view: true, forUser: 'no user' } })),
  clearAlertTurnScreenMobile: () =>
    set(() => ({ alertTurnScreenMobile: { view: false, forUser: false } })),
  clearAlertTurnScreenMobileForUser: () =>
    set(() => ({ alertTurnScreenMobile: { view: false, forUser: 'user' } })),
});

import React from 'react';

const Topbar = () => {
  return (
    <div
      role='banner'
      className='flex bg-blue-500 h-[2.2rem] justify-center items-center lg:pl-0'
      data-testid='topbarSection'>
      <p className='text-white text-[12.5px] fontGTAmericanR mb-0 lg:ml-48'>
        Usa buying group site.
      </p>
    </div>
  );
};

export default Topbar;

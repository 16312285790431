import { Spin } from 'antd';
import { useEffect } from 'react'
import cookie from 'react-cookies';
import { useLocation } from 'react-router-dom';
import useLogout from '../api/services/logout';
import useStatesGlobalStore from '../hooks/useStatesGlobalStore';

const RedirectDealDetailWeweb = () => {

    const location = useLocation();
    const mutation = useLogout();
    const { authInfo, setAuthInfo, setBuyerInfo, setDashboardInfo } =
        useStatesGlobalStore();

    useEffect(() => {
        const activeSesion = cookie.load('token') ? true : false;
        if (!activeSesion) {
            mutation.mutate();

            setAuthInfo({
                ...authInfo,
                state: 'sessionDenied',
            });
            setBuyerInfo([]);
            setDashboardInfo([]);

            cookie.remove('token');
            if (process.env.REACT_APP_TESTING_DEV !== 'development') {
                window.location.href = 'https://beta.usabuying.group/';
            }  else {
                window.location.href = 'https://34695694-59b3-4211-b6b0-fdc2f88d32ae-staging.weweb-preview.io';
            }
            return;
        }

        const searchParams = new URLSearchParams(location.search);
        const id = searchParams.get('id');

        window.location.href = `${process.env.REACT_APP_URL_NEW_BUYERS}/deals?token=${cookie.load('token')}&id=${id}`;
    }, [])

    return (
        <div className='flex justify-center items-center h-screen'>
            <Spin size="large" />
        </div>
    );
}

export default RedirectDealDetailWeweb
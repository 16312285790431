// import { color } from '../../dataSimulator/index';
// import useAddData from '../../../hooks/useAddData';

const buildObject = (item: any) => {
  let linkBestBuy = '';
  let linkAmazon = '';
  let linkWalmart = '';
  const colorDeal: any = [];
  const quantityUser: any = [];
  let alreadyCommittedStyleFlag = false;
  let realStores: any[] = [];
  const alreadyCommitted = item.variations.reduce(
    (total: number, current: any) => total + parseInt(current.buyer_commit),
    0,
  );

  const commitLimit = item.variations.reduce(
    (total: number, current: any) => total + parseInt(current.commit_limit),
    0,
  );

  if (alreadyCommitted === commitLimit) {
    alreadyCommittedStyleFlag = true;
  } else {
    alreadyCommittedStyleFlag = false;
  }

  item?.variations?.map((variation: any) => {
    quantityUser.push({
      color: variation.name,
      amount: variation.buyer_commit,
      id: variation.id,
      fullyCommitted: variation.buyer_commit === variation.commit_limit,
      commit_limit: variation.commit_limit,
      locked_by_time: variation.locked_by_time
    });

    colorDeal.push(variation.name);

    variation?.stores?.map((store: any) => {
      if (store.name === 'Best Buy') {
        linkBestBuy = store.link;
      } else if (store.name === 'Amazon') {
        linkAmazon = store.link;
      } else if (store.name === 'Walmart') {
        linkWalmart = store.link;
      }
      realStores.push(store);
    });
  });

  return {
    ...item,
    item: {
      image: item.image,
      marc: item.brand_name,
      description: item.title,
      color: colorDeal,
    },
    shopNow: [
      '/images/walmart.jpg',
      '/images/bestbuy.jpg',
      '/images/amazon.png',
    ],
    realStores: realStores,
    stores: { linkBestBuy, linkAmazon, linkWalmart },
    price: item.price,
    quantityUser: quantityUser,
    id: item.id,
    weReimburse: item.variations[0].list_price,
    dealExpires: item.expiration_date,
    enabled: alreadyCommittedStyleFlag,
    variations: item.variations,
    banner_text: item.banner_text,
    locked: item.locked,
    days_to_expire: item.days_to_expire,
    is_fully_committed: item.is_fully_committed,
  };
};

export default buildObject;

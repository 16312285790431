import { useContext } from 'react';
import { useQuery } from 'react-query';
import listPayeesName from '../../endPoints/paymentRequest/listPayeesName';
import transformDataListPayeesName, {
  IServiceTransform,
} from '../../transforms/paymentRequest/transformDataListPayeesName';
import { AuthContext } from '../../../context/useSaveDataSession';
interface IResponseService {
  response: IServiceTransform | undefined;
  error: any;
  isLoading: boolean;
  refetch: () => void;
}

const getListPayeesName = (): IResponseService => {
  const { logout } = useContext<any>(AuthContext);

  const { error, data, isLoading, refetch } = useQuery(
    ['LIST_PAYEES_PAYMENTS', listPayeesName],
    listPayeesName,
    {
      onSuccess: (data) => {
        if (data.message === 'Token has expired') {
          logout();
        } else if (data.status === 401) {
          logout();
        }
      },
    },
  );
  if (data) {
    return {
      error,
      response: transformDataListPayeesName(data),
      isLoading,
      refetch,
    };
  } else {
    return { error, response: data, isLoading, refetch };
  }
};

export default getListPayeesName;

import React, { useState } from 'react';
import BreadCrumbs from '../../components/UI/breadCrumbs';
import {
  breadCrumbsQuickGuide,
  ColorlibConnector,
  ColorlibStepIcon,
  guideTitles,
} from './constants';
// import { SearchOutlined } from '@ant-design/icons';
import CardContent from '../../components/UI/cardContent';
import { Button, Stack, StepLabel, Step, Stepper } from '@mui/material';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import useResponsive from '../../hooks/useResponsive';
import globalStoreNoLocalStore from '../../hooks/globalStoreNoLocalStore';

const QuickGuideModule = () => {
  const [activeStep, setActiveStep] = React.useState<any>(0);
  const maxSteps = guideTitles.length;
  const handleBack = () => {
    setActiveStep((prevActiveStep: any) => prevActiveStep - 1);
    setNewPicture('picture1');
  };
  const handleNext = () => {
    setActiveStep((prevActiveStep: any) => prevActiveStep + 1);
    setNewPicture('picture1');
  };

  const [newPicture, setNewPicture] = useState<string>('picture1');

  const handleSection = (param: any) => {
    if (
      param === 'Tracking Numbers' ||
      param === 'Purchase Orders' ||
      param === 'My Account'
    ) {
      // setNewPicture(true);
    }
  };

  const { isMobile } = useResponsive();

  const {
    alertSliceMobile,
    alertTurnScreenMobile,
    clearAlertSliceMobile,
    clearAlertTurnScreenMobile,
  } = globalStoreNoLocalStore();

  React.useEffect(() => {
    if (isMobile) {
      clearAlertSliceMobile();
      clearAlertTurnScreenMobile();
    }
  }, [alertSliceMobile, alertTurnScreenMobile]);

  return (
    <>
      <div className='flex justify-between items-center mt-5 mb-10'>
        <div className=' text-gray-400'>
          <BreadCrumbs links={breadCrumbsQuickGuide} />
        </div>
        {/* <div className='flex gap-4'>
          <SearchOutlined
            style={{
              position: 'absolute',
              marginTop: '28px',
              height: '20px',
              marginLeft: '25%',
            }}
          />
          <span className='text-sm m-auto fontSharpGrotesk22Medium'>
            search for anything
          </span>
          <input
            type='text'
            placeholder='type here...'
            className='rounded-3xl p-6 font-semibold text-black w-[22rem]'
          />
        </div> */}
      </div>
      <main>
        <CardContent
          styles={
            'flex-column justify-between rounded-3xl p-[2.5rem] h-[47rem] w-[92.5rem] space-x-14'
          }>
          <header className='flex h-[7.25rem] justify-between items-start'>
            <Button
              data-testid='backButtonGuide'
              onClick={handleBack}
              disabled={activeStep === 0}
              sx={{
                marginLeft: '4rem',
                color: '#FFFFFF',
                '&:hover': {
                  backgroundColor: '#FFFFFF',
                },
              }}>
              <ArrowBackIosNewRoundedIcon
                fontSize='large'
                sx={{
                  color: '#000000',
                }}
              />
            </Button>
            <div className='flex flex-col'>
              <h1 className='font-semibold text-[2rem] ml-[8rem]'>
                {guideTitles[activeStep].name}
              </h1>
              <Stack sx={{ width: '122%', display: 'flex' }} spacing={4}>
                <Stepper
                  activeStep={activeStep}
                  alternativeLabel
                  connector={<ColorlibConnector />}>
                  {guideTitles.map((item, index) => (
                    <Step key={index}>
                      <StepLabel
                        StepIconComponent={ColorlibStepIcon}></StepLabel>
                    </Step>
                  ))}
                </Stepper>
              </Stack>
            </div>
            <Button
              data-testid='nextButtonGuide'
              onClick={handleNext}
              disabled={activeStep === maxSteps - 1}
              sx={{
                marginRight: '2rem',
                color: '#FFFFFF',
                '&:hover': {
                  backgroundColor: '#FFFFFF',
                },
              }}>
              <ArrowForwardIosRoundedIcon
                fontSize='large'
                sx={{
                  color: '#000000',
                }}
              />
            </Button>
          </header>
          <hr className='mb-6 mt-[-0.5rem] p-1 w-[94%]' />
          <div className='flex   h-[11rem]'>
            <div className='flex-col mt-[4rem]'>
              <section
                className={`justify-start mb-3 w-[18rem] hover:shadow-lg py-[1rem] pl-[2rem] hover:rounded-2xl h-auto cursor-pointer ${
                  newPicture === 'picture1' && 'border rounded-2xl shadow-lg'
                }`}
                onClick={() => setNewPicture('picture1')}>
                <h2 className='text-[#178DFF] fontGTAmericanR font-semibold'>
                  {guideTitles[activeStep].title}
                </h2>
                <p className='text-[000000] fontGTAmericanR w-[14.75rem]'>
                  {guideTitles[activeStep].paragraph}
                </p>
              </section>
              {guideTitles[activeStep].title2 && (
                <section
                  className={`justify-start mb-3 w-[18rem] hover:shadow-lg pt-[1rem] pl-[2rem] hover:rounded-2xl h-[9rem] cursor-pointer ${
                    newPicture === 'picture2' && 'border rounded-2xl shadow-lg'
                  }`}
                  onClick={() => setNewPicture('picture2')}>
                  <h2 className='text-[#178DFF] fontGTAmericanR font-semibold'>
                    {guideTitles[activeStep].title2}
                  </h2>
                  <p className='text-900000] fontGTAmericanR w-[14.75rem]'>
                    {guideTitles[activeStep].paragraph2}
                  </p>
                </section>
              )}
              {guideTitles[activeStep].title3 && (
                <section
                  className={`justify-start mb-3 w-[18rem] hover:shadow-lg pt-[1rem] pl-[2rem] hover:rounded-2xl h-[9rem] cursor-pointer ${
                    newPicture === 'picture3' && 'border rounded-2xl shadow-lg'
                  }`}
                  data-testid='changePicture'
                  onClick={() => {
                    handleSection(guideTitles[activeStep].name);
                    setNewPicture('picture3');
                  }}>
                  <h2 className='text-[#178DFF] fontGTAmericanR font-semibold'>
                    {guideTitles[activeStep].title3}
                  </h2>
                  <p className='text-[000000] fontGTAmericanR w-[14.75rem]'>
                    {guideTitles[activeStep].paragraph3}
                  </p>
                </section>
              )}
            </div>

            <picture
              data-testid={`${newPicture ? 'photo2' : ''}`}
              className='rounded-2xl border-black border-2 h-[27.5rem] w-[54rem] ml-[6rem] mt-[3rem] bg-cover'>
              {newPicture === 'picture1' && guideTitles[activeStep].picture}
              {newPicture === 'picture2' && guideTitles[activeStep].picture2}
              {newPicture === 'picture3' && guideTitles[activeStep].picture3}
            </picture>
          </div>
        </CardContent>
      </main>
    </>
  );
};

export default QuickGuideModule;

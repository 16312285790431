import React, { useEffect, useState } from 'react';
// TODO: WHEN IT'S NEEDED
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { Tag } from 'antd';
// import { Popover as PopoveAnt } from 'antd';
import { Divider, Popover, Stack, Button } from '@mui/material';
import { Link } from 'react-router-dom';
import { ColumnsType } from 'antd/es/table';
import { useCart } from 'react-use-cart';
import { themeCustom } from '../../../theme';
import useQuickCommit from '../../../api/services/activeDeals/quickCommit';
import { useQueryClient } from 'react-query';
import usePriceFormat from '../../../hooks/usePriceFormat';
import GeneralAlert from '../../../components/UI/generalAlert/GeneralAlert';
import { IAlertType } from '../../../interfaces/activeDeal';
import { notifyImpedimentToIncrease, validateExpires } from '../../../helpers';
import {
  dateFormatSorter,
  handleGeneralCopyClick,
} from '../../../helpers/index';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
// import useStatesGlobalStore from '../../../hooks/useStatesGlobalStore';
import useResponsive from '../../../hooks/useResponsive';
import getFilterSetService from '../../../api/services/activeDeals/getFilterSet';

const { colors } = themeCustom;

const RenderActions = (item: any) => {
  const { addItem } = useCart();
  const queryClient = useQueryClient();
  const [variationQty, setVariationQty] = useState<any>({
    id: '',
    qty: 0,
  });

  const [variantQtyList, setVariantQtyList] = useState<any>([]);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null,
  );
  const [isNotAllowToDecrease, setIsNotAllowToDecrease] = useState(false);
  const [showAlert, setShowAlert] = useState<IAlertType>({
    message: '',
    show: false,
    title: '',
    type: 'success',
  });
  const [newValue, setnewValue] = useState(0);
  const addnew = (item: any) => {
    addItem(item);
  };

  const { mutate, isLoading } = useQuickCommit();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const handleChange = (
    e: any,
    variationId: any,
    currentAmount: any,
    commit_limit: any,
    lockedByTime: boolean
  ) => {
    if (e.target.value > commit_limit) {
      setShowAlert({
        show: true,
        message: `The maximum you can commit for this item is: ${commit_limit} `,
        title: 'Error',
        type: 'warning',
      });
      setTimeout(() => {
        setShowAlert({ ...showAlert, show: false });
      }, 3000);

      setVariationQty({ ...variationQty, id: variationId, qty: '' });

      return;
    }

    // if (e.target.value < currentAmount) {
    //   setShowAlert({
    //     show: true,
    //     message: `You've already committed to this item , to decrease contact help support. `,
    //     title: 'Error',
    //     type: 'warning',
    //   });
    //   setTimeout(() => {
    //     setShowAlert({ ...showAlert, show: false });
    //   }, 3000);

    //   setVariationQty({ ...variationQty, id: variationId, qty: '' });

    //   return;
    // }

    notifyImpedimentToIncrease(
      e.target.value,
      currentAmount,
      setIsNotAllowToDecrease,
      setShowAlert,
      lockedByTime
    );
    setVariationQty({ ...variationQty, id: variationId, qty: e.target.value });
  };

  useEffect(() => {
    if (variantQtyList.length > 0 && variationQty.id.length !== 0) {
      const response = variantQtyList.filter(
        (variant: any) => variant.id !== variationQty.id,
      );

      if (response.length > 0) {
        variationQty.qty.length !== 0 || variationQty.qty === '0'
          ? setVariantQtyList([...response, variationQty])
          : setVariantQtyList([...response]);
      } else {
        variationQty.qty.length !== 0
          ? setVariantQtyList([variationQty])
          : setVariantQtyList([]);
      }
    } else {
      variationQty.qty.length !== 0
        ? setVariantQtyList([...variantQtyList, variationQty])
        : setVariantQtyList([]);
    }
  }, [variationQty.qty, variationQty.id]);

  useEffect(() => {
    if (!open) {
      setVariantQtyList([]);
    }
  }, [open]);

  const handleQuickCommit = async () => {
    mutate(
      { commitsData: { commits: variantQtyList }, dealId: item.item.id },
      {
        onSuccess: (data) => {
          if (data?.status) {
            setAnchorEl(null);
            setShowAlert({
              show: true,
              message: data?.error
                ? data?.error
                : 'You have committed to this deal successfully',
              title: data?.error ? 'Error' : 'Success',
              type: data?.error ? 'warning' : 'success',
            });
            setTimeout(() => {
              setShowAlert({ ...showAlert, show: false });
            }, 3000);

            setVariantQtyList([]);
            setnewValue(Number(variationQty.qty));
            // TODO: REVIEW RENDER TO SHOW NEW DATA
            queryClient.invalidateQueries('ACTIVEDEALS');
          } else if (!data?.status) {
            setVariantQtyList([]);
            setShowAlert({
              show: true,
              message: data?.error,
              title: 'Error',
              type: 'warning',
            });
            setTimeout(() => {
              setShowAlert({ ...showAlert, show: false });
            }, 3000);
          }
        },
        onError: (error: any) => {
          setShowAlert({
            show: true,
            message: error,
            title: 'Error',
            type: 'error',
          });
          setTimeout(() => {
            setShowAlert({ ...showAlert, show: false });
          }, 3000);
        },
      },
    );
  };

  return (
    <div>
      {showAlert.show && (
        <GeneralAlert
          alertType={showAlert.type}
          alertMessage={showAlert.message}
          alertTitle={showAlert.title}
          setShowAlert={setShowAlert}
          fontSize={17}
        />
      )}
      {item?.item.enabled ||
      item.item.is_fully_committed ||
      item?.item?.days_to_expire < 0 ? (
        <p className='text-xs text-left flex cursor-default items-center'>
          <img
            className='ml-1'
            src='images/activeDeals/blockedDealIcon.svg'
            alt='blocked deal icon'
            width='16'
            height='22'
          />
          <span className='ml-5 opacity-70 w-32 fontGTAmericanR xl3:text-[0.8rem]'>
            {(item.item.is_fully_committed || item.item.locked) &&
              'No More Commitments Allowed'}
            {item.item.enabled &&
              !item.item.is_fully_committed &&
              "You've already committed to this deal"}
            {item?.item?.days_to_expire < 0 &&
              !item.item.locked &&
              !item.item.is_fully_committed &&
              'Deal Expired'}
          </span>
        </p>
      ) : (
        <div>
          <button
            disabled={item?.item?.days_to_expire < 0 || item?.item?.locked}
            data-testid={`quickCommitButton#${item.item.id}`}
            className='text-xs text-left flex mb-3 '
            onClick={handleClick}>
            <img
              className='ml-1'
              src='images/activeDeals/greyQuick.svg'
              alt='quick commit icon'
              width='17'
              height='16'
            />
            <span className='ml-5 w-32 fontGTAmericanR xl3:text-[0.8rem]'>
              Quick Commit
            </span>
          </button>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}>
            <Stack
              sx={{
                borderRadius: 3,
                padding: 2,
              }}>
              <span className='fontGTAmericanR ml-4'>
                Set commitments per color
              </span>
              <Divider
                sx={{ backgroundColor: '#f2f2f5', marginY: 1, marginBottom: 2 }}
              />
              {item.item.quantityUser?.map((item: any, index: any) => (
                <div key={index}>
                  {item.fullyCommitted && (
                    <p className=' text-xs bg-red-100 font-bold p-[2px] mb-1 text-center rounded-[1px] w-56'>
                      You've already committed to this item's variation.
                    </p>
                  )}
                  <div
                    className='flex w-full items-center justify-center'
                    key={index}>
                    <p className='text-black text-sm text-left w-6/12'>
                      {item.color}
                    </p>
                    <div className='flex items-center gap-5 mb-4 w-48'>
                      <input
                        name={item.color}
                        max={item.commit_limit}
                        defaultValue={item.amount}
                        className='border border-gray-400 w-[4rem] rounded-lg p-2 text-left'
                        data-testid={`quickCommitInput#${item.id}`}
                        min={item.locked_by_time ? (newValue > 0 ? newValue : item.amount) : 0}
                        disabled={
                          item.fullyCommitted ||
                          item.is_buyer_commit_editable ||
                          item.locked
                        }
                        aria-label={'input item amount'}
                        type='number'
                        onChange={(e: any) =>
                          handleChange(
                            e,
                            item.id,
                            item.amount,
                            item.commit_limit,
                            item.locked_by_time,
                          )
                        }
                      />
                    </div>
                  </div>
                </div>
              ))}
              <Button
                disabled={
                  isLoading ||
                  item.fullyCommitted ||
                  isNotAllowToDecrease ||
                  variantQtyList.length === 0 || 
                  (item.locked_by_time  && Number(variationQty.qty) < newValue)
                }
                variant='outlined'
                onClick={handleQuickCommit}
                sx={{
                  border: '1.5px solid',
                  borderRadius: 2,
                  textTransform: 'none',
                  fontWeight: 'bold',
                  '&:hover': {
                    backgroundColor: '#0069d9',
                    borderColor: '#0062cc',
                    boxShadow: 'none',
                    color: colors.white,
                  },
                }}>
                Commit
              </Button>
            </Stack>
          </Popover>
        </div>
      )}
      <Link
        to={`/active-deal/${item.item.id}`}
        state={item?.item}
        className='flex text-xs text-left '>
        <img
          className='ml-1 mb-3'
          src='images/activeDeals/greyEye.svg'
          alt='quick commit icon'
          width='17'
          height='16'
        />
        <p className='mb-3 ml-5 fontGTAmericanR xl3:text-[0.8rem]'>View Item</p>
      </Link>
      <button
        disabled={
          (item?.item?.days_to_expire >= 0 ? false : true) ||
          item?.item?.enabled ||
          item?.item?.locked
        }
        className='flex text-xs text-left customButtonDisabled items-center'
        data-testid='AddDealToCart'
        onClick={() => {
          addnew(item.item);
        }}>
        <img
          className='ml-1'
          src='images/activeDeals/greyCar.svg'
          alt='quick commit icon'
          width='17'
          height='16'
        />
        <p className=' ml-5 fontGTAmericanR mb-0 xl3:text-[0.8rem]'>
          Add to Deals Cart
        </p>
      </button>
    </div>
  );
};

export const dataTableFields: ColumnsType<any> = [
  {
    title: 'Item',
    key: 'item',
    width: window.screen.width > 2500 ? '50rem' : '30rem',
    className: '',
    sorter: (a: any, b: any) => {
      return a.item.description.length - b.item.description.length;
    },
    render: (item: any) => {
      const transfomImages = item?.item?.image?.split('//')[1];

      return (
        <>
          <div
            className='w-[90px] xl4:w-[120px] h-[90px] xl4:h-[120px] p-1 float-left mr-8'
            style={{ boxSizing: 'border-box' }}>
            <img
              className='w-full h-full object-contain'
              src={`https://${transfomImages}`}
              alt='product image'
            />
          </div>
          {item.banner_text && (
            <div className='ml-6'>
              <p className='p-1.5 text-[0.5rem] text-white uppercase absolute rounded-md bg-orange-600 left-0 top-5 text-center '>
                {item.banner_text}
              </p>
            </div>
          )}
          <aside
            className='opacity-40 text-left xl3:text-[0.9rem] xl4:text-[1rem] fontGTAmericanR font-semibold'
            aria-label='product-brand'
            title='brand'>
            {item.item.marc}
          </aside>
          <div className=''>
            <aside
              className='mr-8 text-left text-[13px] xl3:text-[1rem] xl4:text-[1.1rem] fontGTAmericanR mt-1 truncate-text font-semibold '
              aria-label='product-description'
              title='description'>
              {item.item.description}
            </aside>
            <aside
              className='opacity-40 text-left text-[13px] xl3:text-[14px] xl4:text-[15px] mt-2 fontGTAmericanR'
              aria-label='product-color'
              title='color'>
              Colors: {item.item.color.join(', ')}
            </aside>
          </div>
        </>
      );
    },
  },
  {
    title: 'Price',
    dataIndex: 'weReimburse',
    key: 'weReimburse',
    width: window.screen.width > 1600 ? '12rem' : '10rem',
    className: 'fontGTAmericanR ',
    sorter: (a: any, b: any) => {
      return a.weReimburse - b.weReimburse;
    },
    render: (weReimburse) => {
      return (
        <p className='fontGTAmericanR font-semibold xl3:text-[1rem] xl4:text-[1.1rem]'>
          {usePriceFormat(weReimburse || 0)}
        </p>
      );
    },
  },
  // TODO: WHEN IT'S NEEDED
  {
    title: 'We Reimburse',
    dataIndex: 'price',
    key: 'priceTable',
    width: window.screen.width > 1600 ? '15rem' : '12rem',
    sorter: (a: any, b: any) => {
      return a.price - b.price;
    },
    render: (price: string[], item) => {
      const percentage = Math.abs(
        ((Number(item.price) - Number(item.weReimburse)) /
          Number(item.weReimburse)) *
          100,
      );
      return (
        <>
          <p
            className={
              Number(price) > Number(item.weReimburse) ||
              Number(price) < Number(item.weReimburse)
                ? 'flex flex-col font-bold mt-4 fontGTAmericanR xl3:text-[1rem] xl4:text-[1.1rem]'
                : 'flex flex-col font-bold fontGTAmericanR xl3:text-[1rem] xl4:text-[1.1rem]'
            }>
            {usePriceFormat(Number(price))}
            {Number(price) > Number(item.weReimburse) && (
              <Tag
                style={{
                  borderRadius: 10,
                  fontSize: '10px',
                  width: '4.5rem',
                  paddingLeft: 0,
                  paddingRight: 0,
                  paddingTop: 0,
                  textAlign: 'center',
                }}
                color='#81BFFD'>
                {/* {usePriceFormat(Number(price) - Number(item.weReimburse) || 0)} */}
                {percentage.toFixed(2)}%
                <ArrowUpwardIcon fontSize='inherit' />
                {/* Price */}
              </Tag>
            )}

            {Number(price) < Number(item.weReimburse) && (
              <Tag
                style={{
                  borderRadius: 10,
                  fontSize: '10px',
                  width: '4.5rem',
                  paddingLeft: 0,
                  paddingRight: 0,
                  paddingTop: 0,
                  textAlign: 'center',
                }}
                color='#ff6339'>
                {/* {usePriceFormat(Number(item.weReimburse) - Number(price) || 0)} */}
                {percentage.toFixed(2)}%
                <ArrowDownwardIcon fontSize='inherit' />
                {/* Price */}
              </Tag>
            )}
          </p>
        </>
      );
    },
  },
  {
    title: 'Shop Now',
    dataIndex: 'variations',
    key: 'shopNow',
    width: window.screen.width > 2500 ? '26rem' : '16rem',
    className: 'fontGTAmericanR ',
    render: (variations: any) => {
      const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
        null,
      );

      const [indexItemShop, setIndexItemShop] = useState(0);

      const [showAlert, setShowAlert] = useState<IAlertType>({
        message: '',
        show: false,
        title: '',
        type: 'success',
      });

      const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget);
      };

      const handleClose = () => {
        setAnchorEl(null);
      };

      const open = Boolean(anchorEl);
      const id = open ? 'simple-popover' : undefined;

      const indexItem = (e: any, index: any) => {
        handleClick(e);
        setIndexItemShop(index);
      };

      const handleCopyClick = (text: string) => {
        handleGeneralCopyClick(
          text,
          {
            show: true,
            message: text,
            title: 'Text Copied!',
            type: 'info',
          },
          {
            show: true,
            title: 'Coping Error',
            type: 'error',
          },
          setShowAlert,
        );
      };

      return (
        <div className='flex flex-wrap gap-2'>
          {showAlert?.show && (
            <GeneralAlert
              alertType={showAlert.type}
              alertMessage={showAlert.message}
              alertTitle={showAlert.title}
              setShowAlert={setShowAlert}
            />
          )}

          {variations?.map((variation: any) =>
            variation?.stores?.map((store: any, index: any) => {
              const transfomImages = store?.icon?.split('//')[1];

              return (
                <div key={index} id={'variation-store-' + store.id}>
                  <img
                    onClick={(e) => indexItem(e, store.id)}
                    src={
                      !store.icon.includes('no-icon.jpg')
                        ? `https://${transfomImages}`
                        : 'images/no-image.png'
                    }
                    className='rounded-md w-8 h-8 cursor-pointer'
                    alt={store.name}
                  />

                  <Popover
                    id={id}
                    open={indexItemShop === store.id && anchorEl ? true : false}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left',
                    }}>
                    <div className='p-2 w-44'>
                      <a
                        className='my-1 opacity-50 fontGTAmericanR font-semibold text-xs truncate flex gap-1'
                        href={store.link}
                        target='_blank'
                        rel='noreferrer'>
                        <ArrowForwardIcon fontSize='small' />
                        <p className='truncate mb-0'>
                          Shop on {store.link.split('//')[1]}
                        </p>
                      </a>
                      <Divider />
                      <p
                        onClick={() => handleCopyClick(variation.model)}
                        className='mb-1 mt-3 opacity-50 fontGTAmericanR text-xs cursor-pointer'>
                        <ContentCopyIcon fontSize='small' /> Copy item SKU
                      </p>
                    </div>
                  </Popover>
                </div>
              );
            }),
          )}
        </div>
      );
    },
  },
  {
    title: 'Deal Expires',
    dataIndex: 'dealExpires',
    key: 'dealExpires',
    width: window.screen.width > 1600 ? '15rem' : '12rem',
    className: 'fontGTAmericanR ',
    sorter: (a: any, b: any) =>
      new Date(dateFormatSorter(a?.dealExpires)).getTime() -
      new Date(dateFormatSorter(b?.dealExpires)).getTime(),
    render: (dealExpires: string, item) => {
      return (
        <>
          <p className='m-0 flex flex-col mt-3 text-orange-600 xl3:text-[1rem] xl4:text-[1.1rem]'>
            {dealExpires}
          </p>
          {validateExpires(item.days_to_expire) === 1 ? (
            <Tag style={{ borderRadius: 10, fontSize: '10px' }} color='#cd201f'>
              Expired!
            </Tag>
          ) : validateExpires(item.days_to_expire) === 0 ? (
            <Tag style={{ borderRadius: 10, fontSize: '10px' }} color='#F86238'>
              Expires Soon!
            </Tag>
          ) : (
            ''
          )}
        </>
      );
    },
  },
  {
    title: 'Actions',
    className: 'fontGTAmericanR ',
    render: (prueba) => <RenderActions item={prueba} />,
  },
];

export const selectFilterExpires = {
  name: 'expires',
  title: 'Expires',
  menuItem: [
    {
      value: 'yes',
      labelName: 'Yes',
    },
    // {
    //   value: 'not',
    //   labelName: 'Not',
    // },
  ],
};

export const selectFilterPriceType = {
  name: 'priceType',
  title: 'Price Type',
  menuItem: [
    {
      value: 'below_cost',
      labelName: 'Below Cost',
    },
    {
      value: 'cost',
      labelName: 'Cost',
    },
    {
      value: 'above_cost',
      labelName: 'Above Cost',
    },
  ],
};

export const selectFilterPrice = {
  name: 'price',
  title: 'Price',
  isPrice: true,
  menuItem: [
    {
      value: '1-100',
      labelName: '$1 - $100',
    },
    {
      value: '100-200',
      labelName: '$100 - $200',
    },
    {
      value: '200-300',
      labelName: '$200 - $300',
    },
    {
      value: '300-400',
      labelName: '$300 - $400',
    },
    {
      value: '400-500',
      labelName: '$400 - $500',
    },
    {
      value: '500-600',
      labelName: '$500 - $600',
    },
    {
      value: '600-700',
      labelName: '$600 - $700',
    },
    {
      value: '700-800',
      labelName: '$700 - $800',
    },
    {
      value: '800-900',
      labelName: '$800 - $900',
    },
    {
      value: '900-1000',
      labelName: '$900 - $1000',
    },
    {
      value: '1000-1100',
      labelName: '$1000 - $1100',
    },
    {
      value: '1100-1200',
      labelName: '$1100 - $1200',
    },
    {
      value: '1200-1300',
      labelName: '$1200 - $1300',
    },
    {
      value: '1300-1400',
      labelName: '$1300 - $1400',
    },
    {
      value: '1400-1500',
      labelName: '$1400 - $1500',
    },
    {
      value: '1500-1600',
      labelName: '$1500 - $1600',
    },
    {
      value: '1600-1700',
      labelName: '$1600 - $1700',
    },
    {
      value: '1700-1800',
      labelName: '$1700 - $1800',
    },
    {
      value: '1800-1900',
      labelName: '$1800 - $1900',
    },
    {
      value: '1900-2000',
      labelName: '$1900 - $2000',
    },
  ],
};

export const simpleSelectFilter = () => {
  // const { filterSetsSave } = useStatesGlobalStore();

  const { response: responseFilterSet } = getFilterSetService({
    module_name: 'active_deals',
  });

  const { isDesktopCustomTable, isScreenLaptop, isDefaultWidth, isUltraWidth } =
    useResponsive();

  const menuItems = [
    {
      value: '',
      labelName: 'None Selected',
    },
  ];

  responseFilterSet?.data.map((item: any) => {
    return menuItems.push({
      value: item.filter_name,
      labelName: item.filter_name,
    });
  });

  return [
    {
      name: 'filterSets',
      selectWidth: isUltraWidth ? 250 : 100,
      stylesFirstContainer: () => {
        if (isDefaultWidth) {
          return 'flex justify-self-end mt-5 lg:absolute right-[5rem]';
        }

        if (isDesktopCustomTable) {
          return 'flex justify-self-end mt-5 lg:absolute right-[2rem]';
        }

        if (isScreenLaptop) {
          return 'flex justify-self-end mt-5 lg:absolute right-[3.5rem]';
        }

        return 'flex justify-self-end mt-5 lg:absolute right-[5rem]';
      },
      stylesSecondContainer:
        'flex items-center mb-8 lg:mb-0 fontGTAmericanR font-semibold items-center',
      title: 'Your Filter Sets',
      menuItems,
    },
  ];
};

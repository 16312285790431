export const filterSaveLocalStorage = (set) => ({
  selectedCardActiveDeals: false,
  setSelectedCardActiveDeals: (response) =>
    set(() => ({
      selectedCardActiveDeals: response,
    })),
  callRefetch: false,
  setCallRefetch: (response) =>
    set(() => ({
      callRefetch: response,
  })),
});

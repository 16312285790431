import React from 'react';
import * as Styles from './style';
import { Table } from 'antd';
//TODO: everything related to the filters is commented until it is supported from the backend
import { IFilters } from '../../interfaces/myCommitments';
import {
  dataTableFields,
  searchFilter,
  selectFilters,
  stylesFilterSectionSearchAndDdate,
} from './constants/index';
import Filter from '../../components/UI/filter';
import getMyCommitments from '../../api/services/myCommitments/getMyCommitments';
import MobileList from '../../components/UI/mobileList/index';
import { dateFilter } from './constants/index';
import MobileFilter from '../../components/UI/mobileFilter/index';
import useResponsive from '../../hooks/useResponsive';
import globalStoreNoLocalStore from '../../hooks/globalStoreNoLocalStore';
import PaginationCustom from '../../components/UI/PaginationCustom';
import useStatesGlobalStore from '../../hooks/useStatesGlobalStore';

const MyCommitmentsModule = () => {
  const {callRefetch } = useStatesGlobalStore();
  const [filterOptions, setFilterOptions] = React.useState<IFilters>({
    status: [],
    expired: [],
    price: [],
    searchItem: '',
    date_from: '',
    date_until: '',
  });
  
  const [current, setCurrent] = React.useState(1);
  const [sizeItemsPage, setSizeItemPage] = React.useState(10);

  const dateTransform =
    filterOptions.date_from !== '' && filterOptions.date_until !== ''
      ? `${filterOptions.date_from} ${filterOptions.date_until}`
      : '';

  const { response, isLoading, refetch } = getMyCommitments({
    status: filterOptions.status,
    date_range: dateTransform,
    price: filterOptions.price,
    expired: filterOptions.expired,
    item_name: filterOptions.searchItem,
    start: current,
    limit: sizeItemsPage,
  });

  const clearFilters = () => {
    setFilterOptions({
      status: [],
      expired: [],
      price: [],
      searchItem: '',
      date_from: '',
      date_until: '',
    });
  };

  const { isMobile } = useResponsive();

  const {
    alertSliceMobile,
    alertTurnScreenMobile,
    clearAlertSliceMobile,
    clearAlertTurnScreenMobile,
  } = globalStoreNoLocalStore();

  React.useEffect(() => {
    if (isMobile) {
      clearAlertSliceMobile();
      clearAlertTurnScreenMobile();
    }
  }, [alertSliceMobile.view, alertTurnScreenMobile.view]);

  React.useEffect(() => {
    refetch();
  }, [callRefetch])

  return (
    <main className='mt-11'>
      <header id='title'>
        <h1 className='lg:ml-5 text-2xl lg:text-4xl fontSharpGrotesk22Medium text-black mb-10 '>
          My Commitments
        </h1>
      </header>
      <div className='hidden xl:block'>
        <Filter
          filterOptions={filterOptions}
          rangeDateFilter={dateFilter}
          setFilterOptions={setFilterOptions}
          selectFilters={selectFilters}
          searchFilters={searchFilter}
          stylesFilterSectionSearchAndDate={stylesFilterSectionSearchAndDdate}
          isSaveFilterVisible={false}
          showFilterTitle={true}
        />
      </div>
      <div className='mb-4 xl:hidden'>
        <MobileFilter
          clearFilters={clearFilters}
          filterOptions={filterOptions}
          isSaveFilterVisible={true}
          selectFilters={selectFilters}
          setFilterOptions={setFilterOptions}
          showFilterTitle={false}
          rangeDateFilterMobile={dateFilter}
          formStyles={'flex flex-wrap lg:justify-between'}
          searchFilter={searchFilter}
        />
      </div>
      <div>
        <MobileList data={response?.data} isMyCommitmets={true} />
      </div>
      <div className='hidden lg:block' style={{ flex: 1, overflowY: 'auto' }}>
        <Styles.TableContainer>
          <Table
            className='itemTableTd tableStyles xl1:h-[calc(100vh-350px)] xlc:h-[calc(100vh-350px)] xl2:h-[calc(100vh-400px)] xl2_1:h-[calc(100vh-400px)]'
            columns={dataTableFields}
            dataSource={response?.data}
            loading={isLoading}
            scroll={{ x: 1300 }}
            style={{ minHeight: '350px' }}
            pagination={false}
          />
        </Styles.TableContainer>
      </div>
      <PaginationCustom
        totalItems={response?.meta?.totals.items}
        current={current}
        setSizeItemPage={setSizeItemPage}
        sizeItemsPage={sizeItemsPage}
        setCurrent={setCurrent}
        />
    </main>
  );
};

export default MyCommitmentsModule;

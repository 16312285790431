import { useMutation } from 'react-query';
import postFetch from '../../endPoints/myAccount/addPayeeName';

const useAddPayeeName = () => {
  const { mutate } = useMutation(postFetch);

  return { payeeNameMutate: mutate };
};

export default useAddPayeeName;

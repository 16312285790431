import {
  IPurchaseOrders,
  IDataInternal,
  Status,
} from '../../../interfaces/purchaseOrders/indexUpdate';

export interface IServiceTransform {
  data: IDataInternal[];
  meta: {
    status: Status | number;
    message: string;
    totals: {
      amount: string;
      items: number;
    };
  };
}

const transformDataPurchaseOrder = (
  data: IPurchaseOrders,
): IServiceTransform => {
  const dataTranformed = data.data.map((test: any) => {
    return {
      ...test,
      key: test.po_number,
    };
  });
  const metaTranform = {
    status: data.status,
    message: data.message,
    totals: data.totals,
  };

  return { data: dataTranformed, meta: metaTranform };
};

export default transformDataPurchaseOrder;

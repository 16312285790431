import { useQuery } from 'react-query';
import fetchApiGet from '../../endPoints/dashboard/fetchApi';
import { AuthContext } from '../../../context/useSaveDataSession';
import transformDataCardsDashboard, {
  IServiceTransform,
} from '../../transforms/dashboard/transformDataCardsDashboard';
import { useContext } from 'react';

interface IResponseService {
  response: IServiceTransform | undefined;
  error: any;
  isLoading: boolean;
  refetch: () => void;
}

const getInfoCards = (): IResponseService => {
  const { logout } = useContext<any>(AuthContext);

  const { error, data, isLoading, refetch } = useQuery('CARDS', fetchApiGet, {
    onSuccess: (data) => {
      if (data.message === 'Token has expired') {
        logout();
      } else if (data.status === 401 || data.status_code === 401) {
        logout();
      }
    },
  });

  if (data) {
    return { error, response: transformDataCardsDashboard(data), isLoading, refetch };
  } else {
    return { error, response: data, isLoading, refetch };
  }
};

export default getInfoCards;

import { useMutation } from 'react-query';
import updateAchAccountFetch from '../../endPoints/myAccount/updateAchAccount';

const useUpdateAchAccount = () => {
  const { mutate } = useMutation(updateAchAccountFetch);

  return { achAccountUpdateMutate: mutate };
};

export default useUpdateAchAccount;

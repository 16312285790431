/* eslint-disable no-undef */
import cookie from 'react-cookies';

const updatePayeeNameFetch = async (data: any = {}) => {
  const response = await fetch(
    `${
      process.env.REACT_APP_BACKEND_URL
    }buyers/payees-names/${encodeURIComponent(data?.code)}`,
    {
      method: 'PUT',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        Authorization: `Bearer ${cookie.load('token')}`,
        'Content-type': 'application/json',
      },
      redirect: 'follow',
      referrerPolicy: 'strict-origin-when-cross-origin',
      body: JSON.stringify(data?.data),
    },
  );

  return response.json();
};

export default updatePayeeNameFetch;

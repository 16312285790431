import { useMutation } from 'react-query';
import putFetchFilterSetDefault from '../../endPoints/activeDeals/putFilterSetDefault';

const putFilterSetDefaultServices = (): any => {
  return useMutation(putFetchFilterSetDefault);

  // if (data) {
  // } else {
  //   return { error, response: data, isLoading };
  // }
};

export default putFilterSetDefaultServices;

import React from 'react';
import { Box, Button, Popover } from '@mui/material';
import { EditOutlined } from '@mui/icons-material';
import { useQueryClient } from 'react-query';
import { themeCustom } from '../../../theme';
import useDeletePayeeName from '../../../api/services/myAccount/deletePayeeName';
import UpdatePayeeNameForm from '../containers/UpdatePayeeNameForm';
import ModalDeleteCheckRequest from '../../paymentRequest/containers/ModalDeleteCheckRequest';

const RenderActionsPayeesNamesTable = ({
  payeeNameInfo,
  setShowAlert,
}: any) => {
  const { colors } = themeCustom;
  const { mutate, isLoading } = useDeletePayeeName();
  const queryClient = useQueryClient();
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null,
  );
  const [anchorElDelete, setAnchorElDelete] =
    React.useState<HTMLButtonElement | null>(null);
  const popOverOpen = Boolean(anchorEl);

  const handleOpenDeleteConfirmation = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    setAnchorElDelete(event.currentTarget);
  };

  const handleCancel = () => {
    setAnchorElDelete(null);
  };

  const openDeletePop = Boolean(anchorElDelete);
  const id = openDeletePop ? 'simple-popover-delete' : undefined;

  const handleOpenUpdateForm = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseUpdateForm = () => {
    setAnchorEl(null);
  };

  const handleDeletePayeeName = () => {
    mutate(encodeURIComponent(payeeNameInfo?.id), {
      onSuccess: (data: any) => {
        if (data?.status) {
          setAnchorElDelete(null);
          setShowAlert({
            message: '',
            show: true,
            title: 'The payee name has been deleted successfully.',
            type: 'success',
          });
          queryClient.invalidateQueries('GET_PAYEE_NAMES');
        } else {
          setShowAlert({
            message: data?.error,
            show: true,
            title: 'Error on request',
            type: 'warning',
          });
        }
      },
      onError: (error: any) => {
        if (error) {
          setShowAlert({
            message: error,
            show: true,
            title: 'Error on request',
            type: 'warning',
          });
        }
      },
    });
  };

  return (
    <Box
      sx={{
        display: 'block',
      }}>
      <div>
        <Button
          data-testid={`btnUpdate#${payeeNameInfo?.id}`}
          key='one'
          onClick={handleOpenUpdateForm}
          sx={{
            textTransform: 'none',
            color: colors.grayLabels,
          }}>
          <Box sx={{ color: colors.grayLabels, display: 'flex' }}>
            <EditOutlined />
            <span className='ml-3'>Update</span>
          </Box>
        </Button>
      </div>
      <div className='ml-1'>
        <Button
          data-testid={`btnDelete#${payeeNameInfo?.id}`}
          key='two'
          onClick={handleOpenDeleteConfirmation}
          sx={{
            textTransform: 'none',
            color: colors.grayLabels,
          }}>
          <Box sx={{ color: colors.grayLabels, display: 'flex' }}>
            <img
              className='h-5'
              src='images/trackingNumbers/deleteIcon.png'
              alt='delete icon'
              width='12'
              height='17'
            />
            <span className='ml-5'>Delete</span>
          </Box>
        </Button>
        <Popover
          anchorEl={anchorElDelete}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          id={id}
          onClose={handleCancel}
          open={openDeletePop}
          data-testid='formRequestPayment'>
          <ModalDeleteCheckRequest
            functions={{
              handleCancel: handleCancel,
              handleDelete: handleDeletePayeeName,
            }}
            isLoading={isLoading}
            item={payeeNameInfo}
            styles={'p-4'}
          />
        </Popover>
      </div>
      <UpdatePayeeNameForm
        payeeNameInfo={payeeNameInfo}
        anchorEl={anchorEl}
        handleClose={handleCloseUpdateForm}
        open={popOverOpen}
      />
    </Box>
  );
};

export default RenderActionsPayeesNamesTable;

import { IActiveDeals, Items } from '../../../interfaces/activeDeals';
import buildObject from '../activeDeals/buildObject';

export interface IServiceTransform {
  data: Items[];
  meta: {
    status: boolean;
    totals: any;
  };
}

const transformDataActiveDeals = (data: IActiveDeals): IServiceTransform => {
  const dataTranformed = data?.deals?.data?.items?.map((item: any) => {
    return buildObject(item);
  });

  const metaTranform = {
    status: data.status,
    totals: data.deals.totals?.items,
  };

  return { data: dataTranformed, meta: metaTranform };
};

export default transformDataActiveDeals;

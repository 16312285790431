import { useQuery } from 'react-query';
import fetchApiGet from '../../endPoints/paymentRequest/index';
import transformDataPaymentRequest, {
  IServiceTransform,
} from '../../transforms/paymentRequest/index';
import { useContext } from 'react';
import { AuthContext } from '../../../context/useSaveDataSession';

interface IResponseService {
  response: IServiceTransform | undefined;
  error: any;
  isLoading: boolean;
  refetch: () => void;
}

const getPaymentRequest = (params: any): IResponseService => {
  const { logout } = useContext<any>(AuthContext);

  const { error, data, isLoading, refetch } = useQuery(
    ['PAYMENTREQUEST', params],
    () => {
      return fetchApiGet(params);
    },
    {
      onSuccess: (data) => {
        if (data.message === 'Token has expired') {
          logout();
        } else if (data.status === 401) {
          logout();
        }
      },
    },
  );
  if (data) {
    return {
      error,
      response: transformDataPaymentRequest(data),
      isLoading,
      refetch,
    };
  } else {
    return { error, response: data, isLoading, refetch };
  }
};

export default getPaymentRequest;

const copyTextToClipboard = async (text: any) => {
  if ('clipboard' in navigator) {
    return await navigator.clipboard.writeText(text);
  } else {
    return 'Error trying to copy';
  }
};

export const handleGeneralCopyClick = (
  text: string,
  successAlertInfo: any,
  errorAlertInfo: any,
  setShowAlert: any,
) => {
  copyTextToClipboard(text);
  if (text) {
    setShowAlert({
      message: text,
      show: successAlertInfo.show,
      title: successAlertInfo.title,
      type: successAlertInfo.type,
    });
  } else {
    setShowAlert({
      message: 'Error on copying',
      show: errorAlertInfo.show,
      title: errorAlertInfo.title,
      type: errorAlertInfo.type,
    });
  }
  return setShowAlert;
};

export const dateFormatSorter = (date: any) => {
  let dateSplited = date.split(' ');
  let formattedDate = dateSplited[0];

  return formattedDate;
};

export const validateExpires = (dateDealExpires: any) => {
  if (dateDealExpires < 0) {
    return 1;
  } else if (dateDealExpires >= 0 && dateDealExpires <= 2) {
    return 0;
  } else {
    return 2;
  }
};

export const notifyImpedimentToDecrease = (
  valueToCommit: any,
  currentAmount: any,
  setIsNotAllowToDecrease: any,
  setShowAlert: any,
) => {
  if (valueToCommit < currentAmount && valueToCommit != '') {
    setShowAlert({
      show: true,
      message: 'Commit editions must be done through the commitments module.',
      title: 'Error',
      type: 'warning',
    });
    setIsNotAllowToDecrease(true);
  } else if (valueToCommit > currentAmount && valueToCommit != '') {
    setIsNotAllowToDecrease(false);
  }
};

export const notifyImpedimentToIncrease = (
  valueToCommit: any,
  currentAmount: any,
  setIsNotAllowToDecrease: any,
  setShowAlert: any,
  lockedByTime: boolean
) => {
  if (lockedByTime && valueToCommit < currentAmount && valueToCommit != '') {
    setShowAlert({
      show: true,
      message: 'Commit editions must be done through the commitments module.',
      title: 'Error',
      type: 'warning',
    });
    setIsNotAllowToDecrease(true);
  } else if (valueToCommit <= 0) {
    setIsNotAllowToDecrease(true);
  }
   else {
    setIsNotAllowToDecrease(false);
  }
};
import React from 'react';
import { Divider, Popover } from '@mui/material';
import { updateACHAccountForm } from '../constants';
import FormTemplate from '../components/FormTemplate';

const UpdateAchAccountForm = ({
  accountInfo,
  anchorEl,
  handleClose,
  open,
  phoneNumberDetails,
}: any) => {
  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}>
      <div
        className='bg-slate-100 rounded-3xl p-5 w-[40rem] overflow-auto h-[28rem]	'
        data-testid='updateAchAccountForm'>
        <div className='flex justify-between'>
          <h3 className='ml-5 text-blue-500 text-xl font-semibold'>
            Update an ACH Account
          </h3>
        </div>
        <Divider sx={{ bgcolor: '#1b8cfc', height: 2, borderRadius: 2 }} />
        <FormTemplate
          extraFormContainerStyles1={'w-full'}
          extraFormContainerStyles2={'ml-4 w-full'}
          extraFormContainerStyles3={'flex mt-8'}
          handleCloseExtraForm={handleClose}
          formName={'updateAchAccountForm'}
          containerSubmitButton={[
            {
              ml: 2,
              labelName: 'Submit',
              bgColor: 'white',
              containerStyles: 'ml-4 w-full',
            },
          ]}
          formFields={updateACHAccountForm}
          phoneNumberDetails={phoneNumberDetails}
          styles={'flex flex-wrap mt-10 justify-center'}
          submitConfig={[
            {
              dataTestID: 'saveChangesUpdateAchAccount',
              ml: 2,
              labelName: 'Submit',
              bgColor: 'rgb(241 245 249)',
            },
          ]}
          extraACHForm={true}
          updateInfo={accountInfo}
        />
      </div>
    </Popover>
  );
};

export default UpdateAchAccountForm;

import React from 'react';
import { infoCards } from './constants/index';
import { IndexDesktop } from './containers/IndexDesktop';
import { IndexMobile } from './containers/IndexMobile';
import useResponsive from '../../hooks/useResponsive';
import globalStoreNoLocalStore from '../../hooks/globalStoreNoLocalStore';

const HelpCenterModule = () => {
  const { isMobile } = useResponsive();

  const {
    alertSliceMobile,
    alertTurnScreenMobile,
    clearAlertSliceMobile,
    clearAlertTurnScreenMobile,
  } = globalStoreNoLocalStore();

  React.useEffect(() => {
    if (isMobile) {
      clearAlertSliceMobile();
      clearAlertTurnScreenMobile();
    }
  }, [alertSliceMobile.view, alertTurnScreenMobile.view]);

  return (
    <>
      <div className='flex flex-wrap justify-between mt-10'>
        <h1 className='lg:ml-5 text-2xl lg:text-4xl fontSharpGrotesk22Medium text-black mb-10 '>
          Help Center
        </h1>
      </div>
      <div className='hidden lg:block '>
        <IndexDesktop infoCards={infoCards} />
      </div>
      <div className='block lg:hidden'>
        <IndexMobile infoCards={infoCards} />
      </div>
    </>
  );
};

export default HelpCenterModule;

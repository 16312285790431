import React from 'react';
import { Link } from 'react-router-dom';

const CardHelper = ({ route, title, style, icon, disabled }: any) => {
  return (
    <>
      <Link to={route} data-testid={`${title}-desktop`}>
        <div
          className={` rounded-3xl ${style} ${
            disabled ? 'bg-slate-400' : 'bg-blue-200'
          }`}>
          <p className='w-full flex justify-center text-[2rem] '>{icon}</p>
          <p className='w-full text-center text-xl'>{title}</p>
        </div>
      </Link>
    </>
  );
};

export default CardHelper;

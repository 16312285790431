import React from 'react';

export const dataFaqsGeneral = [
  {
    id: 'subGeneral1',
    title: 'How long after a deal is posted must I drop it off?',

    paragraph: (
      <div>
        <p>
        All products/merchandise must be dropped off or delivered by the expiration date listed below
        the deal in the active deals portal. If your package is delayed or you cannot meet the deadline,
        see below. Even if you commit to a deal, USA Buying Group reserves the right to ask you to
        return the product if absolutely necessary.
        </p>
      </div>
    ),
  },
  {
    id: 'subGeneral2',
    title:
      'Is the expiration date the last day to order or the last day to be delivered?',
    paragraph: (
      <div>
        The expiration date is the date on which the product MUST be delivered or dropped off. This
        includes all direct shipments, drop-offs, and pickups. The items must be in our possession by
        the expiration date
      </div>
    ),
  },
  {
    id: 'subGeneral3',
    title:
      'A deal was sent out with a set price, but by the time I saw it, the price went up. Should I still order it?',
    paragraph: <div>No</div>,
  },
  {
    id: 'subGeneral4',
    title:
      'A deal was sent out, and I see the same item on sale from a different retailer. Can I purchase from a store other than the one that USA posted?',
    paragraph: (
      <div>
        If you see an item we are buying on sale from a different retailer, you must ask before
        purchasing from there. Please <a className='underline text-blue-500 hover:text-blue-600 hover:underline' href='https://app.usabuying.group/open-help-ticket'>
        open a support ticket</a> specifying the deal ID, and please attach a
        link for the item that you would like to purchase. This will be judged on a case-by-case basis. Do
        not assume that if we give you permission to buy from that retailer at a different time, you can
        always buy from there.
      </div>
    ),
  },
  {
    id: 'subGeneral5',
    title:
      'A deal was sent out, and when I click the link, I don’t see it available in new condition. Can I purchase a used or refurbished item instead?',
    paragraph: (
      <div>
        No. Unless otherwise specified, all items must be in brand new condition. We will not accept
        refurbished, open, or damaged items.
      </div>
    ),
  },
  {
    id: 'subGeneral6',
    title:
      'A deal was sent out, but by the time I saw the deal, it was fully committed. Should I still buy the deal?',
    paragraph: (
      <p>No</p>
    ),
  },
  {
    id: 'subGeneral7',
    title: 'If I am unable to get the product delivered to USA Buying Group within the time frame, should I still buy the deal?',
    paragraph: (
      <div>No</div>
    ),
  },
  {
    id: 'subGeneral8',
    title:
      'What happens if my package is delayed and gets delivered after the expiration date?',
    paragraph: 
        `USA buying group does not take responsibility for delayed packages, even if you committed to
        the deal. The expiration date is a strong deadline. If your package is delayed and arrives after
        the expiration date, the item will be scanned at the newest price if there's a new deal available
        for that model. If there's no active deal, the package may be rejected.`
       ,
  },
  {
    id: 'subGeneral9',
    title: 'What happens if I ordered an item but did not commit, and now the deal is full?',
    paragraph: (
      <p>
        You can <a className='underline text-blue-500 hover:text-blue-600 hover:underline' href='https://app.usabuying.group/open-help-ticket'>
        open a support ticket</a> to see if we can make an exception for your item. If you do not
        receive a response right away or see that we sent a new deal for the same item at a lower price,
        please cancel your order. If we do receive your non-committed items and we have an open deal
        for that model, it will be scanned at the current price. If there is no open deal for the item, it will
        be rejected.
      </p>
    ),
  },
  {
    id: 'subGeneral10',
    title: 'I ordered an item without committing, but I am no longer able to cancel my order. What should I do?',
    paragraph: (
      <>
        <p>
        Please <a className='underline text-blue-500 hover:text-blue-600 hover:underline' href='https://app.usabuying.group/open-help-ticket'>
        open a support ticket</a> immediately and request a blacklist on your tracking number.
        Please note that if your package has already been delivered, we may no longer be able to place
        a blacklist on the package. In addition, we cannot guarantee rejection of blacklisted items.
        </p>
      </>
    ),
  },
  {
    id: 'subGeneral11',
    title: 'Do you cover tax or shipping?',
    paragraph: (
      <>
        <p>
          No. Unless otherwise specified, we do not cover tax and shipping on orders. You can ship
          directly to our Delaware warehouse, where you won’t be charged a sales tax..
        </p>
      </>
    ),
  },
  {
    id: 'subGeneral12',
    title: 'What should I do if my package will require a signature upon delivery?',
    paragraph: (
      <>
        <p>
          We can sign for packages that are delivered during normal business hours (Monday–Thursday,
          9 a.m.–5 p.m.; Friday, 9 a.m.–2 p.m.). Packages that require a signature should not be delivered
          over the weekend.
        </p>
      </>
    ),
  },
  {
    id: 'subGeneral13',
    title: 'How do I track my package?',
    paragraph: (
      <>
        <p>
          You can view live updates in your portal under the tracking number page. This will show updates
          as your package moves through transit, as well as where it’s holding once it hits our warehouse.
        </p>
      </>
    ),
  },
  {
    id: 'subGeneral14',
    title: 'When do I get paid for my order?',
    paragraph: (
      <>
        <p>
          See below under Payment.
        </p>
      </>
    ),
  },
  {
    id: 'subGeneral15',
    title: 'I have questions, concerns, or comments. Who should I contact?',
    paragraph: (
      <>
        <p>
        Please <a className='underline text-blue-500 hover:text-blue-600 hover:underline' href='https://app.usabuying.group/open-help-ticket'>
        open a support ticket</a> for all questions. Please do not PM any admins. Messaging
        multiple admins for the same issue is both unnecessary and counterproductive.
        </p>
      </>
    ),
  },
];

export const dataFaqsPickUpDropOff = [
  {
    id: 'subDropOff1',
    title: 'How does shipping to DE work?',
    paragraph: (
      <>
        <p>
        You can ship directly to our Delaware warehouse, where you won’t be charged a sales tax. The
        address is Your Name, Buyer ID <br/>
        1400 Vandever Ave. <br/>
        Wilmington, DE 19802.<br/>
        After your items have shipped, please be sure to fill out the following link with your tracking
        number(s). <a className='underline text-blue-500 hover:text-blue-600 hover:underline' href='https://app.usabuying.group/tracking-numbers'>
        https://app.usabuying.group/tracking-numbers</a>
        </p>
      </>
    ),
  },
  {
    id: 'subDropOff2',
    title: 'How often are drop-off/pickups?',
    paragraph: `We generally pick once a week in Flatbush, Crown Heights, Staten Island, Far Rockaway, and
                Monsey. Our Lakewood drop-off location is open Monday- Friday. Refer to that week's drop-off
                schedule for exact dates and times.`,
  },
  {
    id: 'subDropOff3',
    title: 'Do you offer private pickups?',
    paragraph: (
      <>
        <p>
        Private pickups are based on location, demand, and our availability. The minimum for a private
        pickup is $10K. Please fill out the form below BEFORE the start of that day's drop-off schedule.
        <a className='underline text-blue-500 hover:text-blue-600 hover:underline' target='_blank' href='https://goo.gl/R1G19T'>
        https://goo.gl/R1G19T</a>. Filling out the form is a request for a pickup, not a guarantee.
        Note that during busy seasons this option may not be available.
        </p>
      </>
    ),
  },
  {
    id: 'subDropOff4',
    title: 'Do you provide shipping labels?',
    paragraph: (
      <>
        <p>
        We can provide labels for 1k worth of items. Please <a className='underline text-blue-500 hover:text-blue-600 hover:underline' href='https://app.usabuying.group/open-help-ticket'>
        open a help ticket</a>, and let us know the
        items you would like to send, how many boxes you have, and the approximate weight of each
        box. Note, boxes will be insured up to $100.
        </p>
      </>
    ),
  },
];

export const dataFaqsMissingPackage = [
  {
    id: 'subMissingPackage1',
    title: 'I think I’m missing a package. What should I do?',
    paragraph: (
      <div>
        <p>
        Check our portal for scanning updates. If your package was delivered after the scanning cutoff
        date, give it another couple days to get scanned. If it was delivered before the cutoff date,
        please let us know right away so that we can look into it.
        </p>
      </div>
    ),
  },
  {
    id: 'subMissingPackage2',
    title:
      'How long after a package goes missing can I expect USA Buying Group to look into it?',
    paragraph: (
      <p>
        USA’s policy for missing packages is that a claim must be submitted within 1 week of delivery. If
        you do not submit a claim by ticket within that time frame, we may not be able to help you find
        your package or get you the proper reimbursement.
      </p>
    ),
  },
  {
    id: 'subMissingPackage3',
    title: 'If my package shows that it was delivered, does that mean that USA has it?',
    paragraph:
      `If a package was marked as delivered, that does not necessarily mean that it was indeed
      delivered. Therefore, it is important that you inquire about a missing package right away so that
      we can check our footage to see if that package arrived at our location. USA does not take
      responsibility for packages until they are in our warehouse and marked as such.`,
  },
  {
    id: 'subMissingPackage4',
    title: 'If you see my package was delivered but not scanned at the dock, where do I go from there?',
    paragraph:
      `We would ask for some time to make sure all delivered packages were scanned in from that
      time frame. Look out for updates on the portal stating where we're holding with check-ins.`,
  },
  {
    id: 'subMissingPackage5',
    title: 'What happens if I miss the window to open a ticket?',
    paragraph:
      'We will still try our best to locate your product, but we are unable to guarantee reimbursement. Here at USA we always do our best to satisfy our customers, but letting us know far down the road may prevent us from being able to assist you properly.',
  },
  {
    id: 'subMissingPackage6',
    title:
      'How can I get added to the package coverage program?',
    paragraph: (
      <p>
        To enroll in USA’s Safe Ship program use the link below . <br/>
        <a className='underline text-blue-500 hover:text-blue-600 hover:underline' target='_blank' href='https://beta.usabuying.group?enroll=true'>
        https://beta.usabuying.group?enroll=true
        </a>
      </p>
    ),
  },
  {
    id: 'subMissingPackage7',
    title: 'How much time do I have to add coverage to a package?',
    paragraph:
      `The full value of the package must be added within 3–5 business days of when the package
      starts shipping.`,
  },
  {
    id: 'subMissingPackage8',
    title: 'My package was delivered before I added the value; can I still add coverage?',
    paragraph:
      `Yes, coverage can still be added even after your package is delivered.`,
  },
  {
    id: 'subMissingPackage9',
    title: 'My package was lost in transit, will that be covered?',
    paragraph:
      `We may cover packages that were lost in transit. After filing a claim with the shipper and
      financial institution, our team will review your case and make a decision.`,
  },
  {
    id: 'subMissingPackage10',
    title: `When entering the value of a package, should I enter the amount I paid or USA's payout price?`,
    paragraph:
      `When entering the value of a package, please input the total cost of the package, not the price
      that we paid. In the event of a missing package, we will reimburse either the payout price or the
      cost price, whichever is lower.`,
  },
  {
    id: 'subMissingPackage11',
    title:
      `The value of my package is greater than my coverage value limit, and I am unable to add
      the full value to the tracking number. What should I do?`,
    paragraph: (
      <p>
        Please <a className='underline text-blue-500 hover:text-blue-600 hover:underline' href='https://app.usabuying.group/open-help-ticket'>
        send us a message</a> right away—before adding the value—so we can review it.
      </p>
    ),
  },
  {
    id: 'subMissingPackage12',
    title:
      'I see an error message next to the package value in my portal. What does that mean?',
    paragraph: (
      <p>
        If you see an error message next to the package value in your portal, please <a className='underline text-blue-500 hover:text-blue-600 hover:underline' href='https://app.usabuying.group/open-help-ticket'> 
        notify us</a> immediately so that we can look into the issue. This is time-sensitive.
      </p>
    ),
  },
  {
    id: 'subMissingPackage13',
    title:
      `Can I add coverage for a package that I'm shipping to my house and then forwarding to you?`,
    paragraph: (
      <p>
        No. We only cover packages that are sent from the retailer directly to our warehouse in
        Delaware. If using a label that we provided, we offer the option to add insurance for an extra few
        dollars per box. Note that this can only be added with the creation of the label. Once it starts
        shipping, insurance can no longer be added.
      </p>
    ),
  },
  {
    id: 'subMissingPackage14',
    title:
      'We reserve the right to forfeit your eligibility at any time.',
    paragraph: '',
  },
];

export const dataFaqsPayment = [
  {
    id: 'subPayment1',
    title: 'When do I get paid for my order?',
    paragraph: (
      <div>
        <p>
        Once your items are received, you will get an email with a PDF letting you know what we got.
        You can then request payment through your portal.
        </p>
      </div>
    ),
  },
  {
    id: 'subPayment2',
    title: 'How long does it take for my payment request to be processed?',
    paragraph:
      `All payments will be sent within 5 business days of being requested. If your request is older than
      5 business days, please open a ticket so that we can look into your case and have your
      payment sent as soon as possible.`,
  },
  {
    id: 'subPayment3',
    title:
      'How do I get paid?',
    paragraph: (
      <div>
        <p>
          We offer payment either by e-check or ACH.
        </p>
      </div>
    ),
  },
  {
    id: 'subPayment4',
    title: 'Can I get a check made out to a name other than my own?',
    paragraph: (
      <div>
        <p>
        Yes. Checks can be made out to your name, your bank, your credit card, or an immediate family
        member with the same last name as yours. You may also request checks to be issued in your
        company's name, for which we'll need to verify company documents. Please request to add
        payee names under the payment request tab in your portal.
        </p>
      </div>
    ),
  },
  {
    id: 'subPayment5',
    title:
      'I received my purchase order, and my item was checked in at the wrong price. What should I do?',
    paragraph: (
      <div>
        <p>
        If you believe there is an error with your PO, or if your item was scanned at the wrong price, or if
        you need anything updated on your PO, please <a className='underline text-blue-500 hover:text-blue-600 hover:underline' href='https://app.usabuying.group/open-help-ticket'>
        create a ticket</a> right away. Please note that after
        30 days have passed since your PO was created, we may no longer be able to assist you.
        </p>
      </div>
    ),
  },
];

export const infoCards = [
  {
    title: 'Read the FAQs',
    route: '/read-faqs',
    icon: <img src={'images/helpCenter/readFaqs.svg'} alt='faq' />,
    disabled: false,
  },

  {
    title: 'Open a Help Ticket',
    route: '/open-help-ticket',
    icon: <img src={'images/helpCenter/openHelpTicket.svg'} alt='ticket' />,
    disabled: false,
  },
  {
    title: 'Check Ticket Status',
    route: '/check-ticket-status',
    icon: <img src={'images/helpCenter/checkTicket.svg'} alt='status' />,
    disabled: false,
  },
  {
    title: 'Latest Updates',
    route: '/latest-updates',
    icon: <img src={'images/helpCenter/latestUpdates.svg'} alt='updates' />,
    disabled: false,
  },
];

export const helpTickets = [
  {
    trackingNumbers: '#245308233',
    date: '06/20/22',
    message: 'How to access tracking #',
  },
  {
    trackingNumbers: '#245308233',
    date: '06/20/22',
    message: 'How to access tracking #',
  },
  {
    trackingNumbers: '#245308233',
    date: '06/20/22',
    message: 'How to access tracking #',
  },

  {
    trackingNumbers: '#245308233',
    date: '06/20/22',
    message: 'How to access tracking #',
  },
  {
    trackingNumbers: '#245308233',
    date: '06/20/22',
    message: 'How to access tracking #',
  },
];

export const latestUpdatesJuly = [
  {
    description: (
      <>
        <p>
          We currently have all items checked in delivered before Sunday,{' '}
          <b>Octuber 31st</b>. If you are missing anything delivered before
          then, please submit your issues <u>here.</u>
        </p>
      </>
    ),
    date: 'July 20, 2022',
  },
  {
    description: (
      <>
        <p>
          We currently have all items checked in delivered before Sunday,{' '}
          <b>Octuber 31st</b>. If you are missing anything delivered before
          then, please submit your issues <u>here.</u>
        </p>
      </>
    ),
    date: 'July 20, 2022',
  },
  {
    description: (
      <>
        <p>
          We currently have all items checked in delivered before Sunday,{' '}
          <b>Octuber 31st</b>. If you are missing anything delivered before
          then, please submit your issues <u>here.</u>
        </p>
      </>
    ),
    date: 'July 20, 2022',
  },
  {
    description: (
      <>
        <p>
          We currently have all items checked in delivered before Sunday,{' '}
          <b>Octuber 31st</b>. If you are missing anything delivered before
          then, please submit your issues <u>here.</u>
        </p>
      </>
    ),
    date: 'July 20, 2022',
  },
];

export const latestUpdatesJune = [
  {
    description: (
      <>
        <p>
          We currently have all items checked in delivered before Sunday,{' '}
          <b>Octuber 31st</b>. If you are missing anything delivered before
          then, please submit your issues <u>here.</u>
        </p>
      </>
    ),
    date: 'July 20, 2022',
  },
  {
    description: (
      <>
        <p>
          We currently have all items checked in delivered before Sunday,{' '}
          <b>Octuber 31st</b>. If you are missing anything delivered before
          then, please submit your issues <u>here.</u>
        </p>
      </>
    ),
    date: 'July 20, 2022',
  },
];

export const breadCrumbGettingStarted = [
  {
    name: 'Help Center',
    route: '/help-center',
  },
  {
    name: 'Quick Start Guide',
    route: '/getting-started',
  },
];

export const breadCrumbReadFAQs = [
  {
    name: 'Help Center',
    route: '/help-center',
  },
  {
    name: 'FAQs',
    route: '/read-faqs',
  },
];

export const breadCrumbOpenHelpTicket = [
  {
    name: 'Help Center',
    route: '/help-center',
  },
  {
    name: 'Your Help Tickets',
    route: '/open-help-ticket',
  },
  {
    name: 'Submit a Ticket',
    route: '/open-help-ticket',
  },
];

export const breadCrumbCheckTicketStatus = [
  {
    name: 'Help Center',
    route: '/help-center',
  },
  {
    name: 'Your Help Tickets',
    route: '/open-help-ticket',
  },
  {
    name: 'Check Ticket Status',
    route: '/check-ticket-status',
  },
];

export const breadCrumbLatestUpdate = [
  {
    name: 'Help Center',
    route: '/help-center',
  },
  {
    name: 'Latest Updates',
    route: '/latest-updates',
  },
  {
    name: 'Link Disabled',
    route: '!#',
  },
];

import { useMutation } from 'react-query';
import addInsuranceEnroll from '../../endPoints/trackingNumbers/addInsuranceEnrolled';

export const useAddInsuranceEnrroled = (enrolled: any, messageError: any, closeModal: any, refetch: any) => {
  return useMutation(addInsuranceEnroll, {
    onSuccess: (data) => {
      closeModal();
      if (data.enrolled) {
        enrolled(true);
        closeModal();
        refetch();
      } else {
        messageError(true);
      }
    },
    onError: () => {
      messageError(true);
    }
  });
};
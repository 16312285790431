import { useContext } from 'react';
import { useQuery } from 'react-query';
import fetchMyInfo from '../../endPoints/myAccount/getMyInfo';
import { AuthContext } from '../../../context/useSaveDataSession';

const getMyInfo = () => {
  const { logout } = useContext<any>(AuthContext);

  const { error, data, isLoading } = useQuery(['GET_MY_INFO'], fetchMyInfo, {
    onSuccess: (data) => {
      if (data.message === 'Token has expired') {
        logout();
      } else if (data.status === 401) {
        logout();
      }
    },
  });

  if (data) {
    return { error, response: data, isLoading };
  } else {
    return { error, response: data, isLoading };
  }
};

export default getMyInfo;

import {
  IListPayeesName,
  IListPayeesInternal,
} from '../../../interfaces/paymentRequests/listPayeesName';

export interface IServiceTransform {
  data: IListPayeesInternal[];
}

const transformDataListPayeesName = (
  data: IListPayeesName,
): IServiceTransform => {
  const dataTransformed = data.data;

  return { data: dataTransformed };
};

export default transformDataListPayeesName;

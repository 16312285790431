/* eslint-disable no-undef */
import cookie from 'react-cookies';

const listPayeesName = async () => {
  const response = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}buyers/payees-names?status=1`,
    {
      headers: {
        Authorization: `Bearer ${cookie.load('token')}`,
      },
    },
  ).then((fetchResponse) => fetchResponse.json());

  return response;
};

export default listPayeesName;

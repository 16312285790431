import React from 'react';
import { Alert, AlertTitle } from '@mui/material';
import { IAlertType } from '../../../interfaces/activeDeal';

const GeneralAlert = ({
  alertType,
  alertMessage,
  alertTitle,
  setShowAlert,
  fontSize,
}: any) => {
  const alert: IAlertType = {
    show: false,
    message: alertMessage,
    title: alertTitle,
    type: alertType || 'success',
  };

  const handleCloseAlert = () => {
    setShowAlert({
      show: false,
      message: '',
      title: '',
      type: alertType || 'success',
    });
  };

  return (
    <div
      className={`fixed w-11/12 top-3 right-4 lg:right-3 lg:top-12'
      } z-10 lg:w-3/12`}
      data-testid='generalAlertTestId'>
      <Alert
        onClose={handleCloseAlert}
        severity={`${alert.type}`}
        variant='filled'
        sx={{ fontSize: fontSize }}>
        <AlertTitle>
          <strong>{alert.title}</strong>
        </AlertTitle>
        {alert.message}
      </Alert>
    </div>
  );
};

export default GeneralAlert;

import React from 'react';

const CardLatestUpdateHelper = ({ description, date }: any) => {
  return (
    <div className='p-5 bg-gray-100 rounded-3xl w-full flex flex-col-reverse md:flex-row md:justify-between mt-10'>
      <p className='mt-5'>{description}</p>
      <span className='text-gray-400 md:ml-[4rem] md:w-40 text-right'>
        {date}
      </span>
    </div>
  );
};

export default CardLatestUpdateHelper;

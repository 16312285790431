import { useContext } from 'react';
import { useQuery } from 'react-query';
import {
  IFilterAchAccounts,
  IGetAchAccountsServiceResponse,
} from '../../../interfaces/myAccount';
import fetchAchAccounts from '../../endPoints/myAccount/getAchAccounts';
import getAchAccountsTransform from '../../transforms/myAccount/getAchAccounts';
import { AuthContext } from '../../../context/useSaveDataSession';

const getAchAccounts = (
  params: IFilterAchAccounts,
): IGetAchAccountsServiceResponse => {
  const { logout } = useContext<any>(AuthContext);

  const { error, data, isLoading } = useQuery(
    ['GET_ACH_ACCOUNTS', params],
    () => {
      return fetchAchAccounts(params);
    },
    {
      onSuccess: (data) => {
        if (data.message === 'Token has expired') {
          logout();
        } else if (data.status === 401) {
          logout();
        }
      },
    },
  );

  if (data) {
    return { error, response: getAchAccountsTransform(data), isLoading };
  } else {
    return { error, response: data, isLoading };
  }
};

export default getAchAccounts;

import React from 'react';
import Login from '../pages/Login';
import RedirectDealDetailWeweb from '../pages/RedirectDealDetailWeweb';
import RedirectToNewPortal from '../pages/RedirectToNewPortal';

export const freeRoutes = [
  {
    path: '/',
    component: <Login />,
  },
  {
    path: '/deals',
    component: <RedirectDealDetailWeweb />,
    title: 'Active Deals',
  },
  {
    path: '/active-deals',
    component: <RedirectToNewPortal />,
    title: 'Active Deals',
  },
  {
    path: '/active-deal/:id',
    component: <RedirectToNewPortal />,
    title: 'active Deal',
  },
  {
    path: '/tracking-numbers',
    component: <RedirectToNewPortal />,
    title: 'Tracking Numbers',
  },
  {
    path: '/my-deals-cart',
    component: <RedirectToNewPortal />,
    title: 'My Deals Cart',
  },
];

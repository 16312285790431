import { createSelectorHooks } from 'auto-zustand-selectors-hook';
import create from 'zustand';
import { alertSliceMobileView } from './alertSliceMobileView';

let store = create((set, get) => ({
  ...alertSliceMobileView(set, get),
}));

store = createSelectorHooks(store);

export const useStore = store;

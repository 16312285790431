/* eslint-disable no-undef */
import cookie from 'react-cookies';

const fetchApiGet = async (filterOptions: any) => {
  const paramsBrandName = filterOptions.brand_name.map((opt: any) => {
    const option = `&brand_name[]=${opt}`;

    return option;
  });

  const paramsVendor = filterOptions.vendor.map((opt: any) => {
    const option = `&vendor[]=${opt}`;

    return option;
  });

  const paramsPrice = filterOptions.price.map((opt: any) => {
    const option = `&range_price[]=${opt}`;

    return option;
  });

  const paramsPriceType = filterOptions.list_price_type.map((opt: any) => {
    const option = `&list_price_type[]=${opt}`;

    return option;
  });

  const response = await fetch(
    `${
      process.env.REACT_APP_BACKEND_URL
    }buyers/active-deals?${paramsBrandName.join('&')}${paramsVendor.join(
      '&',
    )}${paramsPrice.join('&')}${paramsPriceType.join(
      '&',
    )}&is_expired=${filterOptions.expired.toString()}&start=${
      filterOptions.start
    }&limit=${filterOptions.limit}`,
    {
      method: 'GET',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        Authorization: `Bearer ${cookie.load('token')}`,
        'Content-type': 'application/json',
      },
      redirect: 'follow',
      referrerPolicy: 'strict-origin-when-cross-origin',
    },
  ).then((fetchResponse) => fetchResponse.json());
  return response;
};

export default fetchApiGet;

import tw, { styled } from 'twin.macro';

export const TableContainer = styled.div`
  .disabled-row {
    opacity: 0.5;
  }

  button:disabled {
    background-color: rgb(226 232 240);
    padding: 5px 0;
  }

  .customDisabledCartButton:disabled:hover {
    color: rgb(59 130 246);
  }

  .customButtonDisabled:disabled {
    background-color: #d6dce3;
    padding: 5px 0;
    padding-right: 14px;
  }

  .dealInfoSpanStyle {
    color: #b2b2b2;
    font-size: 0.7rem;
  }

  .dealInfoPStyle {
    font-size: 0.8rem;
  }

  .ant-table-title {
    padding: 0;
  }

  .ant-table-tbody > tr > td {
    padding: 18px 10px;
  }

  @media screen and (min-width: 992px) {
  }
`;

export const ViewCartDeals = styled.div`
  ${tw`flex flex-wrap `}

  @media (min-width: 1870px) {
    ${tw`gap-7 px-12`}
  }
`;

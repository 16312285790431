import {
  IListAchPayments,
  IListAchPaymentsInternal,
} from '../../../interfaces/paymentRequests/listAchPayments';

export interface IServiceTransform {
  data: IListAchPaymentsInternal[];
}

const transformDataListPaymentRequest = (
  data: IListAchPayments,
): IServiceTransform => {
  const dataTransformed = data.data;

  return { data: dataTransformed };
};

export default transformDataListPaymentRequest;

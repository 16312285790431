import React from 'react';
import { Link } from 'react-router-dom';
import * as Styles from './style';

interface IProps {
  data: IData;
}

export interface IData {
  color?: string;
  icon: any;
  title: string;
  path?: string;
}

const WideCard = ({ data }: IProps) => {
  const { color, icon, title, path } = data;

  return (
    <Styles.WideCardStyle color={color} data-testid='smallCardContainer'>
      <Link
        to={path ? path : ''}
        state={{ action: 'addTracking' }}
        className={path ? 'cursor-pointer' : 'cursor-default'}>
        <div className='flex justify-between items-center'>
          <span className='cardIcon'>{icon}</span>
          <p className='pl-6' data-testid='titleCard'>
            {title}
          </p>
        </div>
      </Link>
    </Styles.WideCardStyle>
  );
};

export default WideCard;

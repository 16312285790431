import { useContext } from 'react';
import { useQuery } from 'react-query';
import fetchFilterSet from '../../endPoints/activeDeals/getFilterSet';
import getFilterSetTransform from '../../transforms/activeDeals/getFilterSetTransform';
import { AuthContext } from '../../../context/useSaveDataSession';

const getFilterSetService = (params: any): any => {
  const { logout } = useContext<any>(AuthContext);

  const { error, data, isLoading } = useQuery(
    ['GET_FILTERS_SET', params],
    () => {
      return fetchFilterSet(params);
    },
    {
      onSuccess: (data) => {
        if (data.message === 'Token has expired') {
          logout();
        } else if (data.status === 401) {
          logout();
        }
      },
    },
  );

  if (data) {
    return { error, response: getFilterSetTransform(data), isLoading };
  } else {
    return { error, response: data, isLoading };
  }
};

export default getFilterSetService;

import { useMutation } from 'react-query';
import putUpdateInfoUser from '../../endPoints/myAccount/putUpdateInfoUser';

const usePutUpdateInfoUser = () => {
  const { mutate } = useMutation(putUpdateInfoUser);

  return { mutate };
};

export default usePutUpdateInfoUser;

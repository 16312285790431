export const auth = (set) => ({
  authInfo: [],
  buyerInfo: [],
  dashboardInfo: [],
  setAuthInfo: (response) =>
    set(() => ({
      authInfo: response,
    })),
  setBuyerInfo: (response) =>
    set(() => ({
      buyerInfo: response,
    })),
  setDashboardInfo: (response) =>
    set(() => ({
      dashboardInfo: response,
    })),
});

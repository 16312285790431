import { useStore } from '../store';

const useStatesGlobalStore = () => {
  const authInfo = useStore.useAuthInfo();
  const setAuthInfo = useStore.useSetAuthInfo();

  const buyerInfo = useStore.useBuyerInfo();
  const setBuyerInfo = useStore.useSetBuyerInfo();

  const dashboardInfo = useStore.useDashboardInfo();
  const setDashboardInfo = useStore.useSetDashboardInfo();

  const selectedCardActiveDeals = useStore.useSelectedCardActiveDeals();
  const setSelectedCardActiveDeals = useStore.useSetSelectedCardActiveDeals();

  const callRefetch = useStore.useCallRefetch();
  const setCallRefetch = useStore.useSetCallRefetch();

  return {
    authInfo,
    buyerInfo,
    dashboardInfo,
    selectedCardActiveDeals,
    callRefetch,

    setAuthInfo,
    setBuyerInfo,
    setDashboardInfo,
    setSelectedCardActiveDeals,
    setCallRefetch
  };
};

export default useStatesGlobalStore;

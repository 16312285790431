import { styled } from 'twin.macro';

export const TableContainer = styled.div`
  .itemTableTd .ant-table-tbody > tr > td {
    padding: 23px 10px 20px 10px;
  }

  .tableStyles .ant-table {
    padding-bottom: 0rem;
  }
`;

import { IDashboard, IData } from '../../../interfaces/dashboard';

export interface IServiceTransform {
  data: IData;
  meta: {
    status: boolean;
  };
}

const transformDataCardsDashboard = (data: IDashboard): IServiceTransform => {
  const dataTranformed = data.dashboard;
  const metaTranform = {
    status: data.status,
  };

  return { data: dataTranformed, meta: metaTranform };
};

export default transformDataCardsDashboard;

import React, { useEffect, useState } from 'react';
import { SearchOutlined } from '@ant-design/icons';
import { PlusCircleOutlined } from '@ant-design/icons';
import CheckTicketStatusCard from '../../../components/CheckTicketStatus';
import {
  infoCards,
  breadCrumbCheckTicketStatus,
} from '../../../constants/index';
import CardHelper from '../../../components/cardHelper';
import BreadCrumbs from '../../../../../components/UI/breadCrumbs/index';
import useGetTickets from '../../../../../api/services/helpCenter/getTickets';
import useStatesGlobalStore from '../../../../../hooks/useStatesGlobalStore';
import { Link, useLocation } from 'react-router-dom';
import CardContent from '../../../../../components/UI/cardContent';
import { themeCustom } from '../../../../../theme';

const CheckTicketStatus = () => {
  const { buyerInfo } = useStatesGlobalStore();
  const [searchTicket, setSearchTicket] = useState('');
  const [ticketsUser, setTicketUser] = useState([]);
  const [searchTicketList, setSearchTicketList] = useState([]);
  const [filterStatus, setFilterStatus] = useState('all');

  const location = useLocation();

  let state: any = location?.state;

  useEffect(() => {
    if (state?.search) {
      setSearchTicket(state?.search.toString());
    }
  }, [state]);

  const { response } = useGetTickets(buyerInfo?.emailAddress);

  useEffect(() => {
    if (response) {
      setTicketUser(response);
    }
  }, [response]);

  useEffect(() => {
    const busqueda = searchTicket.toLocaleLowerCase();

    if (searchTicket.length > 0 && ticketsUser.length > 0) {
      const filtro = ticketsUser.filter((data: any) => {
        return (
          data?.subject.toLowerCase().includes(busqueda) ||
          data?.type.toLowerCase().includes(busqueda) ||
          data?.id === Number(busqueda)
        );
      });

      setSearchTicketList(filtro);
    } else {
      setSearchTicketList([]);
    }
  }, [searchTicket, state]);

  useEffect(() => {
    if (filterStatus === 'all') {
      setSearchTicketList(ticketsUser);
      return;
    }
    if (filterStatus === 'open') {
      const filtro = ticketsUser.filter((data: any) => data.status === 2);

      setSearchTicketList(filtro);
      return;
    }
    if (filterStatus === 'pending') {
      const filtro = ticketsUser.filter((data: any) => data.status === 3);

      setSearchTicketList(filtro);
      return;
    }
    if (filterStatus === 'resolved') {
      const filtro = ticketsUser.filter((data: any) => data.status === 4);

      setSearchTicketList(filtro);
      return;
    }
    if (filterStatus === 'closed') {
      const filtro = ticketsUser.filter((data: any) => data.status === 5);

      setSearchTicketList(filtro);
      return;
    }
  }, [searchTicket, state, filterStatus, ticketsUser]);

  return (
    <>
      <div className='flex justify-between mt-5 mb-10'>
        <div className='mt-5 flex gap-4 text-gray-400'>
          <BreadCrumbs links={breadCrumbCheckTicketStatus} />
        </div>
        <div className='lg:flex gap-4 hidden'>
          <SearchOutlined
            style={{
              position: 'absolute',
              marginTop: '28px',
              height: '20px',
              marginLeft: '25%',
            }}
          />

          <span className='text-sm m-auto fontSharpGrotesk22Medium'>
            search for anything
          </span>
          <input
            type='text'
            value={searchTicket}
            placeholder='type here...'
            className='rounded-3xl p-6 font-semibold text-black w-[22rem]'
            onChange={(e) => setSearchTicket(e.target.value)}
          />
        </div>
      </div>
      <div className='lg:flex hidden flex-wrap bg-white rounded-3xl w-full p-10 h-[60rem] overflow-y-scroll'>
        <div className='block'>
          <span className='fontSharpGrotesk22Medium text-4xl'>
            Your Help Tickets
          </span>
          <p className='w-[19rem] leading-6 mt-10 text-lg'>
            Keep track of your help tickets, review responses, and create new
            ones.
          </p>
          <Link to={'/open-help-ticket'}>
            <button className='bg-blue-200 px-8 py-6 w-full rounded-3xl font-semibold my-8'>
              <PlusCircleOutlined
                style={{
                  fontSize: '24px',
                  color: '#178DFF',
                  marginRight: '20px',
                }}
              />
              Open a Help Ticket
            </button>
          </Link>
        </div>
        <div className='ml-20 w-[50rem]'>
          <span className='fontSharpGrotesk22Medium text-2xl text-blue-500'>
            All Help Tickets
          </span>
          <hr className='my-6' />
          <div className='my-6'></div>
          <div>
            {searchTicketList.length > 0 ? (
              <>
                {searchTicketList.map((ticket: any, index: any) => (
                  <CheckTicketStatusCard ticket={ticket} key={index} />
                ))}
              </>
            ) : (
              <>
                {ticketsUser?.map((ticket: any, index: any) => (
                  <CheckTicketStatusCard ticket={ticket} key={index} />
                ))}
              </>
            )}
          </div>
          <hr className='my-6' />
          <div className='mt-10'>
            <span className='text-gray-400 underline'>
              Load earlier help tickets
            </span>
          </div>
        </div>
      </div>

      <div>
        <h3 className='fontSharpGrotesk22Medium mb-10 text-3xl lg:hidden'>
          Ticket Status
        </h3>
        <div className='lg:hidden'>
          <ul className='leading-10 mt-10 flex flex-wrap'>
            <li
              className={`cursor-pointer mx-1 px-4 rounded-lg text-gray-400 border mt-3  ${
                filterStatus === 'all'
                  ? 'bg-blue-500 text-white border-blue-500 font-bold '
                  : 'text-gray-400 border-gray-500 font-bold'
              }`}
              onClick={() => setFilterStatus('all')}>
              All
            </li>
            <li
              className={`cursor-pointer mx-1 px-4 rounded-lg text-gray-400 border  mt-3 ${
                filterStatus === 'open'
                  ? 'bg-blue-500 text-white border-blue-500 font-bold'
                  : 'text-gray-400 border-gray-500 font-bold'
              }`}
              onClick={() => setFilterStatus('open')}>
              Open
            </li>
            <li
              className={`cursor-pointer mx-1 px-4 rounded-lg text-gray-400 border  mt-3 ${
                filterStatus === 'pending'
                  ? 'bg-blue-500 text-white border-blue-500 font-bold'
                  : 'text-gray-400 border-gray-500 font-bold'
              }`}
              onClick={() => setFilterStatus('pending')}>
              Pending
            </li>
            <li
              className={`cursor-pointer mx-1 px-4 rounded-lg text-gray-400 border  mt-3 ${
                filterStatus === 'resolved'
                  ? 'bg-blue-500 text-white border-blue-500 font-bold'
                  : 'text-gray-400 border-gray-500 font-bold'
              }`}
              onClick={() => setFilterStatus('resolved')}>
              Resolved
            </li>
            <li
              className={`cursor-pointer mx-1 px-4 rounded-lg text-gray-400 border  mt-3 ${
                filterStatus === 'closed'
                  ? 'bg-blue-500 text-white border-blue-500 font-bold'
                  : 'text-gray-400 border-gray-500 font-bold'
              }`}
              onClick={() => setFilterStatus('closed')}>
              Closed
            </li>
          </ul>
        </div>
        <CardContent
          styles={
            'lg:w-full mt-10 xl:w-8/12 xl:ml-8 xl:h-5/6 rounded-3xl p-10 p-3 lg:hidden'
          }>
          <div>
            {searchTicketList.length > 0 ? (
              <>
                {searchTicketList.map((ticket: any, index: any) => (
                  <CheckTicketStatusCard ticket={ticket} key={index} />
                ))}
              </>
            ) : (
              <h3>Not Found</h3>
            )}
          </div>
        </CardContent>
      </div>
      <div className='bg-white w-full rounded-3xl px-16 py-8 mt-10 hidden lg:block'>
        <h3 className='fontSharpGrotesk22Medium mb-10 text-lg'>
          What do you need help with?
        </h3>
        <div className='flex flex-wrap justify-center gap-8 mb-20'>
          {infoCards.map((card, index) => (
            <CardHelper
              key={index}
              route={card.route}
              style={'w-[18rem] py-2'}
              title={card.title}
              disabled={card.disabled}
            />
          ))}
        </div>
      </div>
      <div className='mt-5 lg:hidden'>
        {infoCards.map((card: any, index: number) => (
          <Link to={card.route} data-testid={card.title} key={index}>
            <div
              className={`flex mb-4 p-4 items-center rounded-lg w-full justify-between`}
              style={{
                backgroundColor: card.disabled
                  ? themeCustom.colors.grayLabels
                  : themeCustom.colors.lightBlue,
              }}>
              <div className='flex'>
                <p className='mr-8 ml-2 mb-0'>{card.icon}</p>
                <p className='mb-0'>{card.title}</p>
              </div>
              <img src='images/dashboard/arrow.png' alt='arrow' />
            </div>
          </Link>
        ))}
      </div>
    </>
  );
};

export default CheckTicketStatus;

import { useContext } from 'react';
import { useQuery } from 'react-query';
import fetchUpdatesInfo from '../../endPoints/helpCenter/getUpdatesInfo';
import updatesTransform, {
  IResTransform,
} from '../../transforms/helpCenter/updatesInfo';
import { AuthContext } from '../../../context/useSaveDataSession';

interface IResponseService {
  error: any;
  response: IResTransform;
  isLoading: any;
}

const useGetUpdatesInfo = (params: any): IResponseService => {
  const { logout } = useContext<any>(AuthContext);

  const { error, data, isLoading } = useQuery(
    ['GET_UPDATES_INFO', params],
    () => {
      return fetchUpdatesInfo(params);
    },
    {
      onSuccess: (data) => {
        if (data.message === 'Token has expired') {
          logout();
        } else if (
          data.error ===
          'array_key_exists() expects parameter 2 to be array, null given'
        ) {
          logout();
        }
      },
    },
  );

  if (data) {
    return { error, response: updatesTransform(data), isLoading };
  } else {
    return { error, response: data, isLoading };
  }
};

export default useGetUpdatesInfo;

import tw, { styled } from 'twin.macro';

export const CardStyle = styled.div`
  border-radius: 2.4em;

  ${tw`px-6 py-4 mt-3 w-full`}
  ${(props) =>
    props.color
      ? `background-color: ${props.color}`
      : `background-color: white`};

  hr {
    border-top: 1.5px solid black;
  }

  select {
    ${(props) =>
      props.color
        ? `background-color: ${props.color}`
        : `background-color: white`};
    ${tw`border-2 border-black rounded-md`}
    padding:7px;
  }

  #titleCard {
    ${tw`-mr-14 ml-4 text-xl`}
  }

  .titleCardDivStyle {
    ${tw`flex justify-between items-center mb-2`}
  }

  .moneyValueStyles {
    ${tw` mb-3`}
  }
`;

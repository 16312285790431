import React, { useState } from 'react';
// import { Link } from 'react-router-dom';
import { Table } from 'antd';
import { Popover } from 'antd';
import { PlusCircleOutlined } from '@ant-design/icons';
import { Divider } from '@mui/material';
import {
  dataTableFields,
  dateFilter,
  searchFilter,
  selectFilters,
  stylesFilterSectionSearchAndDate,
} from './constants/index';
import Filter from '../../components/UI/filter';
import { IFilters } from '../../interfaces/paymentRequests';
import CardContent from '../../components/UI/cardContent';
import getPaymentRequest from '../../api/services/paymentRequest/index';
import usePriceFormat from '../../hooks/usePriceFormat';
import useStatesGlobalStore from '../../hooks/useStatesGlobalStore';
import { IAlertType } from '../../interfaces/activeDeal/index';
import GeneralAlert from '../../components/UI/generalAlert/GeneralAlert';
import ModalChecksRequests from './containers/ModalChecksRequests';
import * as Styles from './style';
import { DrawerComponentUI } from '../../components/UI/sideSheetNew/index';
import useResponsive from '../../hooks/useResponsive';
import MobileFilter from '../../components/UI/mobileFilter/index';
import globalStoreNoLocalStore from '../../hooks/globalStoreNoLocalStore';
import PaginationCustom from '../../components/UI/PaginationCustom';
import getInfoCards from '../../api/services/dashboard/getInfoCards';

const paymentRequest = () => {
  const { dashboardInfo, setDashboardInfo } = useStatesGlobalStore();
  const [filterOptions, setFilterOptions] = React.useState<IFilters>({
    paymentType: [],
    value: [],
    checkNumberOrName: '',
    date_from: '',
    date_until: '',
  });

  const { response: dataDashboard } = getInfoCards();

  const [current, setCurrent] = useState(1);
  const [sizeItemsPage, setSizeItemPage] = useState(10);

  const [viewPopover, setViewPopover] = useState<boolean>(false);

  const [viewPopoverMobile, setViewPopoverMobile] = useState({
    active: false,
  });

  const { isMobile } = useResponsive();

  const [showAlert, setShowAlert] = useState<IAlertType>({
    message: '',
    show: false,
    title: '',
    type: 'success',
  });

  const [currentBalance, setCurrentBalance] = React.useState(0);

  React.useEffect(() => {
    if (dataDashboard?.data) {
      setDashboardInfo({ ...dashboardInfo, info: dataDashboard?.data });
    }
  }, [dataDashboard?.data]);

  React.useEffect(() => {
    setCurrentBalance(
      dashboardInfo?.info?.total_pending_payment -
        dashboardInfo?.info?.total_pending_requested,
    );
  }, [dashboardInfo]);

  const dateTransform =
    filterOptions.date_from !== '' && filterOptions.date_until !== ''
      ? `${filterOptions.date_from} ${filterOptions.date_until}`
      : '';

  const { response, isLoading } = getPaymentRequest({
    payee_name: filterOptions.checkNumberOrName,
    payment_type: filterOptions.paymentType,
    price: filterOptions.value,
    date_range: dateTransform,
    start: current,
    limit: sizeItemsPage,
  });

  const {
    alertSliceMobile,
    alertTurnScreenMobile,
    setAlertSliceMobile,
    setAlertTurnScreenMobile,
  } = globalStoreNoLocalStore();

  React.useEffect(() => {
    if (
      alertSliceMobile.forUser !== 'user' &&
      alertTurnScreenMobile.forUser !== 'user'
    ) {
      setAlertSliceMobile();
      setAlertTurnScreenMobile();
    }
  }, [alertSliceMobile.forUser, alertTurnScreenMobile.forUser]);

  // React.useEffect(() => {
  //   refetch();
  // }, [filterOptions]);

  const clearFilters = () => {
    setFilterOptions({
      paymentType: [],
      value: [],
      checkNumberOrName: '',
      date_from: '',
      date_until: '',
    });
  };

  return (
    <>
      {showAlert.show && (
        <GeneralAlert
          alertType={showAlert.type}
          alertMessage={showAlert.message}
          alertTitle={showAlert.title}
          setShowAlert={setShowAlert}
          fontSize={17}
        />
      )}
      <div className='lg:flex w-full justify-between '>
        <div className='title 2xl:mb-0 mt-11'>
          <h1 className='lg:ml-5 text-2xl lg:text-4xl fontSharpGrotesk22Medium text-black '>
            Payment Requests
          </h1>
        </div>
        <CardContent
          styles={'hidden lg:flex rounded-b-3xl p-8 xl2_1:w-[65rem]'}
          id='header'>
          <div className='flex card gap-3 w-[65%]' id='cards'>
            <div className='bg-[#6FB8FF] xl2:pr-4 xl2_1:pr-24 2xl:pr-45  pl-10 rounded-3xl 2xl:w-[50%]'>
              <h2 className='mt-5  fontGTAmericanR  '>Current Balance</h2>
              <h3 className='text-3xl xl2:mt-10 xl2_1:mt-12 fontGTAmericanR'>
                {usePriceFormat(currentBalance)}
              </h3>
            </div>
            <div className='bg-[#BEDFFF] xl2:pr-4 xl2_1:pr-24 2xl:pr-0 2xl:w-[50%] pl-10 rounded-3xl'>
              <h2 className='mt-5 fontGTAmericanR'>Pending Payment</h2>
              <h3 className='text-3xl xl2:mt-10 xl2_1:mt-12 fontGTAmericanR'>
                {usePriceFormat(dashboardInfo.info.total_pending_payment || 0)}
              </h3>
            </div>
          </div>
          <div className='ml-9'>
            <Divider variant='middle' orientation='vertical' />
          </div>
          <div className='ml-9'>
            <Popover
              visible={viewPopover}
              placement='bottom'
              trigger='click'
              data-testid='formRequestPayment'
              content={
                <ModalChecksRequests
                  setViewPopover={setViewPopover}
                  setShowAlert={setShowAlert}
                />
              }>
              <button
                data-testid='formRequestPayment'
                onClick={() => setViewPopover(!viewPopover)}
                className='flex bg-white text-blue-400 mb-auto px-8 py-5 rounded-3xl border-2 border-[#E3E3E3]'>
                <PlusCircleOutlined style={{ fontSize: '24px' }} />
                <span className='ml-5 fontGTAmericanR'>Request a Payment</span>
              </button>
            </Popover>
            <a
              className='flex mt-3 ml-2 underline text-gray-400 fontGTAmericanR text-[12px]'
              href='/my-account#payments'>
              Add Payee Name or ACh Account
            </a>
          </div>
        </CardContent>
        <div className=' card gap-3 lg:hidden' id='cards'>
          <div className='bg-[#6FB8FF] w-3/4 py-1 pl-8 rounded-3xl'>
            <h2 className='mt-5  fontGTAmericanR'>Current Balance</h2>
            <div className='pr-3'>
              <Divider variant='fullWidth' orientation='horizontal' />
            </div>
            <h3 className='text-3xl fontGTAmericanR'>
              {usePriceFormat(currentBalance)}
            </h3>
          </div>
          <div className='bg-[#BEDFFF] w-3/4 py-1 pl-8 rounded-3xl mt-4 my-4'>
            <h2 className='mt-5 fontGTAmericanR'>Pending Payment</h2>
            <div className='pr-3'>
              <Divider variant='fullWidth' orientation='horizontal' />
            </div>
            <h3 className='text-3xl fontGTAmericanR'>
              {usePriceFormat(dashboardInfo?.info.total_pending_payment || 0)}
            </h3>
          </div>
        </div>
      </div>

      <div className='block lg:hidden flex-wrap xl:gap-4 bg-white xl:bg-inherit rounded-3xl w-full xl:w-auto mb-4 border-[1px]'>
        <div className='ml-6'>
          <Popover
            visible={viewPopoverMobile.active}
            placement='bottom'
            trigger='click'
            data-testid='formRequestPayment'>
            <button
              data-testid='formRequestPayment'
              onClick={() =>
                setViewPopoverMobile({ active: !viewPopoverMobile.active })
              }
              className='flex justify-between bg-white text-blue-400 w-full mb-auto px-2 py-5 rounded-full'>
              <div>
                <PlusCircleOutlined style={{ fontSize: '24px' }} />
                <span className='ml-5 fontGTAmericanR'>Request a Payments</span>
              </div>
              <img
                alt='delete icon'
                className='h-4 w-2 xl:hidden mr-3'
                height='17'
                src='images/trackingNumbers/blueRightArrow.png'
                width='12'
              />
            </button>
            <div className='pr-3'>
              <Divider variant='fullWidth' orientation='horizontal' />
            </div>
          </Popover>
          <a href='/my-account#payments'>
            <div className='flex items-center ml-2 w-full justify-between pr-4 py-5'>
              <div className='flex items-center'>
                <div>
                  <img
                    src='./images/sideBar/account.png'
                    alt='icon'
                    width={23}
                    height={18}
                  />
                </div>
                <p className='m-0 flex ml-5 text-blue-400 fontGTAmericanR'>
                  Add Payee Name or ACh Account
                </p>
              </div>
              <img
                alt='delete icon'
                className='h-4 w-2 xl:hidden mr-3'
                height='17'
                src='images/trackingNumbers/blueRightArrow.png'
                width='12'
              />
            </div>
          </a>
        </div>
      </div>

      {viewPopoverMobile.active && (
        <DrawerComponentUI
          anchor={'bottom'}
          modalBorderTopRightRadius={'30px'}
          viewRender={
            <ModalChecksRequests
              setViewPopover={setViewPopoverMobile}
              setShowAlert={setShowAlert}
              isMobile={true}
            />
          }
          closeModal={setViewPopoverMobile}
          width={'100%'}
        />
      )}

      <div className='mt-9 hidden xl:block'>
        <Filter
          filterOptions={filterOptions}
          setFilterOptions={setFilterOptions}
          searchFilters={searchFilter}
          selectFilters={selectFilters}
          rangeDateFilter={dateFilter}
          stylesFilterSectionSearchAndDate={stylesFilterSectionSearchAndDate}
          isSaveFilterVisible={false}
          showFilterTitle={true}
        />
      </div>

      {isMobile && (
        <div className='mt-4'>
          <MobileFilter
            clearFilters={clearFilters}
            filterOptions={filterOptions}
            formStyles={'flex flex-wrap lg:justify-between'}
            isSaveFilterVisible={true}
            searchFilter={searchFilter}
            setFilterOptions={setFilterOptions}
            // simpleSelectFilters={selectFiltersTN}
            selectFilters={selectFilters}
            stylesFilterSectionSearchAndDate={stylesFilterSectionSearchAndDate}
            showFilterTitle={false}
            rangeDateFilterMobile={dateFilter}
          />
        </div>
      )}

    <div style={{ flex: 1, overflowY: 'auto' }}> 
      <Styles.TableContainer>
        <Table
          scroll={{ x: 240 }}
          columns={dataTableFields}
          dataSource={response?.data}
          loading={isLoading}
          pagination={false}
          className='xl1:h-[calc(100vh-460px)] xlc:h-[calc(100vh-330px)] xl2:h-[calc(100vh-500px)] xl2_1:h-[calc(100vh-500px)]'
          style={{
            minHeight: '330px'
          }}
        />
      </Styles.TableContainer>
    </div>
        <PaginationCustom
          totalItems={response?.meta?.totals}
          current={current}
          setSizeItemPage={setSizeItemPage}
          sizeItemsPage={sizeItemsPage}
          setCurrent={setCurrent}
          mobile={isMobile}
        />
    </>
  );
};

export default paymentRequest;

import { useMutation } from 'react-query';
import updateEmailPreferencesFetch from '../../endPoints/myAccount/updateEmailPreferences';

const useUpdateEmailPreferences = () => {
  const { mutate } = useMutation(updateEmailPreferencesFetch);

  return { EmailPreferencesMutate: mutate };
};

export default useUpdateEmailPreferences;

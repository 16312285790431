/* eslint-disable no-undef */
import cookie from 'react-cookies';

const fetchToken = async (params: any) => {
  const receivingStatus = params.receiving_status.map((opt: any) => {
    const option = `&receiving_status[]=${opt}`;

    return option;
  });

  const paramsPrice = params.range_price.map((opt: any) => {
    const option = `&range_price[]=${opt}`;

    return option;
  });

  const pendingStatus = params.business_orders_status.map((opt: any) => {
    const option = `&business_orders_status[]=${opt}`;

    return option;
  });

  const res = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}buyers/pos?date_range=${
      params.date_range
    }${receivingStatus.join('&')}${paramsPrice.join('&')}${pendingStatus.join(
      '&',
    )}&search_items=${params.search_items}&limit=${params.limit}&start=${
      params.start
    }`,
    {
      headers: {
        Authorization: `Bearer ${cookie.load('token')}`,
      },
    },
  ).then((res) => res.json());
  return res;
};

export default fetchToken;

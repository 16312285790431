import React, { useState } from 'react';
import { Button } from '@mui/material';
import { themeCustom } from '../../../theme';
import { DrawerComponentUI } from '../../../components/UI/sideSheetNew';
import { DetailsPurchase } from './DetailsPurchase';
const { colors } = themeCustom;

export const RenderActionAndFetch = ({ data }: any) => {
  const [viewModal, setViewModal] = useState({ active: false, name: '' });

  return (
    <>
      <Button
        data-testid='button-view-modal'
        onClick={() => setViewModal({ active: true, name: '' })}
        sx={{
          width: '10rem',
          position: 'absolute',
          right: '2.3rem',
          top: '2rem',
          textTransform: 'none',
          color: colors.grayLabels,
        }}>
        <img
          className=' mb-3'
          src='images/activeDeals/greyEye.svg'
          alt='quick commit icon'
          width='17'
          height='16'
        />
        <p className='mb-3 ml-2 '>View Box Details</p>
      </Button>
      {viewModal.active && (
        <DrawerComponentUI
          width='60rem'
          anchor={'right'}
          viewRender={
            <DetailsPurchase
              po_number={data.po_number}
              setViewModal={setViewModal}
            />
          }
          closeModal={setViewModal}
        />
      )}
    </>
  );
};

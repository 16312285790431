import React, { useState } from 'react';
import ForumOutlinedIcon from '@mui/icons-material/ForumOutlined';
import moment from 'moment';
import useGetIndividualTickets from '../../../api/services/helpCenter/getIndividualTickets';
import SendIcon from '@mui/icons-material/Send';
import usePostAddNoteToTicket from '../../../api/services/helpCenter/postAddNoteToTicket';
import { useQueryClient } from 'react-query';
import useGetConversationTickets from '../../../api/services/helpCenter/getConversationTicket';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import AudioFileIcon from '@mui/icons-material/AudioFile';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import VideoFileIcon from '@mui/icons-material/VideoFile';

const CheckTicketStatusCard = ({ ticket }: any) => {
  const [active, setActive] = useState(false);
  const [idConversations, setIdConversations] = useState('');
  const [message, setMessage] = useState('');
  const [imageFile, setImageFile] = useState([]);

  const queryClient = useQueryClient();

  const { response } = useGetIndividualTickets(idConversations);
  const { response: listConversation } =
    useGetConversationTickets(idConversations);

  const { mutate } = usePostAddNoteToTicket();

  const handleCollapse = (id: any) => {
    setActive(!active);
    setIdConversations(id);
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();

    if (message.length === 0) {
      return;
    }

    mutate(
      {
        id: idConversations,
        message: { body: message, private: false, incoming: true },
        imageFile,
      },
      {
        onSuccess: () => {
          setMessage('');
          setImageFile([]);
          queryClient.invalidateQueries('GET_CONVERSATION_TICKET');
        },
      },
    );
  };

  const verifyUserMessage = (data: any) => {
    if (!data.incoming && !data.private) {
      return (
        <div className='rounded-tl-3xl rounded-tr-3xl rounded-br-3xl lg:w-[35rem] bg-white p-5 mb-2'>
          <div className='text-gray-400 font-semibold'>
            <span>{moment(data?.created_at).format('MM-DD-YY h:mm a')}</span>
          </div>
          <div className='lg:mt-2'>
            {data.body_text
              .replace('Please do not reply to this e-mail directly.', '')
              .replace('Use the help center in your buyer account.', '')
              .replace('Go to ticket center', '')}
          </div>
          {data?.attachments.length > 0 && (
            <>
              <p className='text-sky-600 font-bold mt-2'>Files</p>
              <div className='mt-3 flex w-full gap-3'>
                {data?.attachments.map((file: any) => (
                  <>
                    {file.content_type === 'image/png' && (
                      <a
                        className='rounded-md'
                        href={file.attachment_url}
                        target='_blank'
                        rel='noopener noreferrer'>
                        <img
                          src={file.thumb_url}
                          alt=''
                          width={50}
                          className='rounded-md '
                        />
                      </a>
                    )}
                    {file.content_type === 'audio/mpeg' && (
                      <a
                        href={file.attachment_url}
                        target='_blank'
                        rel='noopener noreferrer'>
                        <AudioFileIcon
                          sx={{ fontSize: '3.3rem', color: '#1890ff66' }}
                        />
                      </a>
                    )}
                    {file.content_type === 'application/pdf' && (
                      <a
                        className='p-0'
                        href={file.attachment_url}
                        target='_blank'
                        rel='noopener noreferrer'>
                        <InsertDriveFileIcon
                          sx={{ fontSize: '3.3rem', color: '#1890ff66' }}
                        />
                      </a>
                    )}
                    {file.content_type === 'video/mp4' && (
                      <a
                        href={file.attachment_url}
                        target='_blank'
                        rel='noopener noreferrer'>
                        <VideoFileIcon
                          sx={{ fontSize: '3.3rem', color: '#1890ff66' }}
                        />
                      </a>
                    )}
                  </>
                ))}
              </div>
            </>
          )}
        </div>
      );
    } else if (data.incoming && !data.private) {
      // const support = data.support_email.split('@');

      return (
        <div
          className='rounded-tl-3xl rounded-tr-3xl rounded-bl-3xl lg:w-[32rem] bg-blue-200 my-10 p-5 lg:ml-[12rem]'
          style={{ wordWrap: 'break-word' }}>
          {/* <span className='font-semibold'>Response from {support[0]}</span> */}
          <div className='text-gray-400 font-semibold'>
            <span>{moment(data?.created_at).format('MM-DD-YY h:mm a')}</span>
          </div>
          <div className='mt-2'>{data.body_text}</div>
          {data?.attachments.length > 0 && (
            <>
              <p className='text-sky-600 font-bold mt-2'>Files</p>
              <div className='mt-3 flex w-full gap-3'>
                {data?.attachments.map((file: any) => (
                  <>
                    {file.content_type === 'image/png' && (
                      <a
                        href={file.attachment_url}
                        target='_blank'
                        rel='noopener noreferrer'>
                        <img
                          src={file.thumb_url}
                          alt=''
                          width={50}
                          className='rounded-md border'
                        />
                      </a>
                    )}
                    {file.content_type === 'audio/mpeg' && (
                      <a
                        href={file.attachment_url}
                        target='_blank'
                        rel='noopener noreferrer'>
                        <AudioFileIcon
                          sx={{ fontSize: '3.3rem', color: 'white' }}
                        />
                      </a>
                    )}
                    {file.content_type === 'application/pdf' && (
                      <a
                        href={file.attachment_url}
                        target='_blank'
                        rel='noopener noreferrer'>
                        <InsertDriveFileIcon
                          sx={{ fontSize: '3.3rem', color: 'white' }}
                        />
                      </a>
                    )}
                    {file.content_type === 'video/mp4' && (
                      <a
                        href={file.attachment_url}
                        target='_blank'
                        rel='noopener noreferrer'>
                        <VideoFileIcon
                          sx={{ fontSize: '3.3rem', color: 'white' }}
                        />
                      </a>
                    )}
                  </>
                ))}
              </div>
            </>
          )}
        </div>
      );
    }
  };

  return (
    <div className='bg-gray-100 py-5 px-8 rounded-3xl lg:mt-10 h-auto'>
      <div className='flex flex-wrap justify-between'>
        <div className='text-gray-400 font-semibold'>
          <span>
            # {ticket.id} | {moment(ticket.created_at).format('MM-DD-YY')}
          </span>
        </div>
        <div className='unread-notification flex gap-4'>
          <span className='my-auto'>
            <ForumOutlinedIcon />
            <span>{listConversation?.length}</span>
          </span>
        </div>
      </div>
      <div className={`w-full ${active ? 'hidden' : 'block'} mt-full mt-5`}>
        <span className='font-semibold'>{ticket.subject}</span>
      </div>

      <div className='wrap-collabsible' data-testid={`response#${ticket?.id}`}>
        <input
          id='collapsible2'
          className='toggle'
          type='checkbox'
          checked={active}
        />

        <div
          data-testid={`btnToggle#${ticket?.id}`}
          className='lbl-toggle text-gray-400 underline cursor-pointer w-full flex justify-end'
          onClick={() => handleCollapse(ticket.id)}
          role='button'></div>

        <div className='collapsible-content'>
          <div className='content-inner'>
            <div className='rounded-tl-3xl rounded-tr-3xl rounded-bl-3xl lg:w-[32rem] bg-blue-200 my-10 p-5 lg:ml-[12rem]'>
              <div className='text-gray-400 font-semibold'>
                <span>
                  {moment(response?.created_at).format('MM-DD-YY h:mm a')}
                </span>
              </div>
              <span className='font-semibold'>{response?.subject}</span>
              <p className='mt-2'>{response?.description_text}</p>
            </div>
            {response?.conversations?.length > 0 && (
              <>
                {listConversation?.map((data: any) => (
                  <>{verifyUserMessage(data)}</>
                ))}

                {response?.status !== 5 && response?.status !== 4 && (
                  <div className='flex justify-end my-4 items-center'>
                    <label htmlFor='file-input'>
                      {/* <img src="ruta-del-icono.png" alt="Icono" /> */}
                      <AttachFileIcon />
                    </label>
                    <input
                      onChange={(e: any) => {
                        setImageFile(e.target.files[0]);
                      }}
                      type='file'
                      id='file-input'
                      className='hidden'></input>
                    <input
                      type='text'
                      className='w-[30rem] mr-3 p-2'
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                    />

                    <SendIcon
                      className='mr-2 cursor-pointer'
                      onClick={(e) => handleSubmit(e)}
                    />
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CheckTicketStatusCard;

const fetchGetIndividualTickets = async (id: any) => {
  const res = await fetch(
    `https://usabuyinggroup.freshdesk.com/api/v2/tickets/${id}?include=conversations`,
    {
      headers: {
        Authorization: `Basic QlZKUWl2QVJXN1M4ZEs1MmhPQXc6WA==`,
        'Content-Type': 'application/json',
      },
      credentials: 'same-origin',
    },
  ).then((res) => res.json());
  return res;
};

export default fetchGetIndividualTickets;

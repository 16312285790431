import React from 'react';
import { Button } from '@mui/material';
import loadingIcon from '../../../images/spinner-2.gif';

const ModalDeleteCheckRequest = ({
  functions,
  item,
  isLoading,
  styles,
}: any) => {
  return (
    <div className={styles} data-testid='popDelete'>
      <span className='flex items-center'>
        <img
          alt='warning icon'
          className='h-8 w-8 mr-4'
          src='images/myAccount/warning-48.png'
          height={48}
          width={48}
        />
        Are you sure you want to remove it?
      </span>
      <div className='mt-6 flex justify-between mx-6'>
        <Button
          onClick={functions.handleDelete}
          data-testid={`deleteBtnPop#${item?.id}`}
          sx={{
            border: '1.5px solid',
            borderRadius: 2,
            textTransform: 'none',
            fontWeight: 'bold',
            '&:hover': {
              backgroundColor: '#0069d9',
              borderColor: '#0062cc',
              boxShadow: 'none',
              color: 'white',
            },
          }}>
          {isLoading ? (
            <img
              className='h-5 w-5'
              src={loadingIcon}
              alt='loading icon'
              width={200}
              height={200}
            />
          ) : (
            <span>Delete</span>
          )}
        </Button>
        <Button
          onClick={functions.handleCancel}
          data-testid={`cancelBtn#${item?.id}`}
          color='error'
          sx={{
            border: '1.5px solid',
            borderRadius: 2,
            textTransform: 'none',
            fontWeight: 'bold',
            '&:hover': {
              backgroundColor: '#d32f2f',
              borderColor: '#d32f2f',
              boxShadow: 'none',
              color: 'white',
            },
          }}>
          Cancel
        </Button>
      </div>
    </div>
  );
};

export default ModalDeleteCheckRequest;

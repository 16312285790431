import { styled } from 'twin.macro';

export const FiltersHrStyle = styled.hr`
  border-top: 2.5px solid #dfdcdc;
  margin-top: 1.6rem;
  margin-bottom: 3.3rem;
`;

export const TableContainer = styled.div`
  .tableStyles .ant-table table {
    padding: 0;
  }

  .tableHeadtyles .ant-table-thead > tr > th {
    background-color: white;
    color: #b2b2b2;
    font-weight: bold;
  }

  .tableBodyStyles .ant-table-tbody > tr > td {
    padding: 8px 20px;
  }
`;

import React from 'react';
import { RenderActionAndFetch } from '../components/RenderActionAndFetch';
import usePriceFormat from '../../../hooks/usePriceFormat';
import { dateFormatSorter } from '../../../helpers';
import type { ColumnsType } from 'antd/es/table';
import { IDataInternal } from '../../../interfaces/purchaseOrders/indexUpdate';

export const columns: ColumnsType<IDataInternal> = [
  {
    title: 'PO Number',
    dataIndex: 'po_number',
    key: 'po_Number',
    fixed: 'left',
    width: '140px',
    className: 'mr-8 text-left text-[13px] fontGTAmericanR mt-2 ',
    sorter: (a: any, b: any) => a.po_number - b.po_number,
  },
  {
    title: 'Date',
    dataIndex: 'date',
    key: 'date',
    width: '200px',
    className: 'fontGTAmericanR ',
    sorter: (a: any, b: any) =>
      new Date(dateFormatSorter(a?.date)).getTime() -
      new Date(dateFormatSorter(b?.date)).getTime(),
  },
  {
    title: 'Purchase ID',
    dataIndex: 'purchase_id',
    key: 'purchase_id',
    width: '200px',
    className: 'fontGTAmericanR ',
    sorter: (a: any, b: any) => a.purchase_id.localeCompare(b.purchase_id),
    /* render: (purchase: { status: string; }, tracking_number: any) => (
      <>
        {purchase && purchase?.status != 'Cancelled' ? (
          <SideSheet purchase={purchase} trackingInfo={tracking_number} />
        ) : (
          'Not Received.'
        )}
      </>
    ), */
  },
  {
    title: 'Box Count',
    dataIndex: 'box_count',
    key: 'boxCount',
    sorter: {},
    width: '120px',
  },
  {
    title: 'Total Value',
    dataIndex: 'amount',
    key: 'amount',
    width: '200px',
    className: 'fontGTAmericanR ',
    sorter: (a: any, b: any) => {
      return a.amount - b.amount;
    },
    render: (amount: any) => <>{usePriceFormat(amount)}</>,
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    ellipsis: true,
    width: '150px',
    className: 'fontGTAmericanR ',
    sorter: (a: any, b: any) => {
      return a.status.length - b.status.length;
    },
    render: (status) => (
      <>
        {status === 'Pending' && (
          <span className='text-black-600 fontGTAmericanR '>Pending</span>
        )}
        {status === 'Complete' && (
          <span className='text-green-600 fontGTAmericanR '>Complete</span>
        )}
        {status === 'Unpaid' && (
          <span className='text-orange-600 fontGTAmericanR '>Unpaid</span>
        )}
      </>
    ),
    //TODO: the data does not come as in the design (status)
    // render: (dataCollapse: [{}]) => {
    //   return (
    //     <>
    //       {dataCollapse.map((item: any, index: any) => {
    //         return (
    //           <div className='flex text-left justify-center' key={index}>
    //             <div className='w-4/12'>
    //               {item.status === 'Fully Paid' && (
    //                 <p className='text-green-600 mx-0 mt-0 mb-2'>Fully Paid</p>
    //               )}
    //               {item.status === 'Partially Paid' && (
    //                 <p className='text-orange-600 mx-0 mt-0 mb-2'>
    //                   Partially Paid
    //                 </p>
    //               )}
    //               {item.status === 'Unpaid' && (
    //                 <p className='text-gray-400 mx-0 mt-0 mb-2'>Unpaid</p>
    //               )}
    //             </div>
    //           </div>
    //         );
    //       })}
    //     </>
    //   );
    // },
  },
  {
    title: 'Actions',
    dataIndex: 'po_number',
    key: 'po-number2',
    width: '150px',
    className: 'fontGTAmericanR ',
    render: (test: any, purchase: any) => {
      return <RenderActionAndFetch data={purchase} />;
    },
  },
];

export const columnsDetails = [
  {
    title: 'Item',
    dataIndex: 'name',
    key: 'Item',
    width: '300px',
    className: 'fontGTAmericanR ',
    // render: (product: {
    //   name:
    //     | boolean
    //     | React.ReactChild
    //     | React.ReactFragment
    //     | React.ReactPortal
    //     | null
    //     | undefined;
    // }) => (
    //   <p className='mr-8 text-left text-[13px] fontGTAmericanR mt-2 truncate-text'>
    //     {product.name}
    //   </p>
    // ),
  },
  {
    title: 'SKU',
    dataIndex: 'sku',
    key: 'sku',
    className: 'fontGTAmericanR ',
    // render: (product: {
    //   internal_sku:
    //     | boolean
    //     | React.ReactChild
    //     | React.ReactFragment
    //     | React.ReactPortal
    //     | null
    //     | undefined;
    // }) => <p className='fontGTAmericanR'>{product.internal_sku}</p>,
  },
  {
    title: 'Qty',
    dataIndex: 'quantity',
    key: 'quantity',
    width: '70px',
    className: 'fontGTAmericanR  mt-0',
    render: (qty: any) => (
      <p className='fontGTAmericanR font-semibold'>{qty}</p>
    ),
  },
  {
    title: 'Price',
    dataIndex: 'price',
    key: 'price',
    className: 'fontGTAmericanR ',
    // render: (amount: any) => (
    //   <p className='fontGTAmericanR font-semibold'>{usePriceFormat(amount)}</p>
    // ),
  },
  {
    title: 'Amount',
    dataIndex: 'amount',
    key: 'amount',
    className: 'fontGTAmericanR ',
    // render: (amount: any) => (
    //   <p className='fontGTAmericanR font-semibold'>{usePriceFormat(amount)}</p>
    // ),
  },
];

export const dateFilter = [
  {
    name: 'datefilter',
    styles: 'mt-7',
  },
];

export const selectFilters = [
  {
    name: 'receivedStatus',
    title: 'Received Status',
    width: 145,
    menuItem: [
      {
        value: 'Partially Received',
        labelName: 'Partially Received',
      },
      {
        value: 'Received',
        labelName: 'Received',
      },
      {
        value: 'Over Received',
        labelName: 'Over Received',
      },
      {
        value: 'Unreceived',
        labelName: 'Unreceived',
      },
    ],
  },
  {
    name: 'totalValue',
    title: 'Total Value',
    isPrice: true,
    menuItem: [
      {
        value: '1-100',
        labelName: '$1 - $100',
      },
      {
        value: '100-200',
        labelName: '$100 - $200',
      },
      {
        value: '200-300',
        labelName: '$200 - $300',
      },
      {
        value: '300-400',
        labelName: '$300 - $400',
      },
      {
        value: '400-500',
        labelName: '$400 - $500',
      },
      {
        value: '500-600',
        labelName: '$500 - $600',
      },
      {
        value: '600-700',
        labelName: '$600 - $700',
      },
      {
        value: '700-800',
        labelName: '$700 - $800',
      },
      {
        value: '800-900',
        labelName: '$800 - $900',
      },
      {
        value: '900-1000',
        labelName: '$900 - $1000',
      },
      {
        value: '1000-1100',
        labelName: '$1000 - $1100',
      },
      {
        value: '1100-1200',
        labelName: '$1100 - $1200',
      },
      {
        value: '1200-1300',
        labelName: '$1200 - $1300',
      },
      {
        value: '1300-1400',
        labelName: '$1300 - $1400',
      },
      {
        value: '1400-1500',
        labelName: '$1400 - $1500',
      },
      {
        value: '1500-1600',
        labelName: '$1500 - $1600',
      },
      {
        value: '1600-1700',
        labelName: '$1600 - $1700',
      },
      {
        value: '1700-1800',
        labelName: '$1700 - $1800',
      },
      {
        value: '1800-1900',
        labelName: '$1800 - $1900',
      },
      {
        value: '1900-2000',
        labelName: '$1900 - $2000',
      },
    ],
  },
  // {
  //   name: 'productType',
  //   title: 'Product Type',
  //   menuItem: [
  //     {
  //       value: 'amazon',
  //       labelName: 'Amazon',
  //     },
  //     {
  //       value: 'bestBuy',
  //       labelName: 'Best Buy',
  //     },
  //     {
  //       value: '7tradeLLC',
  //       labelName: '7 Trade LLC',
  //     },
  //   ],
  // },
  {
    name: 'pendingStatus',
    title: 'Pending Status',
    width: 145,
    menuItem: [
      {
        value: 'Pending',
        labelName: 'Pending',
      },
      {
        value: 'Cancelled',
        labelName: 'Cancelled',
      },
      {
        value: 'Complete',
        labelName: 'Complete',
      },
      {
        value: 'Overpaid',
        labelName: 'Overpaid',
      },
      {
        value: 'Refunded',
        labelName: 'Refunded',
      },
      {
        value: 'Underpaid',
        labelName: 'Underpaid',
      },
    ],
  },
];

export const searchFilter = [
  {
    name: 'pOItemNameModel',
    placeHolder: 'Search PO Number, Item Name',
    stylesSecondContainer: 'mr-2',
    width: 250,
  },
];

export const stylesFilterSectionSearchAndDate =
  'flex justify-end w-full mt-5 mr-12';

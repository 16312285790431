import { useQuery } from 'react-query';
import { AuthContext } from '../../../context/useSaveDataSession';
import { IGetAchAccountsAuthorizedActions } from '../../../interfaces/myAccount';
import fetchGetAchAccountsAuthorizedActions from '../../endPoints/myAccount/getAchAccountsAuthorizedActions';
import achAccountsAuthorizedActionsTransform from '../../transforms/myAccount/getAchAccountsAuthorizedActions';
import { useContext } from 'react';

const useGetAchAccountsAuthorizedActions =
  (): IGetAchAccountsAuthorizedActions => {
    const { logout } = useContext<any>(AuthContext);

    const { error, data, isLoading } = useQuery(
      'GET_ACH_ACCOUNTS_AUTHORIZED_ACTIONS',
      fetchGetAchAccountsAuthorizedActions,
      {
        onSuccess: (data) => {
          if (data.message === 'Token has expired') {
            logout();
          } else if (data.status === 401) {
            logout();
          }
        },
      },
    );

    if (data) {
      return {
        error,
        response: achAccountsAuthorizedActionsTransform(data),
        isLoading,
      };
    } else {
      return { error, response: data, isLoading };
    }
  };

export default useGetAchAccountsAuthorizedActions;

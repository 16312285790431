import React, { useContext } from 'react';
import * as AiIcons from 'react-icons/ai';
import * as FiIcons from 'react-icons/fi';
import * as MdIcons from 'react-icons/md';
import { Link } from 'react-router-dom';
import { AuthContext } from '../../../../../context/useSaveDataSession';
import useStatesGlobalStore from '../../../../../hooks/useStatesGlobalStore';
import BottomNavigation from '@mui/material/BottomNavigation';
import { Paper } from '@mui/material';
import AlertSliceMobile from '../../../../alertSliceMobile';
import cookie from 'react-cookies';

const Sidebar = () => {
  const [active, setActive] = React.useState(false);
  const { logout } = useContext(AuthContext);
  const { buyerInfo } = useStatesGlobalStore();
  const { firstName } = buyerInfo;

  const BottomNavigationBar = [
    {
      name: 'Dashboard',
      path: '/dashboard',
      icon: (
        <img
          src='./images/sideBar/house.svg'
          alt='icon'
          width='18'
          height='19'
        />
      ),
    },
    {
      title: 'Active Deals',
      newPath: '/deals',
      icon: (
        <img
          src='./images/sideBar/ticket.svg'
          alt='icon'
          width='19'
          height='19'
        />
      ),
    },
    {
      title: 'Trackings',
      newPath: '/tracking-numbers',
      icon: (
        <img
          src='./images/sideBar/tracking.svg'
          alt='icon'
          width='16'
          height='18'
        />
      ),
    },
    {
      name: 'Purchase Orders',
      path: '/purchase-orders',
      icon: (
        <img
          src='./images/sideBar/purchaseorder.svg'
          alt='icon'
          width='19'
          height='19'
        />
      ),
    },
    {
      name: 'Payment Request',
      path: '/payment-request',
      icon: (
        <img
          src='./images/sideBar/payment.svg'
          alt='icon'
          width='19'
          height='13'
        />
      ),
    },
    {
      name: 'Account',
      path: '/my-account',
      icon: (
        <img
          src='./images/sideBar/account.png'
          alt='icon'
          width='23'
          height='18'
        />
      ),
    },
    {
      name: 'My Deals Cart',
      path: '/my-deals-cart',
      icon: (
        <img
          src='./images/sideBar/blueCar.svg'
          alt='icon'
          width='23'
          height='18'
        />
      ),
    },
    {
      name: 'Commitments',
      path: '/my-commitments',
      icon: (
        <img
          src='./images/sideBar/commit.svg'
          alt='icon'
          width='18'
          height='18'
        />
      ),
    },
    {
      name: 'Help Center',
      path: '/help-center',
      icon: (
        <img
          src='./images/sideBar/helpCenter.png'
          alt='icon'
          width='18'
          height='18'
        />
      ),
    },
    {
      name: 'Log out',
      path: '/?logout=true',
      function: () => {
        logout();
      },
      icon: (
        <img
          src='./images/sideBar/LogOut.svg'
          alt='icon'
          width='18'
          height='18'
        />
      ),
    },
  ];

  const handleClose = () => {
    setActive(false);
  };

  const SidebarData = [
    {
      title: 'Dashboard',
      path: '/dashboard',
      icon: (
        <img
          src='./images/sideBar/house.svg'
          alt='icon'
          width='16'
          height='18'
        />
      ),
      line: true,
    },
    {
      title: 'Active Deals',
      newPath: '/deals',
      icon: (
        <img
          src='./images/sideBar/ticket.svg'
          alt='icon'
          width='16'
          height='18'
        />
      ),
    },
    {
      title: 'My Deals Cart',
      path: '/my-deals-cart',
      icon: (
        <img
          src='./images/sideBar/blueCar.svg'
          alt='icon'
          width='16'
          height='18'
        />
      ),
    },
    {
      title: 'My Commitments',
      path: '/my-commitments',
      icon: (
        <img
          src='./images/sideBar/commit.svg'
          alt='icon'
          width='16'
          height='18'
        />
      ),
      line: true,
    },
    {
      title: 'Trackings',
      newPath: '/tracking-numbers',
      icon: (
        <img
          src='./images/sideBar/tracking.svg'
          alt='icon'
          width='16'
          height='18'
        />
      ),
    },
    {
      title: 'Purchase Orders',
      path: '/purchase-orders',
      icon: (
        <img
          src='./images/sideBar/purchaseorder.svg'
          alt='icon'
          width='16'
          height='18'
        />
      ),
    },
    {
      title: 'Payment Requests',
      path: '/payment-request',
      icon: (
        <img
          src='./images/sideBar/payment.svg'
          alt='icon'
          width='16'
          height='18'
        />
      ),
    },
    {
      title: 'Help Center',
      path: '/help-center',
      icon: <MdIcons.MdOutlineMessage size={25} />,
      line: true,
    },
  ];

  const sidebarOptions = [
    {
      title: 'My Account',
      path: '/my-account',
      icon: <AiIcons.AiOutlineSetting size={25} />,
    },
    {
      title: 'Logout',
      testID: 'logoutBtnTestId',
      path: '/?logout=true',
      function: () => {
        logout();
      },
      icon: <FiIcons.FiLogOut size={25} />,
    },
  ];

  return (
    <>
      <AlertSliceMobile />
      <Paper
        className='flex md:justify-center  xl1:hidden'
        elevation={3}
        sx={{
          marginTop: '1rem',
          position: 'fixed',
          bottom: 0,
          flexWrap: 'wrap',
          overflowX: 'scroll',
          overflowY: 'hidden',
          width: '100vw',
          paddingBottom: '1.5rem',
          height: '69px',
        }}>
        <BottomNavigation showLabels>
          {BottomNavigationBar.map((item, index) => {
            return (
              <>
                {item.path ?
                  <Link
                    className='flex flex-col ml-2 pt-[0.5rem] mt-1 rounded-t-xl items-center hover:bg-[#BEDFFF] w-[4.5rem] h-[69px] '
                    to={item.path}
                    key={index}
                    onClick={item.function}>
                    <picture className='flex'>{item.icon}</picture>
                    <p className='flex items-center text-center justify-center text-[#000000] opacity-40 text-[0.6rem] mt-1 w-[0.5]'>
                      {item.name}
                    </p>
                  </Link>
                  :
                  <a
                    href={`${process.env.REACT_APP_URL_NEW_BUYERS}${item.newPath}?token=${cookie.load('token')}`}
                    onClick={handleClose}
                    className='flex flex-col ml-2 pt-[0.5rem] mt-1 rounded-t-xl items-center hover:bg-[#BEDFFF] w-[4.5rem] h-[69px]'>
                    {item.icon}
                    <span className='flex items-center text-center justify-center text-[#000000] opacity-40 text-[0.6rem] mt-1 w-[0.5]'>
                      {item.title}
                    </span>
                  </a>}
              </>

            );
          })}
        </BottomNavigation>
      </Paper>
      <nav
        data-testid='sidebarSection'
        className={
          active
            ? 'flex bg-white xl3:w-[258px] w-[224px] justify-center left-0 top-0 bottom-0 shadow-2xl bg-blend-overlay z-30 h-screen overflow-y-auto'
            : 'hidden bg-white xl3:w-[258px] justify-center w-[224px] top-0 shadow-2xl lg:flex h-screen overflow-y-auto overflow-x-hidden'
        }>
        <ul className='w-full py-[1.2rem] px-[1rem] fontQuireSansLight'>
          <li>
            <a className='cursor-pointer' href='/dashboard'>
              <img
                className='flex w-40 mx-auto mt-[0.8rem] mb-[1.7rem]'
                src='/images/logo-sidebar.png'
                alt='usa buying group home logo'
                width={262}
                height={103}
              />
            </a>
          </li>
          {SidebarData.map((item, index) => {
            return (
              <React.Fragment key={index}>
                {item.line && (
                  <li>
                    <hr className=' my-2 ' />
                  </li>
                )}
                <li className='flex justify-center items-center pl-5 h-[2.5rem] rounded-xl hover:bg-blue-200'>
                  {item.path? <Link
                    to={item.path}
                    onClick={handleClose}
                    className='flex text-blue-500 hover:text-blue-500 w-full h-full gap-3 items-center rounded-xl p-4'>
                    {item.icon}
                    <span className='text-black text-[11px] xl3:text-[12.5px] opacity-60 ml-1 fontGTAmericanR'>
                      {item.title}
                    </span>
                  </Link>
                  :
                  <a
                    href={`${process.env.REACT_APP_URL_NEW_BUYERS}${item.newPath}?token=${cookie.load('token')}`}
                    onClick={handleClose}
                    className='flex text-blue-500 hover:text-blue-500 w-full h-full items-center rounded-xl pl-4'>
                    {item.icon}
                    <span className='text-black text-[11px] xl3:text-[12.5px] opacity-60 fontGTAmericanR ml-4 mr-1'>
                      {item.title}
                    </span>
                  </a>}
                </li>
              </React.Fragment>
            );
          })}
          <li>
            <Link to='/quick-start-guide'>
              <img
                className='flex w-48 mx-auto mt-5 mb-8'
                src='/images/dashboard/imageSidberSvg.svg'
                alt='tutorialLinkImg'
                width={227}
                height={235}
              />
            </Link>
          </li>
          <li className='flex justify-start items-center mx-4 pl-5 pb-2'>
            <MdIcons.MdAccountCircle size={30} />
            <span className='mx-[0.5rem] opacity-50 fontGTAmericanR'>
              {firstName}
            </span>
          </li>
          {sidebarOptions.map((option, index) => {
            return (
              <React.Fragment key={index}>
                <li className='flex justify-center items-center w-36 mx-10 pt-2  pb-2 h-10 rounded-xl hover:bg-blue-200'>
                  <Link
                    data-testid={option.testID}
                    to={option.path}
                    onClick={option.function}
                    className='flex text-black opacity-50 items-center rounded-xl gap-3'>
                    {option.icon}
                    <span className='text-black text-xs underline w-20 fontGTAmericanR'>
                      {option.title}
                    </span>
                  </Link>
                </li>
              </React.Fragment>
            );
          })}
          <div className='flex justify-center items-center flex-col mt-3.5'>
            <img src='/images/sideBar/follow-us.png' width={65} />
            <div className='flex flex-wrap gap-3 mt-1 mb-3'>
              <a href='https://discord.gg/usabuyinggroup' target="_blank">
                <img src='/images/sideBar/discord-logo.png' width={25} />
              </a>
              <a target="_blank" href='https://www.instagram.com/usabuyinggroup/'>
                <img src='/images/sideBar/instagram-logo2.png' style={{ height: '20px', width: '20px', paddingTop: '1.5px' }} />
              </a>
              <a target="_blank" href='https://x.com/USABuyingGroup'>
                <img src='/images/sideBar/x-logo2.png' style={{ height: '18px', width: '18px', paddingTop: '2px' }} />
              </a>
            </div>
          </div>
        </ul>
      </nav>
    </>
  );
};

export default Sidebar;

import { themeCustom } from '../../../theme';
import { MdCircleNotifications } from 'react-icons/md';

const { colors } = themeCustom;

export const Select = () => {
  return (
    <select name='filterPaymentsDate' aria-label='your payments select filter'>
      <option value='Today'>Today</option>
      <option value='ThisWeek'>This week</option>
    </select>
  );
};

export const dataCard = [
  {
    color: colors.blue,
    // componentEdit: <Select />,
    icon: (
      <img
        src='./images/dashboard/arrow.png'
        alt='icon'
        width='7'
        height='13'
      />
    ),
    isCurrency: true,
    title: 'Your payments',
    field: 'total_spent_company',
    path: '/payment-request',
  },
  {
    color: colors.lightBlue,
    componentEdit: false,
    icon: (
      <img
        src='./images/dashboard/arrow.png'
        alt='icon'
        width='7'
        height='13'
      />
    ),
    isCurrency: true,
    title: 'Pending payments',
    field: 'total_pending_payment',
    path: '/payment-request',
  },
  {
    color: colors.lightOrange,
    componentEdit: false,
    icon: (
      <img
        src='./images/dashboard/arrow.png'
        alt='icon'
        width='7'
        height='13'
      />
    ),
    isCurrency: false,
    title: 'Pending tracking numbers',
    field: 'pending_trackings',
    path: '/tracking-numbers',
  },
  {
    color: 'rgb(255, 156, 129)',
    componentEdit: false,
    icon: (
      <img
        src='./images/dashboard/arrow.png'
        alt='icon'
        width='7'
        height='13'
      />
    ),
    // isCurrency: true,
    title: 'In my deals cart',
    field: '',
    isTotalPriceCart: true,
  },
];

export const updatesCardContent = [
  {
    text: (
      <div className='flex flex-col'>
        <div className='flex'>
          <MdCircleNotifications className='text-red-600 text-2xl' />
          <span className='mx-2'>BREAKING NEWS</span>

          <MdCircleNotifications className='text-red-600 text-2xl' />
        </div>
        <span className='my-3'>DE Address Change</span>
        <span className='mb-3'>Important Update</span>
        <p>
          We have opened a new DE warehouse. Please start shipping packages to
          our new address below.
        </p>
        <div>
          <p className='my-0'>(Buyer ID)</p>
          <p className='my-0'>1400 Vandever Ave</p>
          <p>Wilmington, DE 19802</p>
        </div>

        <span>
          We are no longer picking up from the old address we don't have access
          to that address
        </span>
      </div>
    ),
  },
  {
    text: (
      <span>
        We refreshed our space and new features will be supported shortly. If
        you need to use the old portal you can use{' '}
        <a
          href='https://old.app.usabuying.group/'
          rel='noreferrer'
          target='_blank'
          className='text-blue-400 hover:text-blue-500'>
          old.app.usabuying.group.
        </a>
        . Thanks.
      </span>
    ),
  },
];

export const dataWideCard = [
  {
    color: colors.lightBlue,
    icon: (
      <img
        src='./images/dashboard/mapIcon.png'
        alt='icon'
        width='32'
        height='34'
      />
    ),
    title: 'Add tracking numbers',
    path: '/tracking-numbers',
  },
  {
    color: colors.lightBlue,
    icon: (
      <img
        src='./images/dashboard/paymentIcon.png'
        alt='icon'
        width='36'
        height='24'
      />
    ),
    title: 'Request payment',
    path: '/payment-request',
  },
  {
    color: colors.lightBlue,
    icon: (
      <img
        src='./images/dashboard/purchaseIcon.png'
        alt='icon'
        width='32'
        height='32'
      />
    ),
    title: 'See your purchase orders',
    path: '/purchase-orders',
  },
  {
    color: colors.lightBlue,
    icon: (
      <img
        src='./images/dashboard/helpTicketIcon.png'
        alt='icon'
        width='36'
        height='30'
      />
    ),
    title: 'Open a new help ticket',
    path: '/open-help-ticket',
  },
];

export const notifications = [
  {
    title: 'You Committed to 3 items on July 29, 2021.',
    status: true,
  },
  {
    title:
      'You were paid $1022.45 via ACH Account "Moshe Visa" on July 30, 2021.',
    status: true,
  },
  {
    title: 'You Committed to 3 items on July 29, 2021.',
    status: false,
  },
];

export const simpleSelectFilter = [
  {
    name: 'filterSets',
    selectWidth: 220,
    stylesFirstContainer: 'grid justify-items-stretch w-12/12 mt-2',
    stylesSecondContainer: 'flex items-center mb-8 lg:mb-0 justify-self-end',
    title: 'Sort By',
    menuItems: [
      {
        value: '',
        labelName: 'New in',
      },
      {
        value: 'alphabeticalA-Z',
        labelName: 'Alphabetical, A-Z',
      },
      {
        value: 'alphabeticalZ-A',
        labelName: 'Alphabetical, Z-A',
      },
      {
        value: 'retailType,lowToHigh',
        labelName: 'Retail Type, low to high',
      },
      {
        value: 'retailType,highToLow',
        labelName: 'Retail Type, high to low',
      },
    ],
  },
];

import React from 'react';
import usePriceFormat from '../../../hooks/usePriceFormat';
import { dateFormatSorter } from '../../../helpers';
import RenderActions from '../containers/RenderActions';

export const dataTableFields: any = [
  {
    title: 'Request Date',
    dataIndex: 'added_date',
    key: 'addedDate',
    fixed: 'left',
    width: '200px',
    className: 'fontGTAmericanR',
    sorter: (a: any, b: any) =>
      new Date(dateFormatSorter(a?.addedDate)).getTime() -
      new Date(dateFormatSorter(b?.addedDate)).getTime(),
  },
  {
    title: 'Payment Type',
    dataIndex: 'type',
    key: 'paymentType',
    width: '150px',
    className: 'fontGTAmericanR',
    sorter: (a: any, b: any) => {
      return a.paymentType.length - b.paymentType.length;
    },
    render: (type: any, item: any) => <span>{item?.type?.name}</span>,
  },
  {
    title: 'Details',
    dataIndex: 'details',
    key: 'details',
    width: '200px',
    className: 'fontGTAmericanR',
    sorter: (a: any, b: any) => {
      return a.details.length - b.details.length;
    },
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    width: '150px',
    className: 'fontGTAmericanR',
    render: (status: any, item: any) => {
      return <span>{item?.status?.name}</span>;
    },
  },
  {
    title: 'Check Number',
    dataIndex: 'check',
    key: 'checkNumber',
    width: '150px',
    className: 'fontGTAmericanR',
    sorter: (a: any, b: any) => {
      return a?.checkNumber - b?.checkNumber;
    },
    render: (checkNumber: any, item: any) => {
      return (
        <span>
          {checkNumber?.check_number
            ? checkNumber?.check_number
            : item?.status?.name}
        </span>
      );
    },
  },
  {
    title: 'Total Value',
    dataIndex: 'value',
    key: 'value',
    width: '150px',
    className: 'fontGTAmericanR',
    sorter: (a: any, b: any) => {
      return a?.value - b?.value;
    },
    render: (value: any) => {
      return <>{usePriceFormat(value)}</>;
    },
  },
  {
    title: 'Actions',
    dataIndex: 'action',
    key: 'action',
    width: '200px',
    className: 'fontGTAmericanR ',
    render: (action: any, item: any) => {
      return <RenderActions item={item} />;
    },
  },
];

export const data = [
  {
    requestDate: '10/19/2021 4:49 PM',
    paymentType: 'ACH',
    details: 'MOSHE TD',
    checkNumber: '#2500EFDF043',
    totalValue: '$500.22',
    status: 'Processed',
  },
  {
    requestDate: '10/19/2021 4:49 PM',
    paymentType: 'ACH',
    details: 'MOSHE TD',
    checkNumber: '#2500EFDF043',
    totalValue: '$500.22',
    status: 'Processed',
  },

  {
    requestDate: '10/19/2021 4:49 PM',
    paymentType: 'ACH',
    details: 'MOSHE TD',
    checkNumber: '#2500EFDF043',
    totalValue: '$500.22',
    status: 'Processed',
  },
  {
    requestDate: '10/19/2021 4:49 PM',
    paymentType: 'ACH',
    details: 'MOSHE TD',
    checkNumber: '#2500EFDF043',
    totalValue: '$500.22',
    status: 'Processed',
  },
];

export const dateFilter = [
  {
    name: 'datefilter',
  },
];

export const searchFilter = [
  {
    name: 'checkNumberOrName',
    placeHolder: 'Search Name',
    stylesFirstContainer: '',
    stylesSecondContainer: 'mr-5 ',
    width: 300,
  },
];

export const selectFilters = [
  {
    name: 'paymentType',
    title: 'Payment Type',
    width: 155,
    menuItem: [
      {
        value: 'ACH',
        labelName: 'ACH',
      },
      {
        value: 'Check',
        labelName: 'Check',
      },
    ],
  },
  {
    name: 'value',
    title: 'Value',
    menuItem: [
      {
        value: '1-100',
        labelName: '$1 - $100',
      },
      {
        value: '100-200',
        labelName: '$100 - $200',
      },
      {
        value: '200-300',
        labelName: '$200 - $300',
      },
      {
        value: '300-400',
        labelName: '$300 - $400',
      },
      {
        value: '400-500',
        labelName: '$400 - $500',
      },
      {
        value: '500-600',
        labelName: '$500 - $600',
      },
      {
        value: '600-700',
        labelName: '$600 - $700',
      },
      {
        value: '700-800',
        labelName: '$700 - $800',
      },
      {
        value: '800-900',
        labelName: '$800 - $900',
      },
      {
        value: '900-1000',
        labelName: '$900 - $1000',
      },
      {
        value: '1000-1100',
        labelName: '$1000 - $1100',
      },
      {
        value: '1100-1200',
        labelName: '$1100 - $1200',
      },
      {
        value: '1200-1300',
        labelName: '$1200 - $1300',
      },
      {
        value: '1300-1400',
        labelName: '$1300 - $1400',
      },
      {
        value: '1400-1500',
        labelName: '$1400 - $1500',
      },
      {
        value: '1500-1600',
        labelName: '$1500 - $1600',
      },
      {
        value: '1600-1700',
        labelName: '$1600 - $1700',
      },
      {
        value: '1700-1800',
        labelName: '$1700 - $1800',
      },
      {
        value: '1800-1900',
        labelName: '$1800 - $1900',
      },
      {
        value: '1900-2000',
        labelName: '$1900 - $2000',
      },
    ],
  },
];

export const stylesFilterSectionSearchAndDate =
  'flex justify-end w-full mt-5 mr-12';

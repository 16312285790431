/* eslint-disable no-undef */
import React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import Main from './components/UI/layout/Main/Main';
import RoutesComponent from './routes/RoutesComponent';
import 'antd/dist/antd.css';
import './App.css';
import UseSaveDataSession from './context/useSaveDataSession';
import { CartProvider } from 'react-use-cart';
import CacheBuster from 'react-cache-buster';
import packageApp from '../package.json';
import { Buffer } from 'buffer';
// import { useNavigate } from 'react-router-dom';
import useStatesGlobalStore from './hooks/useStatesGlobalStore';
// import { AuthContext } from './context/useSaveDataSession';
// import { useContext } from 'react';
import cookie from 'react-cookies';
// import { Redirect } from 'react-router-dom';

const queryClient = new QueryClient();

function App() {
  // eslint-disable-next-line no-undef
  const isProduction = process.env.REACT_APP_CACHE === 'production';

  const {
    buyerInfo,
    setBuyerInfo,
    setDashboardInfo,
    dashboardInfo,
    setAuthInfo,
  } = useStatesGlobalStore();

  const urlParams = new URLSearchParams(window.location.search);
  const token = urlParams.get('token');
  const enroll = urlParams.get('enroll');
  const redirect = urlParams.get('redirect');

  const login = () => {
    const dateExpired = new Date(new Date().getTime() + 1000 * 60 * 1440);

    setAuthInfo({
      expiration: dateExpired?.toISOString(),
      state: 'sessionAproved',
    });
  };

  React.useEffect(() => {
    // eslint-disable-next-line no-undef
    if (token) {
      let tokenEncoded = Buffer.from(token, 'base64');
      let tokenDecodedString = tokenEncoded.toString('ascii');
      const data = JSON.parse(tokenDecodedString);

      setBuyerInfo({
        ...buyerInfo,
        buyerInternalId: data.data.buyer.buyer_internal_id,
        firstName: data.data.buyer.first_name,
        lastName: data.data.buyer.last_name,
        emailAddress: data.data.buyer.email,
        mobilePhone: data.data.buyer.phone_number,
        mobilePhoneTwo: data.data.buyer.phone_number_2,
        mobilePhoneThree: data.data.buyer.phone_number_3,
        buyerID: data.data.buyer.buyer_id,
        additionalIds: data.data.buyer.buyer_ids,
        checksRequestsAccess: data.data.buyer.checks_requests_access,
        betaPortalAccess: data.data.buyer.beta_portal_access,
        signup_date: data.data.buyer.signup_date
      });

      setDashboardInfo({ ...dashboardInfo, info: data.data.dashboard });

      login();

      cookie.save('token', data.data.token);
      cookie.save('enroll', enroll);
      
      const newPortal = process.env.REACT_APP_NEW_PORTAL || ""

      if (redirect?.includes(newPortal)) {
        const url = redirect.includes("?") ? `${redirect}&token=${data.data.token}` : `${redirect}?token=${data.data.token}`
        window.location.href = url;
        return;
    } else if (redirect && !redirect.includes(newPortal)) {
        window.location.href = redirect;
        return;
    }
    
      const protocol = window.location.protocol;
      const domain = window.location.host;
      window.location.href = `${protocol}//${domain}/dashboard`;
    }

    // setTimeout(() => {
    //   if (!token && process.env.REACT_APP_TESTING_DEV !== 'development') {
    //     window.location.href = 'https://usabuying.group/';
    //   }
    // }, 2000);
  }, []);

  return (
    <CacheBuster
      currentVersion={packageApp.version}
      isEnabled={isProduction} //If false, the library is disabled.
      isVerboseMode //If true, the library writes verbose logs to console.
      // metaFileDirectory={'.'} //If public assets are hosted somewhere other than root on your server.
    >
      <QueryClientProvider client={queryClient}>
        <CartProvider>
          <UseSaveDataSession>
            <Main>
              <RoutesComponent />
            </Main>
          </UseSaveDataSession>
        </CartProvider>
      </QueryClientProvider>
    </CacheBuster>
  );
}

export default App;

/* eslint-disable no-undef */
import cookie from 'react-cookies';

const fetchDetails = async (params: any) => {
  const res = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}buyers/pos/` +
      new URLSearchParams(params),
    {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${cookie.load('token')}`,
        'Content-Type': 'application/json',
      },
      credentials: 'same-origin',
    },
  ).then((res) => res.json());
  return res;
};

export default fetchDetails;

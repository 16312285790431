import React from 'react';
import { Divider, Popover } from '@mui/material';
import { updatePayeeNameForm } from '../constants';
import FormTemplate from '../components/FormTemplate';

const UpdatePayeeNameForm = ({
  payeeNameInfo,
  anchorEl,
  handleClose,
  open,
}: any) => {
  return (
    <Popover
      data-testid='UpdatePayeeNameFormDialog'
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}>
      <div
        className='bg-slate-100 rounded-3xl p-5 w-[40rem] overflow-auto h-[17rem]	'
        data-testid='adhAccountOpenForm'>
        <div className='flex justify-between'>
          <h3 className='ml-5 text-blue-500 text-xl font-semibold'>
            Update Payee Name
          </h3>
        </div>
        <Divider sx={{ bgcolor: '#1b8cfc', height: 2, borderRadius: 2 }} />
        <FormTemplate
          extraFormContainerStyles1={'w-full'}
          extraFormContainerStyles2={''}
          extraFormContainerStyles3={''}
          formFields={updatePayeeNameForm}
          formName={'updatePayeeNameForm'}
          handleCloseExtraForm={handleClose}
          styles={'lg:flex flex-wrap mt-10 lg:justify-center'}
          submitConfig={[
            {
              dataTestID: 'saveChangesUpdatePayeeName',
              ml: 2,
              labelName: 'Submit',
              bgColor: 'rgb(241 245 249)',
            },
          ]}
          updateInfo={payeeNameInfo}
        />
      </div>
    </Popover>
  );
};

export default UpdatePayeeNameForm;

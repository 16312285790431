import {
  StepConnector,
  stepConnectorClasses,
  StepIconProps,
  styled,
} from '@mui/material';

export const breadCrumbsQuickGuide = [
  {
    name: 'Help Center',
    route: '/help-center',
  },
  {
    name: `Quick Start Guide`,
    route: '/quick-start-guide',
  },
];

export const ColorlibConnector = styled(StepConnector)(() => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 9,
    left: -17,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: '#178DFF',
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: '#178DFF',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    width: 34,
    backgroundColor: '#0000002E',
    borderRadius: 1,
  },
}));

export const ColorlibStepIconRoot = styled('div')<{
  ownerState: { completed?: boolean; active?: boolean };
}>(({ ownerState }) => ({
  backgroundColor: '#CBCBCB',
  zIndex: 1,
  color: '#fff',
  width: 22,
  height: 19,
  display: 'flex',
  borderRadius: '50%',
  justifyContent: 'center',
  alignItems: 'center',
  ...(ownerState.active && {
    backgroundColor: '#178DFF',
  }),
  ...(ownerState.completed && {
    backgroundColor: '#178DFF',
  }),
}));

export function ColorlibStepIcon(props: StepIconProps) {
  const { active, completed, className } = props;
  return (
    <ColorlibStepIconRoot
      ownerState={{ completed, active }}
      className={className}></ColorlibStepIconRoot>
  );
}

export const guideTitles = [
  {
    name: 'Dashboard',
    title: 'View your status',
    paragraph:
      'See everything at a glance: what you spent, cash you can claim, and commitments you need to act on.',
    title2: 'Control everything',
    paragraph2:
      "We've rearranged the dashboard for max clarity. Your important tools are within reach! (Plus a few ones :)",
    title3: 'Shop from the dash',
    paragraph3:
      'The Top Deals section lets you commit to deals without even having to leave your dasboard.',
    picture: (
      <img
        className='rounded-2xl w-[55rem] h-[27.01rem]'
        src='/images/quickGuide/dashboard/dashboard status.png'
        alt='dashboard status'
      />
    ),
    picture2: (
      <img
        className='rounded-2xl w-[55rem] h-[27rem]'
        src='/images/quickGuide/dashboard/dashboard links.png'
        alt='dashboar links'
      />
    ),
    picture3: (
      <img
        src='/images/quickGuide/dashboard/top deals.png'
        alt='top deals'
        className='rounded-2xl w-[55rem] h-[27rem]'
      />
    ),
  },
  {
    name: 'Active Deals',
    title: 'New! Filter and sort',
    paragraph:
      "Smart filter and sort-anyywhere tabs give you more control and help you find what you're looking for.",
    title2: 'Add deals to a cart',
    paragraph2:
      'Dream now, figure it all out by adding items to our brand-new My Deals Cart.',
    title3: 'Quick commit',
    paragraph3:
      'Commit in seconds with a new Quick Commit option, right from the Active Deals page.',
    picture: (
      <img
        className='rounded-2xl w-[55rem] h-[27rem]'
        src='/images/quickGuide/active deals/active deals filters.png'
        alt='active deals filters'
      />
    ),
    picture2: (
      <img
        className='rounded-2xl w-[55rem] h-[27rem]'
        src='/images/quickGuide/active deals/active deals cart.png'
        alt='active deals cart'
      />
    ),
    picture3: (
      <img
        className='rounded-2xl w-[55rem] h-[27rem]'
        src='/images/quickGuide/active deals/active deals quick commit.png'
        alt='active deals quick commit'
      />
    ),
  },
  {
    name: 'My Deals Cart',
    title: 'Add to Deals Cart',
    paragraph:
      'Add to the Deals Cart from the Active Deals page and sort it all out later.',
    title2: 'Control color and quantity',
    paragraph2:
      "Set the number of items you'd like to buy and choose colorways in the Deals Cart",
    title3: 'Commit to your cart',
    paragraph3:
      "Bull-commit to all the items in your Deal Cart at once. It's a new way to commit!",
    picture: (
      <img
        className='rounded-2xl w-[55rem]'
        src='/images/quickGuide/active deals/active deals cart.png'
        alt='active deals cart'
      />
    ),
    picture2: (
      <img
        className='rounded-2xl w-[55rem] h-[27rem]'
        src='/images/quickGuide/my deals cart/my cart options.png'
        alt='my cart options'
      />
    ),
    picture3: (
      <img
        className='rounded-2xl w-[55rem] h-[27rem]'
        src='/images/quickGuide/my deals cart/my cart commit.png'
        alt='my cart options'
      />
    ),
  },
  {
    name: 'Individual Deals',
    title: 'New intuitive walk through',
    paragraph:
      "Our redesign individual Deal page walks you through the commitment process, so even if you're new to this, all the information is right at your fingertips.",
    picture: (
      <img
        className='rounded-2xl w-[55rem] h-[27rem]'
        src='/images/quickGuide/individual.png'
        alt='deal'
      />
    ),
  },
  {
    name: 'My Commitments',
    title: 'View commitment status',
    paragraph:
      "See the status of every item you've ever committed to. Sort and filter to find what you need!",
    title2: 'Buy via item links',
    paragraph2:
      "If you didn't buy your products yet, the My Commitment page includes each item's store links.",
    picture: (
      <img
        className='rounded-2xl w-[55rem] h-[27rem]'
        src='/images/quickGuide/my commitments/my commitments status.png'
        alt='my commitments status'
      />
    ),
    picture2: (
      <img
        className='rounded-2xl w-[55rem] h-[27rem]'
        src='/images/quickGuide/my commitments/my commitments links.png'
        alt='my commitments links'
      />
    ),
  },
  {
    name: 'Tracking Numbers',
    title: 'Add tracking numbers',
    paragraph:
      'Batch-Add store-generated tracking numbers quickly and easily. ',
    title2: 'Generate labels',
    paragraph2:
      'Quickly generate labels for drop-off so we can keep track of your boxes.',
    title3: 'Filter, sort and view',
    paragraph3:
      'View box details for an in-depth look at each tracking number. Export partial or complete data.',
    picture: (
      <img
        className='rounded-2xl w-[55rem] h-[27rem]'
        src='/images/quickGuide/tracking number/tracking add.png'
        alt='tracking add'
      />
    ),
    picture2: (
      <img
        className='rounded-2xl w-[55rem] h-[27.04rem]'
        src='/images/quickGuide/tracking number/tracking general.png'
        alt='tracking general'
      />
    ),
    picture3: (
      <img
        className='rounded-2xl w-[55rem] h-[27rem]'
        src='/images/quickGuide/tracking number/tracking filters.png'
        alt='tracking filters'
      />
    ),
  },
  {
    name: 'Purchase Orders',
    title: 'Filter + sort POs for easy searching',
    paragraph:
      "Search by Payment Status, Total Value and more so you'll always find what you're looking for",
    title2: "Export however you'd like",
    paragraph2:
      'Export in multiple formats so you can integrate your data on the tracking platform of your choice.',
    title3: 'View PO details',
    paragraph3:
      'View detailed informationa about every box included in your desire Purchase Order.',
    picture: (
      <img
        className='rounded-2xl w-[55rem] h-[27rem]'
        src='/images/quickGuide/purchase orders/purchase orders filters.png'
        alt='purchase orders filters'
      />
    ),
    picture2: (
      <img
        className='rounded-2xl w-[55rem] h-[27.04rem] '
        src='/images/quickGuide/purchase orders/purchase exports.png'
        alt='purchase exports'
      />
    ),
    picture3: (
      <img
        className='rounded-2xl w-[55rem] h-[27rem]'
        src='/images/quickGuide/purchase orders/details purchase.png'
        alt='details purchase'
      />
    ),
  },
  {
    name: 'Payment Request',
    title: 'See where your money is',
    paragraph: 'View your current balance and request a payment',
    title2: 'Filter everything',
    paragraph2:
      'Find the data you need in an instant with intuitive filters, calendar search and more.',
    picture: (
      <img
        className='rounded-2xl w-[55rem] h-[27rem]'
        src='/images/quickGuide/payments/payment.png'
        alt='payments'
      />
    ),
    picture2: (
      <img
        src='/images/quickGuide/payments/payment filters.png'
        alt='payment'
        className='rounded-2xl w-[55rem] h-[27rem]'
      />
    ),
  },
  {
    name: 'My Account',
    title: 'View & edit your information',
    paragraph:
      'Change address and payment, locate your USA Buying ID, and change email preferences.',
    title2: 'Add payment methods',
    paragraph2:
      'Add payee accounts for checks or ACH accounts for automatic deposits.',
    title3: 'API keys',
    paragraph3: 'Acces API information for high-tech accessibility.',
    picture: (
      <img
        className='rounded-2xl w-[55rem] h-[27rem]'
        src='/images/quickGuide/my account/my account my info.png'
        alt='my account info'
      />
    ),
    picture2: (
      <img
        className='rounded-2xl w-[55rem] h-[27rem] '
        src='/images/quickGuide/my account/my account payments.png'
        alt='my account payments'
      />
    ),
    picture3: (
      <img
        src='/images/quickGuide/my account/my account referal.png'
        alt='my acoount referal'
        className='rounded-2xl w-[55rem] h-[27rem]'
      />
    ),
  },
];

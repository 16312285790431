import tw, { styled } from 'twin.macro';

export const WideCardStyle = styled.div`
  border-radius: 0.8rem;
  ${tw`flex m-2 p-4 items-center xl:w-[45%]`}

  p {
    margin-bottom: 0;
  }

  ${(props) =>
    props.color
      ? `background-color: ${props.color}`
      : `background-color: white`};

  hr {
    border-top: 2.5px solid black;
    transform: rotate(90deg);
    ${tw`w-14 rounded-lg `}
  }

  #titleCard {
    ${tw``}
  }

  .cardIcon {
    ${tw`ml-3 h-10 w-10 pt-2`}
  }
`;

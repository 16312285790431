import { useContext } from 'react';
import { useQuery } from 'react-query';
import {
  IFilterPayeeNames, //pendinete ajustar esto
  IGetPayeeNamesServiceResponse,
} from '../../../interfaces/myAccount';
import fetchPayeeNames from '../../endPoints/myAccount/getPayeeNames';
import getPayeeNamesTransform from '../../transforms/myAccount/getPayeeNames';
import { AuthContext } from '../../../context/useSaveDataSession';

const getPayeeNames = (
  params: IFilterPayeeNames,
): IGetPayeeNamesServiceResponse => {
  const { logout } = useContext<any>(AuthContext);

  const { error, data, isLoading } = useQuery(
    ['GET_PAYEE_NAMES', params],
    () => {
      return fetchPayeeNames(params);
    },
    {
      onSuccess: (data) => {
        if (data.message === 'Token has expired') {
          logout();
        } else if (data.status === 401) {
          logout();
        }
      },
    },
  );

  if (data) {
    return { error, response: getPayeeNamesTransform(data), isLoading };
  } else {
    return { error, response: data, isLoading };
  }
};

export default getPayeeNames;

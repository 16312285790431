const fetchPostServiceFreshdesk = async (data: any) => {
  const formData: any = new FormData();
  formData.append(
    'custom_fields[cf_customerid]',
    data.custom_fields.cf_customerid,
  );

  if (data.imageFile.name) {
    formData.append('attachments[]', data.imageFile);
  }

  formData.append('type', data.type);
  formData.append('subject', data.subject);
  formData.append('description', data.description);
  formData.append('email', data.email);
  formData.append('priority', data.priority);
  formData.append('status', data.status);

  const options = {
    method: 'POST',
    headers: {
      Authorization: 'Basic QlZKUWl2QVJXN1M4ZEs1MmhPQXc6WA==',
    },
    body: formData,
  };

  try {
    const response = await fetch(
      `https://usabuyinggroup.freshdesk.com/api/v2/tickets`,
      options,
    );
    const data = await response.json();
    return data;
  } catch (error) {
    console.error(error);
  }
};

export default fetchPostServiceFreshdesk;

// Basic UUV6dGUzblpPNUNBZTFlb1hKOlg= autorization cuenta daniel

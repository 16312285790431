import { Spin } from 'antd';
import { useEffect } from 'react'
import cookie from 'react-cookies';
import { useLocation } from 'react-router-dom';

const RedirectToNewPortal = () => {

    const location = useLocation();

    useEffect(() => {
        const currentPath = location.pathname;
        const match = currentPath.match(/\/active-deal\/(\d+)/);
        const token = cookie.load('token')
        const tokenParam = token ? `token=${token}` : '';
        if (match) {
            const dealNumber = match[1];
            window.location.href = `${process.env.REACT_APP_URL_NEW_BUYERS}/deals?id=${dealNumber}&${tokenParam}`;
        } else {
            const newPath = currentPath === "/active-deals" ? "/deals" : currentPath; 
            window.location.href = `${process.env.REACT_APP_URL_NEW_BUYERS}${newPath}?${tokenParam}`;
        }
    }, [location]);

    return (
        <div className='flex justify-center items-center h-screen'>
            <Spin size="large" />
        </div>
    );
}

export default RedirectToNewPortal
import React from 'react';
import { Pagination } from 'antd';
import { PaginationProps } from 'antd/es/pagination';

const PaginationCustom = ({
  totalItems,
  current,
  setSizeItemPage,
  sizeItemsPage,
  setCurrent,
  mobile,
}: any) => {
  const onShowSizeChange: PaginationProps['onShowSizeChange'] = (
    current,
    pageSize,
  ) => {
    setSizeItemPage(pageSize);
    setTimeout(() => {
      setCurrent(1);
    }, 500);
  };

  const onChange: PaginationProps['onChange'] = (page) => {
    setCurrent(page);
  };

  return (
    <div
      className={`${'mt-4 mb-4 w-full flex'} ${
        mobile ? 'justify-center' : 'justify-end'
      }`}>
      <Pagination
        current={current}
        pageSize={sizeItemsPage}
        showSizeChanger
        onShowSizeChange={onShowSizeChange}
        defaultCurrent={1}
        total={totalItems}
        onChange={onChange}
        responsive
      />
    </div>
  );
};

export default PaginationCustom;

import { useMutation } from 'react-query';
import updatePayeeNameFetch from '../../endPoints/myAccount/updatePayeeName';

const useUpdatePayeeName = () => {
  const { mutate } = useMutation(updatePayeeNameFetch);

  return { payeeNameMutateFnc: mutate };
};

export default useUpdatePayeeName;

/* eslint-disable no-undef */
import cookie from 'react-cookies';

const fetchMyCommits = async (params: any) => {
  const paramsPrice = params.price.map((opt: any) => {
    const option = `&range_price[]=${opt}`;

    return option;
  });

  const paramsStatus = params.status.map((opt: any) => {
    const option = `&commit_status[]=${opt}`;

    return option;
  });

  const response = await fetch(
    `${
      process.env.REACT_APP_BACKEND_URL
    }buyers/active-deals/my-commits?date_range=${
      params.date_range
    }${paramsPrice.join('&')}${paramsStatus.join(
      '&',
    )}&is_expired=${params.expired.toString()}&item_name=${params.item_name}&limit=${params.limit}&start=${params.start}`,
    {
      headers: {
        Authorization: `Bearer ${cookie.load('token')}`,
      },
    },
  ).then((fetchResponse) => fetchResponse.json());

  return response;
};

export default fetchMyCommits;

import { useContext } from 'react';
import { useQuery } from 'react-query';
import fetchToken from '../../endPoints/purchaseOrders/index';
import transformDataPurchaseOrder, {
  IServiceTransform,
} from '../../transforms/purchaseOrders';
import { AuthContext } from '../../../context/useSaveDataSession';

interface IResponseService {
  response: IServiceTransform | undefined;
  error: any;
  isLoading: boolean;
  refetch: () => void;
}

const getPurchaseOrder = (params: any): IResponseService => {
  const { logout } = useContext<any>(AuthContext);

  const { error, data, isLoading, refetch } = useQuery(
    ['GET_TRACKING_NUMBERS_DATA', params],
    () => {
      return fetchToken(params);
    },
    {
      onSuccess: (data) => {
        if (data.message === 'Token has expired') {
          logout();
        } else if (data.status === 401) {
          logout();
        }
      },
    },
  );

  if (data) {
    return {
      error,
      response: transformDataPurchaseOrder(data),
      isLoading,
      refetch,
    };
  } else {
    return { error, response: data, isLoading, refetch };
  }
};

export default getPurchaseOrder;

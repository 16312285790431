import React from 'react';
import Chips from './chips';

const RenderStatus = ({ item }: any) => {
  const [statusCommitment, setStatusCommitment] = React.useState('expired');

  React.useEffect(() => {
    const status: string[] = [];
    if (item.days_to_expire <= 0) {
      setStatusCommitment('expired');
      return;
    }

    item.variations.map((variation: any) => {
      if (variation.buyer_commit === variation.received) {
        status.push('received');
      } else if (
        variation.buyer_commit > variation.received &&
        variation.buyer_commit > 0 &&
        variation.received > 0
      ) {
        status.push('partial received');
      } else {
        status.push('not received');
      }
    });

    if (status.includes('partial received')) {
      setStatusCommitment('partial received');
    } else if (
      status.includes('received') &&
      !status.includes('not received') &&
      !status.includes('partial received')
    ) {
      setStatusCommitment('received');
    } else {
      setStatusCommitment('not received');
    }
  }, [statusCommitment, item]);

  return <Chips title={statusCommitment} />;
};

export default RenderStatus;

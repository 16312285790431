import React from 'react';
import { ColumnsType } from 'antd/es/table';
// import { FileOutlined } from '@ant-design/icons';
// import Chips from '../containers/chips';
import { IData } from '../../../interfaces/myCommitments';
import { RenderQty } from '../containers/RenderQty';
import RenderStatus from '../containers/RenderStatus';
import usePriceFormat from '../../../hooks/usePriceFormat';
import { Tag } from 'antd';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import RenderActions from '../containers/RenderActions';

export const dataTableFields: ColumnsType<any> = [
  {
    title: '',
    // dataIndex: 'item',
    key: 'statusTable',
    width: '2rem',
    render: (item) => {
      return <RenderStatus item={item} />;
    },
  },
  {
    title: 'Item',
    key: 'item',
    width: '25rem',
    className: 'fontGTAmericanR',
    sorter: (a: any, b: any) => {
      return a.item.length - b.item.length;
    },
    render: (item: IData) => (
      <div>
        <aside className='opacity-40 text-left truncate-text' id='brand'>
          {item.model}
        </aside>
        <aside
          className='text-left font-semibold mb-4 truncate-text'
          id='title'>
          {item.title}
        </aside>
        <aside className='opacity-40 text-left mb-4'>
          Deal expires: {item.expiration_date}
        </aside>
      </div>
    ),
  },
  {
    title: 'Quantity',
    key: 'quantity',
    width: '13rem',
    className: 'fontGTAmericanR ',
    render: (item: IData) => <RenderQty item={item} />,
  },
  {
    title: 'Qty Received',
    key: 'quantityReceived',
    width: '10rem',
    className: 'fontGTAmericanR ',
    render: (item: IData) =>
      item?.variations?.map((variation: any, index: any) => (
        <div className='flex w-full items-center' key={index}>
          <div className='flex  w-3/12 mb-7 p-2 text-left font-semibold'>
            {variation.received}
          </div>
        </div>
      )),
  },
  {
    title: 'Qty Waiting',
    key: 'quantityWaiting',
    width: '10rem',
    className: 'fontGTAmericanR ',
    render: (item: IData) =>
      item?.variations?.map((variation: any, index: any) => (
        <div className='flex w-full items-center' key={index}>
          <p
            className='flex  w-3/12 mb-7 p-2 text-left font-semibold'
            data-testid='quatityWaiting'>
            {parseInt(variation.buyer_commit) >= 0
              ? variation.buyer_commit - variation.received
              : 0}
          </p>
        </div>
      )),
  },
  {
    title: 'We Reimburse',
    dataIndex: 'price',
    key: 'priceTable',
    width: '15rem',
    sorter: (a: any, b: any) => {
      return a.price - b.price;
    },
    render: (price: string[], item) => {
      return (
        <>
          <p
            className={
              Number(price) > Number(item.variations[0].list_price) ||
              Number(price) < Number(item.variations[0].list_price)
                ? 'flex flex-col font-bold mt-4 fontGTAmericanR'
                : 'flex flex-col font-bold fontGTAmericanR'
            }>
            {usePriceFormat(Number(price))}
            {Number(price) > Number(item.variations[0].list_price) && (
              <Tag
                style={{
                  borderRadius: 10,
                  fontSize: '8px',
                  width: '4.5rem',
                  paddingLeft: 0,
                  paddingRight: 0,
                  paddingTop: 0,
                  textAlign: 'center',
                }}
                color='#81BFFD'>
                {usePriceFormat(
                  Number(price) - Number(item.variations[0].list_price) || 0,
                )}
                <ArrowUpwardIcon fontSize='inherit' />
                Price
              </Tag>
            )}

            {Number(price) < Number(item.variations[0].list_price) && (
              <Tag
                style={{
                  borderRadius: 10,
                  fontSize: '8px',
                  width: '4.5rem',
                  paddingLeft: 0,
                  paddingRight: 0,
                  paddingTop: 0,
                  textAlign: 'center',
                }}
                color='#ff6339'>
                {usePriceFormat(
                  Number(item.variations[0].list_price) - Number(price) || 0,
                )}
                <ArrowDownwardIcon fontSize='inherit' />
                Price
              </Tag>
            )}
          </p>
        </>
      );
    },
  },
  {
    title: 'Items Links',
    key: 'itemLinks',
    width: '12rem',
    className: 'fontGTAmericanR ',
    render: (item: IData) => {
      return (
        <div className='flex flex-wrap gap-2'>
          {item?.variations?.map((variation: any) =>
            variation?.stores?.map((store: any, index: any) => (
              <a
                href={store.link}
                className='flex'
                key={index}
                target='_blank'
                rel='noopener noreferrer'>
                <img
                  alt={store.name}
                  width='35rem'
                  className='float-left mr-1 rounded-lg'
                  src={store.icon}
                />
              </a>
            )),
          )}
        </div>
      );
    },
  },
  {
    title: 'Actions',
    key: 'actions',
    width: '13rem',
    className: 'fontGTAmericanR ',
    render: (_action, item) => {
      return <RenderActions item={item} />;
    },
  },
];

export const dateFilter = [
  {
    name: 'datefilter',
    styles: 'mt-7',
  },
];

export const selectFilters = [
  {
    name: 'status',
    title: 'Status',
    menuItem: [
      {
        value: 'partial_received',
        labelName: 'Partial Qty Received',
      },
      {
        value: 'fully_received',
        labelName: 'Fully Received',
      },
      {
        value: 'not_received',
        labelName: 'Not Received',
      },
    ],
  },
  {
    name: 'expired',
    title: 'Expired',
    menuItem: [
      {
        value: 'yes',
        labelName: 'Yes',
      },
      // {
      //   value: 'not',
      //   labelName: 'Not',
      // },
    ],
  },
  {
    name: 'price',
    title: 'Price',
    isPrice: true,
    menuItem: [
      {
        value: '1-100',
        labelName: '$1 - $100',
      },
      {
        value: '100-200',
        labelName: '$100 - $200',
      },
      {
        value: '200-300',
        labelName: '$200 - $300',
      },
      {
        value: '300-400',
        labelName: '$300 - $400',
      },
      {
        value: '400-500',
        labelName: '$400 - $500',
      },
      {
        value: '500-600',
        labelName: '$500 - $600',
      },
      {
        value: '600-700',
        labelName: '$600 - $700',
      },
      {
        value: '700-800',
        labelName: '$700 - $800',
      },
      {
        value: '800-900',
        labelName: '$800 - $900',
      },
      {
        value: '900-1000',
        labelName: '$900 - $1000',
      },
      {
        value: '1000-1100',
        labelName: '$1000 - $1100',
      },
      {
        value: '1100-1200',
        labelName: '$1100 - $1200',
      },
      {
        value: '1200-1300',
        labelName: '$1200 - $1300',
      },
      {
        value: '1300-1400',
        labelName: '$1300 - $1400',
      },
      {
        value: '1400-1500',
        labelName: '$1400 - $1500',
      },
      {
        value: '1500-1600',
        labelName: '$1500 - $1600',
      },
      {
        value: '1600-1700',
        labelName: '$1600 - $1700',
      },
      {
        value: '1700-1800',
        labelName: '$1700 - $1800',
      },
      {
        value: '1800-1900',
        labelName: '$1800 - $1900',
      },
      {
        value: '1900-2000',
        labelName: '$1900 - $2000',
      },
    ],
  },
];

export const searchFilter = [
  {
    name: 'searchItem',
    placeHolder: 'Search Items',
    stylesSecondContainer: 'mr-4',
    width: 200,
  },
];

export const stylesFilterSectionSearchAndDdate =
  'flex justify-end w-full mt-5 mr-12';

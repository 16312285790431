import { Pagination } from '../../../interfaces/activeDeals';
import {
  InternalData,
  UpdatesInfo,
} from '../../../interfaces/helpCenter/updateInfo';

export interface IResTransform {
  data: InternalData[];
  meta: {
    pagination: Pagination;
    status: Boolean;
    totals: Number;
  };
}

const updatesTransform = (data: UpdatesInfo): IResTransform => {
  const dataTranformed = data.data;
  const metaTranform = {
    pagination: data?.pagination,
    status: data?.status,
    totals: data?.totals?.items,
  };

  return { data: dataTranformed, meta: metaTranform };
};

export default updatesTransform;

import { useQuery } from 'react-query';
import fetchGetConversationTicket from '../../endPoints/helpCenter/getConversationTicket';

export interface IResponseService {
  response: any;
  error: any;
  isLoading: boolean;
}

const useGetConversationTickets = (params: any): IResponseService => {
  const { error, data, isLoading } = useQuery(
    ['GET_CONVERSATION_TICKET', params],
    () => {
      return fetchGetConversationTicket(params);
    },
  );

  return { error, response: data, isLoading };
};

export default useGetConversationTickets;

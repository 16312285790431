/* eslint-disable no-undef */
import React, { useContext, useState, useEffect } from 'react';
import cookie from 'react-cookies';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { AuthContext } from '../context/useSaveDataSession';
import useStatesGlobalStore from '../hooks/useStatesGlobalStore';
import { IAlertType } from '../interfaces/activeDeal/index';
import GeneralAlert from '../components/UI/generalAlert/GeneralAlert';
import { Buffer } from 'buffer';
import { Spin } from 'antd';

const Login = () => {
  const { buyerInfo, dashboardInfo, setBuyerInfo, setDashboardInfo } =
    useStatesGlobalStore();
  const [postData, setPostData] = useState({
    credentials: '',
    password: '',
  });

  const [searchParams] = useSearchParams();
  const logoutParam = searchParams.get('logout');

  const [showAlert, setShowAlert] = useState<IAlertType>({
    message: '',
    show: false,
    title: '',
    type: 'success',
  });

  let navigate = useNavigate();

  const { login } = useContext<any>(AuthContext);

  const { authInfo, setAuthInfo } = useStatesGlobalStore();

  useEffect(() => {
    if (authInfo.state === 'sessionAproved' && cookie.load('token')) {
      navigate('/dashboard');
    }
  }, [authInfo.state]);

  const handleChange = (e: any) => {
    setPostData({
      ...postData,
      [e.target.name]: e.target.value,
    });
  };

  const fetchApiPost = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/buyers/login`,
      {
        method: 'POST',
        body: JSON.stringify(postData),
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'same-origin',
      },
    )
      .then((res) => res.json())
      .then((data) => data);

    return response;
  };

  const token = window.location.search.split('token=')[1];

  const loginApp = () => {
    const dateExpired = new Date(new Date().getTime() + 1000 * 60 * 1440);

    setAuthInfo({
      expiration: dateExpired?.toISOString(),
      state: 'sessionAproved',
    });
  };

  React.useEffect(() => {
    // eslint-disable-next-line no-undef
    if (token) {
      let tokenEncoded = Buffer.from(token, 'base64');
      let tokenDecodedString = tokenEncoded.toString('ascii');
      const data = JSON.parse(tokenDecodedString);

      setBuyerInfo({
        ...buyerInfo,
        buyerInternalId: data.data.buyer.buyer_internal_id,
        firstName: data.data.buyer.first_name,
        lastName: data.data.buyer.last_name,
        emailAddress: data.data.buyer.email,
        mobilePhone: data.data.buyer.phone_number,
        mobilePhoneTwo: data.data.buyer.phone_number_2,
        mobilePhoneThree: data.data.buyer.phone_number_3,
        buyerID: data.data.buyer.buyer_id,
        additionalIds: data.data.buyer.buyer_ids,
        checksRequestsAccess: data.data.buyer.checks_requests_access,
        betaPortalAccess: data.data.buyer.beta_portal_access,
      });

      setDashboardInfo({ ...dashboardInfo, info: data.data.dashboard });

      loginApp();

      cookie.save('token', data.data.token);
      const protocol = window.location.protocol;
      const domain = window.location.host;
      window.location.href = `${protocol}//${domain}/dashboard`;
    }

    if(logoutParam && !token && process.env.REACT_APP_TESTING_DEV !== 'development') {
      setTimeout(() => {
        window.location.href = 'https://beta.usabuying.group';
      }, 2000);
    }
    else if (!token && process.env.REACT_APP_TESTING_DEV !== 'development') {
      window.location.href = 'https://beta.usabuying.group';
    }
  }, []);

  const handleLogin = (e: any) => {
    e.preventDefault();
    fetchApiPost().then((data) => {
      if (data.status == 200) {
        cookie.save('token', data.data.token, {
          path: '/',
          maxAge: process.env.REACT_APP_TOKEN_EXPIRED,
        });

        setBuyerInfo({
          ...buyerInfo,
          buyerInternalId: data.data.buyer.buyer_internal_id,
          firstName: data.data.buyer.first_name,
          lastName: data.data.buyer.last_name,
          emailAddress: data.data.buyer.email,
          mobilePhone: data.data.buyer.phone_number,
          mobilePhoneTwo: data.data.buyer.phone_number_2,
          mobilePhoneThree: data.data.buyer.phone_number_3,
          buyerID: data.data.buyer.buyer_id,
          additionalIds: data.data.buyer.buyer_ids,
          checksRequestsAccess: data.data.buyer.checks_requests_access,
          betaPortalAccess: data.data.buyer.beta_portal_access,
          signup_date: data.data.buyer.signup_date
        });

        setDashboardInfo({ ...dashboardInfo, info: data.data.dashboard });

        // if (
        //   data.data.buyer.beta_portal_access &&
        //   data.data.buyer.beta_portal_access === 1 &&
        //   cookie.load('token')
        // ) {
        // } else {
        //   setShowAlert({
        //     show: true,
        //     message: `You still don't have access to the beta.`,
        //     title: 'Error',
        //     type: 'error',
        //   });
        // }
        login();
        navigate('/dashboard');
      } else if (data.status === 401) {
        alert('Invalid credentials');
        setPostData({ credentials: '', password: '' });
      }
    });
  };

  // if (authInfo.state === 'sessionDenied') {
  //   window.location.href = 'https://usabuying.group/';
  // }

  return (
    <>
      {showAlert.show && (
        <GeneralAlert
          alertType={showAlert.type}
          alertMessage={showAlert.message}
          alertTitle={showAlert.title}
          setShowAlert={setShowAlert}
          fontSize={17}
        />
      )}
      {process.env.REACT_APP_TESTING_DEV !== 'development'? 
       <div className='flex justify-center items-center h-screen'>
            <Spin size="large" />
        </div>
      :
      <>
      <div className='pt-16'>
        <h3 className='text-center mt-5 text-3xl mb-5 fontSharpGrotesk22Medium w-full'>
          Log in
        </h3>
      </div>
      <div className='flex flex-col lg:mx-52 lg:mb-16'>
        <div className='mx-5 bg-white relative z-20 rounded-xl mt-5 shadow-2xl p-4 sm:w-7/12 sm:ml-auto sm:mr-auto'>
          <form
            onSubmit={handleLogin}
            method='POST'
            className='flex flex-col justify-center'>
            <label className='mt-5' htmlFor='credentials'>
              Username
            </label>
            <input
              data-testid='input-username'
              name='credentials'
              onChange={handleChange}
              required
              type='text'
              value={postData.credentials}
              className='bg-gray-100
                placeholder-gray-500 rounded-lg mt-7 p-3 text-black'
            />
            <label className='mt-5' htmlFor='password'>
              Password
            </label>
            <input
              data-testid='input-password'
              className='bg-gray-100 placeholder-gray-500 rounded-lg p-3 mt-5 text-black'
              name='password'
              onChange={handleChange}
              required
              type='password'
              value={postData.password}
            />
            <div className='flex justify-center mb-6 mt-8'>
              <button
                data-testid='button-login'
                className='fontSharpGrotesk22Medium hover:bg-blue-500 hover:text-white py-4 text-center px-17 md:px-8 md:py-4 text-blue-500 rounded-xl text-xl md:text-base mt-4 mb-10 border-2 border-blue-500'>
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
      </>
      }
    </>
  );
};

export default Login;

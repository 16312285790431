import React, { useState } from 'react';
import * as Styles from '../style';
import { Box, Divider } from '@mui/material';
import getAchAccounts from '../../../api/services/myAccount/getAchAccounts';
import Filter from '../../../components/UI/filter';
import { achAccountsSearchFilter, stylesSearchFilter } from '../constants';
import { Table } from 'antd';
import { IData } from '../../../interfaces/myAccount';
import RenderActionsAchAccountsTable from './RenderActionsAchAccountsTable';
import { IAlertType } from '../../../interfaces/activeDeal';
import GeneralAlert from '../../../components/UI/generalAlert/GeneralAlert';

const DetailAllAchAccounts = ({ phoneNumberDetails }: any) => {
  const [filterOptions, setFilterOptions] = useState<any>({
    search: '',
    limit: 0,
    sort_field: 'created_at',
    sort_order: 'desc',
  });
  const [showAlert, setShowAlert] = useState<IAlertType>({
    message: '',
    show: false,
    title: '',
    type: 'success',
  });

  const achAccountsTableColumns = [
    {
      title: 'Account Name',
      dataIndex: 'account_name',
      key: 'account_name',
    },
    {
      title: 'Routing Number',
      dataIndex: 'routing_number',
      key: 'routing_number',
    },
    {
      title: 'Account Number',
      dataIndex: 'account_number',
      key: 'account_number',
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (item: IData) => (
        <RenderActionsAchAccountsTable
          accountInfo={item}
          phoneNumberDetails={phoneNumberDetails}
          setShowAlert={setShowAlert}
        />
      ),
    },
  ];

  const { response, isLoading } = getAchAccounts(filterOptions);

  return (
    <div>
      {showAlert?.show && (
        <GeneralAlert
          alertType={showAlert.type}
          alertMessage={showAlert.message}
          alertTitle={showAlert.title}
          setShowAlert={setShowAlert}
        />
      )}
      <h2 className='text-3xl'>ACH Accounts</h2>
      <Filter
        filterOptions={filterOptions}
        setFilterOptions={setFilterOptions}
        searchFilters={achAccountsSearchFilter}
        stylesFilterSectionSearchAndDate={stylesSearchFilter}
        isSaveFilterVisible={false}
        showFilterTitle={true}
      />
      <Box
        sx={{
          mt: 7,
          p: 4,
          borderRadius: 3,
          border: '1px solid #b2b2b2',
          marginX: 1,
          overflowY: 'auto',
          height: '37rem',
        }}>
        <div
          className='flex justify-between p-4 pt-0'
          data-testid='titleAllAchAccounts'>
          <h5 className='font-semibold text-lg'>ACH Accounts</h5>
        </div>
        <Divider />
        <Styles.TableContainer>
          <Table
            className='tableStyles tableHeadtyles tableBodyStyles'
            columns={achAccountsTableColumns}
            dataSource={response?.data}
            loading={isLoading}
            pagination={false}
          />
        </Styles.TableContainer>
      </Box>
    </div>
  );
};

export default DetailAllAchAccounts;

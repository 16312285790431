import React from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';

const ListTicketHelper = ({ ticket }: any) => {
  const StatusTicket = () => {
    let background = 'bg-gray-400';

    if (ticket.status === 2) {
      background = 'bg-green-500';
    } else if (ticket.status === 3) {
      background = 'bg-amber-300';
    } else if (ticket.status === 4) {
      background = 'bg-cyan-700';
    } else if (ticket.status === 5) {
      background = 'bg-gray-400';
    }

    return <div className={`dot ${background}`}></div>;
  };

  return (
    <>
      <Link to={'/check-ticket-status'} state={{ search: ticket.id }}>
        <div className='flex flex-wrap cardInternal text-gray-400 justify-between ml-[2.7rem]'>
          <span># {ticket.id}</span>
          <span>{moment(ticket.created_at).format('DD/MM/YY')}</span>
        </div>
        <div className='flex mt-2 cardInternal'>
          <StatusTicket />
          <div className='font-semibold mx-5'>{ticket.subject}</div>
        </div>
        <hr className='my-5' />
      </Link>
    </>
  );
};

export default ListTicketHelper;

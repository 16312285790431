import React, { useState } from 'react';
import * as Styles from '../style';
import { Button, Stack } from '@mui/material';
import { themeCustom } from '../../../../theme';
import { useQueryClient } from 'react-query';
import useQuickCommit from '../../../../api/services/activeDeals/quickCommit';
import { notifyImpedimentToDecrease } from '../../../../helpers';

const { colors } = themeCustom;
const QuickCommit = ({ deal, setShowAlert, setViewModal }: any) => {
  const queryClient = useQueryClient();
  const { mutate, isLoading } = useQuickCommit();
  const [isNotAllowToDecrease, setIsNotAllowToDecrease] = useState(false);
  const [variationQty, setVariationQty] = useState({
    id: '',
    qty: '',
  });
  const handleChange = (e: any, variationId: any, currentAmount: any) => {
    notifyImpedimentToDecrease(
      e.target.value,
      currentAmount,
      setIsNotAllowToDecrease,
      setShowAlert,
    );
    setVariationQty({ ...variationQty, id: variationId, qty: e.target.value });
  };

  const handleQuickCommit = async () => {
    mutate(
      { commitsData: { commits: [variationQty] }, dealId: deal.id },
      {
        onSuccess: (data) => {
          if (data?.status) {
            setViewModal({ active: false });

            setShowAlert({
              show: true,
              message: data?.error
                ? data?.error
                : 'You have committed to this deal successfully',
              title: data?.error ? 'Error' : 'Success',
              type: data?.error ? 'warning' : 'success',
            });
            // TODO: REVIEW RENDER TO SHOW NEW DATA
            queryClient.invalidateQueries('ACTIVEDEALS');
          } else {
            setShowAlert({
              show: true,
              message: data?.error,
              title: 'Error',
              type: 'warning',
            });
          }
        },
        onError: (error: any) => {
          setShowAlert({
            show: true,
            message: error,
            title: 'Error',
            type: 'error',
          });
        },
      },
    );
  };

  return (
    <Stack
      data-testid='quickCommitView'
      sx={{
        borderRadius: 3,
        padding: 2,
        paddingBottom: 0,
      }}>
      <div className='h-40 overflow-y-auto'>
        <Styles.TableHeaderCommitContainer className='flex px-4'>
          <h3>Color</h3>
          <h3 className='ml-24'>Qty Committed</h3>
        </Styles.TableHeaderCommitContainer>
        {deal?.quantityUser?.map((item: any, index: any) => (
          <>
            {item.fullyCommitted && (
              <p className='mb-0 text-xs bg-red-100 font-bold mt-3 p-[2px] text-center rounded-sm'>
                You've already committed to this item's variation.
              </p>
            )}
            <div
              className='flex rounded-lg items-center justify-center bg-slate-200 mt-2'
              key={index}>
              <div className='flex justify-between w-10/12 items-center h-14'>
                <span
                  className={`text-sm text-left flex flex-wrap overflow-x-auto w-20 py-2 `}>
                  {item.color}
                </span>
                <input
                  disabled={item.fullyCommitted}
                  className='ml-3 text-slate-500 cursor-text bg-slate-200 border border-gray-400 rounded-lg h-9 w-[3.5rem] font-semibold pl-2'
                  type='number'
                  data-testid={`inputQuickCommit#${item.id}`}
                  name={item.color}
                  onChange={(e: any) => handleChange(e, item.id, item.amount)}
                  defaultValue={item.amount}
                />
                <Button
                  disabled={
                    isLoading ||
                    item.id !== variationQty.id ||
                    item.fullyCommitted ||
                    isNotAllowToDecrease
                  }
                  variant='outlined'
                  data-testid={`btnSave#${item.id}`}
                  onClick={handleQuickCommit}
                  sx={{
                    border: '1.5px solid',
                    borderRadius: 2,
                    textTransform: 'none',
                    fontWeight: 'bold',
                    '&:hover': {
                      backgroundColor: '#0069d9',
                      borderColor: '#0062cc',
                      boxShadow: 'none',
                      color: colors.white,
                    },
                  }}>
                  {isLoading ? (
                    <img
                      className='h-5 w-5'
                      src='images/activeDeals/spinning-circle.gif'
                      alt='loading icon'
                      width={200}
                      height={200}
                    />
                  ) : (
                    <span>Commit</span>
                  )}
                </Button>
              </div>
            </div>
          </>
        ))}
      </div>
    </Stack>
  );
};

export default QuickCommit;

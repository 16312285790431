import React from 'react';

const FaqsHelper = ({ title, paragraph }: any) => {
  
  return (
    <>
      <div className='p-1.5'>
        <span className='text-gray-400 font-semibold'>{title}</span>
        <p className='mt-5'>{paragraph}</p>
      </div>
    </>
  );
};

export default FaqsHelper;

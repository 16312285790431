import React from 'react';
import { Box } from '@mui/material';

export const TemplateSideSheet = ({
  anchor,
  toggleDrawer,
  componentRender,
  modalBorderTopRightRadius,
  width,
}: any) => {
  return (
    <Box
      className='mt-36 w-screen rounded-t-3xl lg:mt-0 lg:w-auto lg:rounded-t-none'
      sx={{
        bgcolor: 'white',
        // height: '150%',
        p: 4,
        width: width,
        borderTopLeftRadius: '30px',
        borderTopRightRadius: modalBorderTopRightRadius,
        height: '100vh',
        overflowY: 'scroll',
      }}
      role='presentation'
      onClick={toggleDrawer(anchor, true)}
      onKeyDown={toggleDrawer(anchor, true)}>
      {componentRender}
    </Box>
  );
};

import React, { useState } from 'react';
import { themeCustom } from '../../../theme';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import { IFormTemplate } from '../../../interfaces/userProfile';
import { ErrorMessage } from '@hookform/error-message';
import {
  Button,
  Box,
  Checkbox,
  Divider,
  FormControlLabel,
  InputBase,
  InputLabel,
  Stack,
} from '@mui/material';
import GeneralAlert from '../../../components/UI/generalAlert/GeneralAlert';
import useFormTemplateFunctions from '../hooks/useFormTemplateFunctions';
import { DrawerComponentUI } from '../../../components/UI/sideSheetNew';
import DetailAllAchAccounts from './DetailAllAchAccounts';
import DetailAllPayeeNames from './DetailAllPayeeNames';

const FormTemplate = ({
  extraACHForm,
  extraChangePasswordForm,
  extraFormContainerStyles1,
  extraFormContainerStyles2,
  extraFormContainerStyles3,
  formFields,
  handleCloseExtraForm,
  styles,
  submitConfig,
  formName,
  phoneNumberDetails,
  updateInfo,
}: any) => {
  const { colors } = themeCustom;
  const {
    control,
    handleSubmit,
    getValues,
    register,
    reset,
    formState: { errors },
  } = useForm<IFormTemplate>({
    defaultValues: {
      account_name: updateInfo?.account_name || '',
      account_number: updateInfo?.account_number || '',
      routing_number: updateInfo?.routing_number || '',
      name: updateInfo?.name || '',
    },
  });

  const {
    alert,
    handleAddAchAccount,
    handleAddPayeeName,
    handleChangePassword,
    handleGetVerificationCode,
    handleUpdateAchACcount,
    handleUpdatePayeeName,
    sameValueValidation,
    setAlert,
    setViewModal,
    viewModal,
  } = useFormTemplateFunctions();

  const [isAvailableToSendCode, setIsAvailableToSendCode] = useState(false);

  const handleIntemediateVerificationCode = () => {
    handleGetVerificationCode(setIsAvailableToSendCode, updateInfo);
  };

  const onSubmit: SubmitHandler<IFormTemplate> = (formData) => {
    switch (formName) {
      case 'changePasswordForm':
        handleChangePassword(formData, getValues, reset);
        break;
      case 'addPayeeNameForm':
        handleAddPayeeName(formData, reset);
        break;
      case 'addAchAccountForm':
        handleAddAchAccount(formData, reset);
        break;
      case 'updateAchAccountForm':
        handleUpdateAchACcount(formData, updateInfo?.id, handleCloseExtraForm);
        break;
      case 'updatePayeeNameForm':
        handleUpdatePayeeName(formData, updateInfo?.id, handleCloseExtraForm);
        break;
    }
  };

  return (
    <>
      {alert.show && (
        <GeneralAlert
          alertType={alert.type}
          alertMessage={alert.message}
          alertTitle={alert.title}
          setShowAlert={setAlert}
        />
      )}
      <form className={styles} onSubmit={handleSubmit(onSubmit)}>
        {formFields.map((item: any, index: any) => (
          <Controller
            key={index}
            name={item.name}
            control={control}
            defaultValue=''
            render={({ field }) => (
              <Stack sx={{ mr: item.mr, mb: 4 }}>
                <InputLabel
                  disabled={item.disabled}
                  shrink
                  htmlFor={item.label}
                  sx={{
                    color: colors.grayLabels,
                    fontWeight: 'bold',
                    fontSize: 15,
                  }}>
                  {item.label}
                </InputLabel>
                <Box
                  sx={{
                    p: '2px 4px',
                    display: 'flex',
                    alignItems: 'center',
                    width:
                      (window.innerWidth < 1000 && item.widthMobile) ||
                      (window.innerWidth < 1400 && item.widthLaptops) ||
                      (window.innerWidth > 1400 && item.width),
                    height: 50,
                    borderRadius: 0.6,
                    backgroundColor: colors.grayInputs,
                    border: '1px solid #dedede',
                    '& :disabled': {
                      backgroundColor: '#f2f2f5',
                    },
                  }}>
                  <InputBase
                    {...field}
                    {...register(item.name, {
                      required: 'This field is required',
                      minLength: {
                        value: item.minLength,
                        message: item.minLengthMessage,
                      },
                      maxLength: {
                        value: item.maxLength,
                        message: item.maxLengthMessage,
                      },
                      pattern: {
                        value: item.pattern,
                        message: 'Match the required pattern',
                      },
                      disabled: item.disabled,
                    })}
                    autoComplete={item.autoCompleteIndicator}
                    id={item.label}
                    aria-describedby={item.ariaDescribed}
                    placeholder={item.placeholder}
                    name={item.name}
                    type={item.type}
                    inputProps={{
                      'aria-label': 'Without label',
                      'data-testid': `${item.testID}`,
                    }}
                    sx={{
                      flex: 1,
                      p: 1,
                      fontSize: 15,
                      fontWeight: 'bold',
                      input: {
                        '&::placeholder': {
                          '-webkit-text-fill-color': `black !important`,
                          fontWeight: 'bold',
                        },
                      },
                    }}
                  />
                </Box>
                <ErrorMessage
                  errors={errors}
                  name={item.name}
                  render={({ message }) => (
                    <span className='text-red-600'>{message}</span>
                  )}
                />
              </Stack>
            )}
          />
        ))}
        <div className={extraFormContainerStyles1}>
          {extraACHForm && (
            <Divider
              sx={{
                bgcolor: '#dfdcdc',
                height: 2,
                borderRadius: 2,
                mb: 5,
                mt: 2,
              }}
            />
          )}
          <div className={extraFormContainerStyles2}>
            {extraACHForm && (
              <>
                <p className='font-semibold m-0'>
                  For security, we need your verification to create this payment
                  method.
                </p>
                <p className='font-semibold m-0'>{phoneNumberDetails}</p>
              </>
            )}
            <div className={extraFormContainerStyles3}>
              {extraACHForm && (
                <Controller
                  name={'verification_code'}
                  control={control}
                  render={({ field }) => (
                    <Stack sx={{ mb: 4 }}>
                      <InputLabel
                        shrink
                        htmlFor={'verification_code'}
                        sx={{
                          color: colors.grayLabels,
                          fontWeight: 'bold',
                          fontSize: 15,
                          display: {xs: 'grid', sm: 'flex'},              
                          flexDirection: { xs: 'column', sm: 'row' },
                          alignItems: { xs: 'flex-start', sm: 'center' },
                        }}>
                        Verification Code
                        <Button
                          onClick={handleIntemediateVerificationCode}
                          disabled={isAvailableToSendCode}
                          sx={{
                            textDecoration: 'underline',
                            color: colors.blueLabels,
                            textTransform: 'none',
                            fontWeight: 'bold',
                            justifyContent: 'flex-start',
                            fontSize: 17,
                            ml: {xs: 0, sm: 3},
                            mr: 3,
                            p: 0,
                            '&:hover': {
                              textDecoration: 'underline',
                              boxShadow: 'none',
                              backgroundColor: 'white',
                            },
                          }}>
                          Send code
                        </Button>
                        (For US mobile numbers only)
                      </InputLabel>
                      <Box
                        sx={{
                          p: '2px 4px',
                          display: 'flex',
                          alignItems: 'center',
                          minWidth: 100,
                          maxWidth: 290,
                          height: 50,
                          borderRadius: 0.6,
                          backgroundColor: colors.grayInputs,
                          border: '1px solid #dedede',
                        }}>
                        <InputBase
                          {...field}
                          {...register('verification_code', {
                            required: 'this field is required',
                          })}
                          id={'verification_code'}
                          placeholder={'Mobile Verification Code'}
                          name={'verification_code'}
                          data-testid='verification_code'
                          inputProps={{
                            'data-testid': `verification_code_update`,
                          }}
                          type={'number'}
                          sx={{
                            flex: 1,
                            p: 1,
                            fontSize: 15,
                            fontWeight: 'bold',
                            input: {
                              '&::placeholder': {
                                '-webkit-text-fill-color': `black !important`,
                                fontWeight: 'bold',
                              },
                            },
                          }}
                        />
                      </Box>
                      <ErrorMessage
                        errors={errors}
                        name={'verification_code'}
                      />
                    </Stack>
                  )}
                />
              )}
              {sameValueValidation?.show && (
                <span
                  className='text-red-600'
                  data-testid='differentValuesMessage'>
                  {sameValueValidation.message}
                </span>
              )}
              {extraChangePasswordForm && (
                <div className='mb-3'>
                  <FormControlLabel
                    control={
                      // TODO: pending add new functionality
                      // <Controller
                      //   name={'requireNewSign'}
                      //   control={control}
                      //   render={({ field }) => (
                      <Checkbox
                        // {...field}
                        // {...register('requireNewSign', {})}
                        inputProps={{
                          'aria-label':
                            'Require all devices to sign in with a new password',
                        }}
                      />
                      // )}
                      // />
                    }
                    label='Require all devices to sign in with a new password'
                  />
                  <ErrorMessage errors={errors} name={'requireNewSign'} />
                </div>
              )}

              {submitConfig?.map((item: any, index: any) => (
                <Button
                  disabled={item.disabled}
                  data-testid={item.dataTestID}
                  key={index}
                  type='submit'
                  sx={{
                    textDecoration: 'underline',
                    color: colors.blueLabels,
                    textTransform: 'none',
                    fontWeight: 'bold',
                    fontSize: 13,
                    ml: item.ml,
                    '&:hover': {
                      textDecoration: 'underline',
                      boxShadow: 'none',
                      backgroundColor: item.bgColor,
                    },
                  }}>
                  {item.labelName}
                </Button>
              ))}
            </div>
          </div>
        </div>
      </form>
      {viewModal.active && (
        <DrawerComponentUI
          anchor={'right'}
          viewRender={
            viewModal.name === 'allAchAccounts' ? (
              <DetailAllAchAccounts />
            ) : (
              <DetailAllPayeeNames />
            )
          }
          closeModal={setViewModal}
        />
      )}
    </>
  );
};

export default FormTemplate;

import { IPayeeNamesData, IPayeenamesResTransform } from '../../../interfaces/myAccount';

const getPayeeNamesTransform = (data: IPayeeNamesData): IPayeenamesResTransform => {
  const dataTranformed = data?.data;
  const metaTranform = {
    pagination: data?.pagination,
    status: data?.status,
    totals: data?.totals,
  };

  return { data: dataTranformed, meta: metaTranform };
};

export default getPayeeNamesTransform;

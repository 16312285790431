const getFilterSetTransform = (data: any): any => {
  const modifyData = data?.filters.map((filterSet: any) => {
    return {
      ...filterSet,
      filter_data: JSON.parse(filterSet.filter_data),
    };
  });

  const dataTranformed = data.status;

  return { data: modifyData, meta: dataTranformed };
};

export default getFilterSetTransform;

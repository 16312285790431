import tw, { styled } from 'twin.macro';
import { themeCustom } from '../../theme';

export const CardUpdate = styled.div`
  .sectionScroll {
    ${tw`h-80 md:h-3/6 lg:mr-6 xl:h-36`}
    scrollbar-width: thin;
    scrollbar-color: light;
    overflow: auto;
    direction: rtl;
    padding-left: 2rem;
  }

  .sectionScroll::-webkit-scrollbar {
    width: 8px;
  }

  .sectionScroll::-webkit-scrollbar-track {
    background-color: #f3f3f3; /* color of the tracking area */
  }

  .sectionScroll::-webkit-scrollbar-thumb {
    background-color: #d8d8d8;
    border-radius: 20px;
    border: 2px solid #f1f2f3;
  }

  .cardInternal {
    ${tw`p-4 rounded-3xl`}
    background-color: ${themeCustom.colors.dullGray};
    direction: ltr;
    margin-bottom: 1.5rem;

    span {
      font-weight: bold;
    }
  }
`;

export const Card = styled.div`
  .sectionScroll {
    ${tw`md:h-3/6 xl:h-72`}
    scrollbar-width: thin;
    scrollbar-color: light;
    overflow: auto;
    direction: rtl;
  }

  .sectionScroll::-webkit-scrollbar {
    width: 8px;
  }

  .sectionScroll::-webkit-scrollbar-track {
    background-color: #f3f3f3; /* color of the tracking area */
  }

  .sectionScroll::-webkit-scrollbar-thumb {
    background-color: #d8d8d8;
    border-radius: 20px;
    border: 2px solid #f1f2f3;
  }
`;

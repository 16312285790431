import { styled } from 'twin.macro';

export const ChipsRotate = styled.div`
  ${(props) =>
    (props.title === 'received' && 'background-color: #36da72') ||
    (props.title === 'partial received' && 'background-color: #8ce8ae') ||
    (props.title === 'not received' && 'background-color: #d3d3d3') ||
    (props.title === 'expired' && 'background-color: #ff6339')};

  transform: rotate(-90deg);
  position: absolute;
  margin-top: -1.3rem;
  left: -1.8rem;
  width: 7.5rem;
  z-index: 1;
  text-transform: capitalize;
  padding: 0.6rem 0.8rem 0.6rem 0.8rem;
  border-radius: 20px;
  color: #fff;
  font-weight: bold;
  text-align: center;
  font-size: 12px;
`;

import { useMediaQuery } from 'react-responsive';

const useResponsive = () => {
  const isMobile = useMediaQuery({
    query: '(max-width: 1199px)',
  });

  const isDesktopCustomTable = useMediaQuery({
    query: '(min-width: 1200px)',
  });

  const isScreenLaptop = useMediaQuery({
    query: '(min-width: 1300px)',
  });

  const isDefaultWidth = useMediaQuery({
    query: '(min-width: 1600px)',
  });

  const isUltraWidth = useMediaQuery({
    query: '(min-width: 1834px)',
  });

  const isQhd = useMediaQuery({
    query: '(min-width: 2560)',
  });

  return {
    isDesktopCustomTable,
    isScreenLaptop,
    isDefaultWidth,
    isUltraWidth,
    isMobile,
    isQhd,
  };
};

export default useResponsive;

import React from 'react';
import { Link } from 'react-router-dom';
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';

const BreadCrumbs = ({ links }: any) => {
  return (
    <>
      <div className='none-print'>
        <div className='flex'>
          <Link to='/' className='breadcrumb' />
          {links?.map((item: any, index: any) => (
            <div className='flex' key={index}>
              <Link
                to={item.route === '!#' ? '' : item.route}
                className={` text-[#b2b2b2] truncate w-20 ${
                  item.route === '!#'
                    ? 'pointer-events-none'
                    : 'underline whitespace-nowrap md:whitespace-normal'
                }`}>
                {item.name}
              </Link>
              <span
                className={` ${
                  links[index] == links[links.length - 1] ? 'hidden' : 'block'
                }`}>
                <ArrowForwardIosOutlinedIcon
                  sx={{ height: 15, width: 15, marginX: 2 }}
                />
              </span>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default BreadCrumbs;

import { styled } from 'twin.macro';

export const MobileTable = styled.div`
  @media screen and (max-width: 1200px) {
    .ant-table table {
      padding: 0;
    }

    .ant-table-thead > tr > th:first-child {
      padding-left: 1rem;
      border-radius: 0 !important;
    }

    .ant-table-thead > tr > th:last-child {
      border-radius: 0 !important;
    }

    .ant-table-column-sorter-inner {
      display: none;
    }
  }

  .ant-table-thead > tr > th:first-child {
    padding-left: 1rem;
  }

  .ant-table-tbody > tr > td {
    padding: 2rem;
  }
`;

import React from 'react';
import Sidebar from './components/Sidebar';
import Topbar from './components/Topbar';

const Main = (props) => {
  const { children } = props;

  return (
    <>
      <div className='lg:flex lg:flex-nowrap w-full justify-between'>
        <div className='lg:fixed lg:left-0 lg:mr-4 lg:pb-12 z-10'>
          <Sidebar />
        </div>
        <div className=' md:w-full'>
          <Topbar />
          <div className='w-full lg:w-auto xl3:pl-[16rem] xl2:pl-[13rem] px-3 md:px-2 xl2_1:pr-[2rem] lg:ml-60 xl2:ml-12 fixed lg:static overflow-y-auto h-screen lg:h-auto pb-52 lg:pb-0 min-[2024px]:pl-[18rem]'>
            {children}
          </div>
        </div>
      </div>
    </>
  );
};

export default Main;
/* eslint-disable no-undef */
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import * as Styles from './styles';
import { infoCards, breadCrumbOpenHelpTicket } from '../../../constants/index';
import ListTicketHelper from '../../../components/ListTicketHelper';
import CardHelper from '../../../components/cardHelper';
import BreadCrumbs from '../../../../../components/UI/breadCrumbs/index';
import { Select, MenuItem, Alert } from '@mui/material';
import usePostServiceFreshDesk from '../../../../../api/services/helpCenter/postServiceFreshDesk';
import useStatesGlobalStore from '../../../../../hooks/useStatesGlobalStore';
// import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import useGetTickets from '../../../../../api/services/helpCenter/getTickets';
import moment from 'moment';
import GeneralAlert from '../../../../../components/UI/generalAlert/GeneralAlert';
import { IAlertType } from '../../../../../interfaces/activeDeal/index';
import CardContent from '../../../../../components/UI/cardContent';
import { themeCustom } from '../../../../../theme';
import { Input } from 'antd';
import FileUploadIcon from '@mui/icons-material/FileUpload';

const OpenHelpTicket = () => {
  const { buyerInfo } = useStatesGlobalStore();
  const [ticketsUser, setTicketUser] = useState([]);
  const [openTicket, setOpenTicket] = useState(true);

  const { TextArea } = Input;

  // const modules = {
  //   toolbar: [
  //     [{ header: '1' }, { header: '2' }, { font: [] }],
  //     [{ size: [] }],
  //     ['bold', 'italic', 'underline', 'strike', 'blockquote'],
  //     [
  //       { list: 'ordered' },
  //       { list: 'bullet' },
  //       { indent: '-1' },
  //       { indent: '+1' },
  //     ],
  //     // ['link', 'image', 'video'],
  //     ['clean'],
  //   ],
  //   clipboard: {
  //     // toggle to add extra line breaks when pasting HTML:
  //     matchVisual: false,
  //   },
  // };

  const [infoTicket, setInfoTicket] = useState<any>({
    type: '',
    subject: '',
    description: '',
    email: buyerInfo.emailAddress,
    priority: 1,
    imageFile: [],
    status: 2,
    custom_fields: {
      cf_customerid: buyerInfo.buyerID,
    },
  });

  const [showAlert, setShowAlert] = useState<IAlertType>({
    message: '',
    show: false,
    title: '',
    type: 'success',
  });

  const { mutate } = usePostServiceFreshDesk();

  const { response } = useGetTickets(buyerInfo?.emailAddress);

  useEffect(() => {
    if (infoTicket.type === '') {
      setInfoTicket({ ...infoTicket, description: '' });
    }
  }, [infoTicket.type]);

  useEffect(() => {
    if (infoTicket.type === 'Feedback') {
      setInfoTicket({ ...infoTicket, priority: 1 });
      return;
    }
    if (
      infoTicket.type === 'General Questions' ||
      infoTicket.type === 'Missing Package'
    ) {
      setInfoTicket({ ...infoTicket, priority: 2 });
      return;
    }
    if (
      infoTicket.type === 'Return Label' ||
      infoTicket.type === 'Shipping Label'
    ) {
      setInfoTicket({ ...infoTicket, priority: 3 });
      return;
    }
    if (
      infoTicket.type === 'Non Committed Deals' ||
      infoTicket.type === 'Pricing Issue'
    ) {
      setInfoTicket({ ...infoTicket, priority: 4 });
      return;
    }
  }, [infoTicket.type]);

  useEffect(() => {
    if (!response?.errors) {
      console.log('estoy aqui', response);
      const rest = response?.filter(
        (ticket: any) =>
          ticket.custom_fields.cf_customerid === buyerInfo.buyerID,
      );

      setTicketUser(rest);
    }
  }, [response]);

  useEffect(() => {
    if (ticketsUser?.length > 0) {
      const rest = ticketsUser.filter((ticket: any) => ticket.status === 2);

      if (rest.length > 3) {
        setOpenTicket(false);
      } else {
        setOpenTicket(true);
      }
    }
  }, [ticketsUser]);

  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (
      infoTicket.type === '' ||
      infoTicket.subject === '' ||
      infoTicket.description === ''
    ) {
      return;
    }
    mutate(
      {
        ...infoTicket,
      },
      {
        onSuccess: () => {
          setInfoTicket({
            ...infoTicket,
            type: '',
            subject: '',
            priority: 1,
            description: '',
            imageFile: [],
          });
          setShowAlert({
            show: true,
            message: `Ticket created successfully, soon you will receive a response to your email.`,
            title: 'Success',
            type: 'success',
          });
        },
      },
    );
  };

  return (
    <>
      {showAlert.show && (
        <GeneralAlert
          alertType={showAlert.type}
          alertMessage={showAlert.message}
          alertTitle={showAlert.title}
          setShowAlert={setShowAlert}
          fontSize={17}
        />
      )}
      <div className='flex justify-between mt-5 mb-10'>
        <div className='mt-5 flex gap-4 text-gray-400'>
          <BreadCrumbs links={breadCrumbOpenHelpTicket} />
        </div>
        <div className='flex gap-4'></div>
      </div>
      <div className='xl:flex bg-white rounded-3xl w-full xl:p-6 2xl:p-10 hidden gap-6'>
        <div className='block xl:w-1/3 2xl:w-[27rem]'>
          <span className='fontSharpGrotesk22Medium text-4xl'>
            Your Help Tickets
          </span>
          <p className='2xl:w-[19rem] leading-6 my-10 text-lg'>
            Keep track of your help tickets, review responses, and create new
            ones.
          </p>
          <Styles.Card className='rounded-3xl bg-gray-100 xl:p-6 2xl:p-10'>
            <span className='font-semibold ml-[2rem]'>
              Previous Help Tickets
            </span>
            <div className='sectionScroll mt-20 overflow-y-scroll h-[23rem]'>
              {ticketsUser?.map((ticket) => (
                <ListTicketHelper ticket={ticket} />
              ))}
            </div>
            <div className='underline text-gray-400 px-2 pt-16 mt-12'>
              <Link to='/check-ticket-status'>View all help tickets</Link>
            </div>
          </Styles.Card>
        </div>
        <div className='border-gray-200 border-2 xl:p-8 2xl:p-10 rounded-3xl 2xl:ml-14 xl:w-2/3 2xl:w-[51rem]'>
          <div className='flex justify-between'>
            <span className='fontSharpGrotesk22Medium text-xl'>
              New Help Ticket
            </span>
            <span className='text-gray-400'>
              {moment(new Date()).format('MMM DD, YYYY')}
            </span>
          </div>
          <hr className='mt-5 mb-10' />
          <form action='' className='flex flex-wrap justify-center'>
            {!openTicket && (
              <Alert className='w-full' severity='error'>
                You have reached the maximum number of open tickets, you must
                wait for them to be resolved.
              </Alert>
            )}
            <div className='flex xl:gap-4 2xl:gap-8'>
              <div className=''>
                <p className='text-gray-300 font-semibold mt-5'>Topic</p>
                <Select
                  value={infoTicket.type}
                  className='bg-gray-100 w-[18rem] tex-black'
                  onChange={(e) =>
                    setInfoTicket({ ...infoTicket, type: e.target.value })
                  }>
                  <MenuItem value='1' disabled selected>
                    <em>Select One</em>
                  </MenuItem>
                  <MenuItem value='General Questions'>
                    General Questions
                  </MenuItem>
                  <MenuItem value='Missing Package'>Missing Package</MenuItem>
                  <MenuItem value='Non Committed Deals'>
                    Non Committed Deals
                  </MenuItem>
                  <MenuItem value='Pricing Issue'>Pricing Issue</MenuItem>
                  <MenuItem value='Return Label'>Return Label</MenuItem>
                  <MenuItem value='Shipping Label'>Shipping Label</MenuItem>
                  <MenuItem value='Feedback'>Feedback</MenuItem>
                </Select>
              </div>
              <div>
                <p className='text-gray-300 font-semibold mt-5'>
                  Subject (Describe issue in 2-5 words)*
                </p>
                <input
                  value={infoTicket.subject}
                  type='text'
                  name='subject'
                  className='bg-gray-100 p-4 w-[18rem]'
                  onChange={(e) =>
                    setInfoTicket({ ...infoTicket, subject: e.target.value })
                  }
                />
              </div>
            </div>
            <div className='mt-7 mx-10 w-full'>
              <p className='text-gray-300 font-semibold'>Description*</p>
              {/* <div className='w-[38rem]'>
                <ReactQuill
                  value={infoTicket.description}
                  theme='snow'
                  modules={modules}
                  style={{ height: '200px' }}
                  onChange={(e: any) =>
                    setInfoTicket({ ...infoTicket, description: e })
                  }
                />
              </div> */}
              <TextArea
                value={infoTicket.description}
                className='flex w-full'
                rows={10}
                onChange={(e) =>
                  setInfoTicket({ ...infoTicket, description: e.target.value })
                }
              />

              <div className='flex w-full justify-center mt-5 flex-col items-center'>
                <p className='text-gray-300 font-bold text-base'>File</p>
                <label htmlFor='file-input-open'>
                  {/* <img src="ruta-del-icono.png" alt="Icono" /> */}
                  <FileUploadIcon
                    sx={{ fontSize: '4rem' }}
                    className='text-gray-300 cursor-pointer'
                  />
                </label>
                <input
                  className='hidden'
                  id='file-input-open'
                  type='file'
                  name=''
                  onChange={(e: any) => {
                    setInfoTicket({
                      ...infoTicket,
                      imageFile: e.target.files[0],
                    });
                  }}
                />
                <p>{infoTicket.imageFile.name}</p>
              </div>
            </div>

            <div className='mt-20 w-full flex justify-center'>
              <button
                disabled={
                  !openTicket ||
                  infoTicket.type === '' ||
                  infoTicket.subject === '' ||
                  infoTicket.description === ''
                }
                className='w-full 2xl:w-[38rem] border-2 border-blue-300 hover:border-blue-500 rounded-xl p-5 text-blue-500 font-semibold hover:bg-blue-500 hover:text-white disabled:bg-slate-300 disabled:opacity-75'
                onClick={handleSubmit}>
                Send
              </button>
            </div>
          </form>
        </div>
      </div>
      <div>
        <h3 className='fontSharpGrotesk22Medium mb-10 text-3xl xl:hidden'>
          Submit a Ticket
        </h3>
        <CardContent
          styles={
            'lg:w-full mt-10 xl:w-8/12 xl:ml-8 xl:h-5/6 rounded-3xl p-10 p-3 xl:hidden '
          }>
          <form action=''>
            <div className='flex justify-between'>
              <p className='font-bold'>New Help Ticket</p>
              <span className='text-gray-400 font-bold'>
                {moment(new Date()).format('MMM DD, YYYY')}
              </span>
            </div>
            <hr />
            {!openTicket && (
              <Alert className='w-full' severity='error'>
                You have reached the maximum number of open tickets, you must
                wait for them to be resolved.
              </Alert>
            )}
            <div>
              <p className='text-gray-300 font-semibold mt-5'>Topic</p>
              <Select
                value={infoTicket.type}
                className='bg-gray-100 w-[18rem] tex-black'
                onChange={(e) =>
                  setInfoTicket({ ...infoTicket, type: e.target.value })
                }>
                <MenuItem value='1' disabled selected>
                  <em>Select One</em>
                </MenuItem>
                <MenuItem value='General Questions'>General Questions</MenuItem>
                <MenuItem value='Missing Package'>Missing Package</MenuItem>
                <MenuItem value='Non Committed Deals'>
                  Non Committed Deals
                </MenuItem>
                <MenuItem value='Pricing Issue'>Pricing Issue</MenuItem>
                <MenuItem value='Return Label'>Return Label</MenuItem>
                <MenuItem value='Shipping Label'>Shipping Label</MenuItem>
                <MenuItem value='Feedback'>Feedback</MenuItem>
              </Select>
            </div>
            <div>
              <p className='text-gray-300 font-semibold mt-5'>
                Subject (Describe issue in 2-5 words)*
              </p>
              <input
                value={infoTicket.subject}
                type='text'
                name='subject'
                className='bg-gray-100 p-4 w-[18rem]'
                onChange={(e) =>
                  setInfoTicket({ ...infoTicket, subject: e.target.value })
                }
              />
            </div>
            <div className='mt-7'>
              <p className='text-gray-300 font-semibold'>Description*</p>
              <div className='w-full'>
                {/* <ReactQuill
                  value={infoTicket.description}
                  theme='snow'
                  modules={modules}
                  style={{ height: '200px' }}
                  onChange={(e: any) =>
                    setInfoTicket({ ...infoTicket, description: e })
                  }
                /> */}

                <TextArea
                  value={infoTicket.description}
                  className='flex w-full'
                  rows={10}
                  onChange={(e) =>
                    setInfoTicket({
                      ...infoTicket,
                      description: e.target.value,
                    })
                  }
                />

                <div className='flex w-full justify-center mt-5'>
                  <input
                    type='file'
                    name=''
                    onChange={(e: any) => {
                      setInfoTicket({
                        ...infoTicket,
                        imageFile: e.target.files[0],
                      });
                    }}
                  />
                </div>
              </div>
            </div>
            <div className='mt-20'>
              <button
                disabled={
                  !openTicket ||
                  infoTicket.type === '' ||
                  infoTicket.subject === '' ||
                  infoTicket.description === ''
                }
                className='w-full mt-8 border-2 border-blue-500 rounded-xl p-5 font-semibold bg-blue-500 text-white disabled:bg-slate-300 disabled:opacity-75'
                onClick={handleSubmit}>
                Send
              </button>
            </div>
          </form>
        </CardContent>
      </div>
      <div className='bg-white w-full rounded-3xl px-16 py-8 mt-10 hidden lg:block'>
        <h3 className='fontSharpGrotesk22Medium mb-10 text-lg'>
          What do you need help with?
        </h3>
        <div className='flex flex-wrap justify-center gap-8 mb-20 '>
          {infoCards.map((card, index) => (
            <CardHelper
              key={index}
              route={card.route}
              style={'w-[18rem] py-2'}
              title={card.title}
              disabled={card.disabled}
            />
          ))}
        </div>
      </div>
      <div className='mt-5 lg:hidden'>
        {infoCards.map((card: any, index: number) => (
          <Link to={card.route} data-testid={card.title} key={index}>
            <div
              className={`flex mb-4 p-4 items-center rounded-lg w-full justify-between`}
              style={{
                backgroundColor: card.disabled
                  ? themeCustom.colors.grayLabels
                  : themeCustom.colors.lightBlue,
              }}>
              <div className='flex'>
                <p className='mr-8 ml-2 mb-0'>{card.icon}</p>
                <p className='mb-0'>{card.title}</p>
              </div>
              <img src='images/dashboard/arrow.png' alt='arrow' />
            </div>
          </Link>
        ))}
      </div>
    </>
  );
};

export default OpenHelpTicket;

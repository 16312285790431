import { useMutation } from 'react-query';
import updateChangeImageFetch from '../../endPoints/myAccount/putChangeImage';

const useUpdateChangeImage = () => {
  const { mutate } = useMutation(updateChangeImageFetch);

  return { updateImage: mutate };
};

export default useUpdateChangeImage;

import React from 'react';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import SwipeRightIcon from '@mui/icons-material/SwipeRight';
import ScreenRotationIcon from '@mui/icons-material/ScreenRotation';
import globalStoreNoLocalStore from '../../hooks/globalStoreNoLocalStore';

const AlertSliceMobile = () => {
  const {
    alertSliceMobile,
    alertTurnScreenMobile,
    clearAlertSliceMobileForUser,
    clearAlertTurnScreenMobileForUser,
  } = globalStoreNoLocalStore();

  return (
    <div className='lg:hidden'>
      {alertSliceMobile.view && alertSliceMobile.forUser !== 'user' && (
        <div className='absolute bottom-[12.5rem] bg-[#1890ff] w-[22rem] z-50 mx-4 px-6 py-5 rounded-lg flex justify-between items-center text-white font-bold text-[0.9rem]'>
          <SwipeRightIcon fontSize='large' />
          <p className='m-0'>Swipe to view spreadsheet</p>
          <HighlightOffIcon
            fontSize='large'
            onClick={clearAlertSliceMobileForUser}
          />
        </div>
      )}

      {alertTurnScreenMobile.view && alertTurnScreenMobile.forUser !== 'user' && (
        <div className='absolute bottom-[7rem] bg-[#bedfff] w-[22rem] z-50 mx-4 px-6 py-5 rounded-lg flex justify-between items-center font-bold text-[0.9rem]'>
          <ScreenRotationIcon fontSize='large' />
          <p className='m-0'>Turn screen for more space</p>
          <HighlightOffIcon
            fontSize='large'
            onClick={clearAlertTurnScreenMobileForUser}
          />
        </div>
      )}
    </div>
  );
};

export default AlertSliceMobile;

/* eslint-disable no-undef */
import React, { useState, useEffect } from 'react';
// import { Checkbox } from 'antd';
import { usePostDowload } from '../../../api/services/purchaseOrders/usePostDowload';
import GeneralAlert from '../../../components/UI/generalAlert/GeneralAlert';
import { IAlertType } from '../../../interfaces/activeDeal';

const FormExportPurchase = ({ list }: any) => {
  const { mutate } = usePostDowload();
  const [infoRequestExport, setInfoRequestExport] = useState({
    ids: '',
    module: 'buyers',
    submodule: 'pos',
  });

  const [showAlert, setShowAlert] = useState<IAlertType>({
    message: '',
    show: false,
    title: '',
    type: 'success',
  });

  useEffect(() => {
    const idsData = () => {
      const listTest: any[] = [];

      list.map((item: any) => {
        listTest.push(item.po_number);
      });
      setInfoRequestExport({
        ...infoRequestExport,
        ids: listTest.toString(),
      });
    };

    idsData();
  }, [list]);

  const onSubmit = (format: any) => {
    if (infoRequestExport.ids === '') {
      setShowAlert({
        show: true,
        message: 'Ids id is required',
        title: 'Error',
        type: 'warning',
      });
    }

    try {
      mutate(
        {
          ...infoRequestExport,
          format,
        },
        {
          onSuccess: (data) => {
            if (data?.download) {
              window.location.href = `${
                process.env.REACT_APP_BACKEND_URL
              }resources/${encodeURIComponent(data?.download.code)}`;
              setShowAlert({
                show: true,
                message: data?.error
                  ? data?.error
                  : 'Report downloaded successfully',
                title: data?.error ? 'Error' : 'Success',
                type: data?.error ? 'warning' : 'success',
              });
            }
          },
          onError: (error) => {
            console.log(error);
          },
        },
      );
    } catch (error) {
      console.log('catch', error);
    }
  };

  return (
    <>
      {showAlert.show && (
        <GeneralAlert
          alertType={showAlert.type}
          alertMessage={showAlert.message}
          alertTitle={showAlert.title}
          setShowAlert={setShowAlert}
          fontSize={17}
        />
      )}
      <div className='w-60'>
        <p className='font-semibold'>Options</p>
        {/* <div>
          <Checkbox>
            <label className='ml-2'>Include product data</label>
          </Checkbox>
          <Checkbox>
            <label className='ml-2'>Export current view</label>
          </Checkbox>
        </div> */}
        <hr className='border-t-2 border-gray-300 px-2 my-5' />
        <div className='mt-6'>
          <p className='text-slate-400'>Download as</p>
        </div>
        <div className='mt-6'>
          {/* <h6
            className='cursor-pointer hover:bg-slate-300 p-2 rounded-lg'
            >
            Microsoft Excel(.xls)
          </h6> */}
          <h6
            className='cursor-pointer hover:bg-slate-300 p-2 rounded-lg'
            onClick={() => onSubmit('csv')}>
            Comma Separated Values(.csv)
          </h6>
          <h6
            className='cursor-pointer hover:bg-slate-300 p-2 rounded-lg'
            onClick={() => onSubmit('pdf')}>
            PDF(.pdf)
          </h6>
        </div>
      </div>
    </>
  );
};

export default FormExportPurchase;

import {
  IDetailsPurchase,
  IDataInternal,
} from '../../../interfaces/purchaseOrders/details';

export interface IServiceTransform {
  data: IDataInternal;
  meta: {
    status: number;
  };
}

const transformDataDetailsPurchase = (
  data: IDetailsPurchase,
): IServiceTransform => {
  const dataTranformed = data.data;
  const metaTranform = {
    status: data.status,
  };

  return { data: dataTranformed, meta: metaTranform };
};

export default transformDataDetailsPurchase;

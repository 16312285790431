import React from 'react';
// import { SearchOutlined } from '@ant-design/icons';
import CardLatestUpdateHelper from '../../../components/cardLatestUpdateHelper';
import BreadCrumbs from '../../../../../components/UI/breadCrumbs/index';
import { infoCards, breadCrumbLatestUpdate } from '../../../constants/index';
import CardHelper from '../../../components/cardHelper';
import useGetUpdatesInfo from '../../../../../api/services/helpCenter/getUpdatesInfo';
import ErrorIcon from '@mui/icons-material/Error';
import parse from 'html-react-parser';

const LatestUpdates = () => {
  const { response } = useGetUpdatesInfo({
    display_at_from: '',
    display_at_until: '',
  });

  return (
    <>
      <div className='flex justify-between mt-5 mb-10'>
        <div className='mt-5 flex gap-4 text-gray-400'>
          <BreadCrumbs links={breadCrumbLatestUpdate} />
        </div>

        {/* <div className='flex gap-4'>
          <SearchOutlined
            style={{
              position: 'absolute',
              marginTop: '28px',
              height: '20px',
              marginLeft: '25%',
            }}
          />

          <span className='text-sm m-auto fontSharpGrotesk22Medium'>
            search for anything
          </span>
          <input
            type='text'
            placeholder='type here...'
            className='rounded-3xl p-6 font-semibold text-black w-[22rem]'
          />
        </div> */}
      </div>
      <div className='md:flex md:flex-wrap md:gap-32 bg-white w-full rounded-3xl py-20  px-1 md:px-10 '>
        <div className='card-nav px-3'>
          <span className='fontSharpGrotesk22Medium text-3xl'>
            Latest Updates
          </span>
        </div>
        <div className='active_deals lg:w-4/6 overflow-y-auto'>
          <span className='fontSharpGrotesk22Medium p-5 text-base text-blue-500'>
            All Updates
          </span>
          <hr className='my-8' />
          {/* <div className='my-10'>
            <span className='text-gray-400'>July 2022</span>
          </div> */}
          {response && response.data.length > 0 ? (
            <>
              {response.data.map((update, index) => (
                <CardLatestUpdateHelper
                  key={index}
                  description={parse(update.description)}
                  date={update.display_at}
                />
              ))}
            </>
          ) : (
            <div className='ml-8 flex items-center'>
              <ErrorIcon sx={{ fontSize: '1.5rem' }} />
              <p className='text-xl font-semibold mb-0'>
                No recent updates has been posted.
              </p>
            </div>
          )}
          {/* <hr className='my-8' />
          <span className='text-gray-400'>June 2022</span>
          {latestUpdatesJune.map((update, index) => (
            <CardLatestUpdateHelper
              key={index}
              description={update.description}
              date={update.date}
            />
          ))} */}

          {/* <div className='mt-10'>
            <span className='text-gray-400 p-5 underline'>
              Load earlier updates
            </span>
          </div> */}
        </div>
      </div>
      <div className='bg-white w-full rounded-3xl px-16 py-8 mt-10'>
        <h3 className='fontSharpGrotesk22Medium mb-10 text-lg'>
          What do you need help with?
        </h3>
        <div className='flex flex-wrap justify-center gap-8 mb-20'>
          {infoCards.map((card, index) => (
            <CardHelper
              key={index}
              route={card.route}
              style={'w-[18rem] py-2'}
              title={card.title}
            />
          ))}
        </div>
      </div>
    </>
  );
};

export default LatestUpdates;

import { useMutation } from 'react-query';
import postFetch from '../../endPoints/myAccount/addAchAccount';

const useAddAchAccount = () => {
  const { mutate } = useMutation(postFetch);

  return { achAccountMutate: mutate };
};

export default useAddAchAccount;

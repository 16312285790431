import React from 'react';
import { Link } from 'react-router-dom';
import CardContent from '../../../components/UI/cardContent';
import { themeCustom } from '../../../theme';

export const IndexMobile = ({ infoCards }: any) => {
  return (
    <div>
      {infoCards.map((card: any, index: number) => (
        <Link to={card.route} data-testid={card.title} key={index}>
          <div
            className={`flex mb-4 p-4 items-center rounded-lg w-full justify-between`}
            style={{
              backgroundColor: card.disabled
                ? themeCustom.colors.grayLabels
                : themeCustom.colors.lightBlue,
            }}>
            <div className='flex'>
              <p className='mr-8 ml-2 mb-0'>{card.icon}</p>
              <p className='mb-0'>{card.title}</p>
            </div>
            <img src='images/dashboard/arrow.png' alt='arrow' />
          </div>
        </Link>
      ))}

      <div>
        <CardContent
          styles={
            'lg:w-full mt-10 xl:w-8/12 xl:ml-8 xl:h-5/6 rounded-3xl p-10 p-3 md:block '
          }>
          <div className='flex gap-6'>
            <img
              src='images/helpCenter/quickStartMobile.svg'
              alt='quickStartMobile'
            />
            <div>
              <h3 className='text-[1.5rem] fontSharpGrotesk22Medium'>
                Quick Start Guide
              </h3>
              <p className='fontGTAmericaRegular'>
                Welcome to the new USA Buying portal: intuitive, beautiful, easy
                to get around. Our Quick Start Guide will show you all the
                essentials!
              </p>
              <p className='text-gray-400 underline'>Show me around</p>
            </div>
          </div>
        </CardContent>
      </div>
    </div>
  );
};

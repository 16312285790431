import React from 'react';
import CardContent from '../../../components/UI/cardContent';
import * as Styles from '../style';
import ErrorIcon from '@mui/icons-material/Error';
import parse from 'html-react-parser';

const CardUpdates = ({ data }: any) => {
  return (
    <CardContent styles={'w-full xl:w-2/4 rounded-3xl p-6'}>
      <Styles.CardUpdate>
        <p className=' fontSharpGrotesk22Medium md:text-xl md:ml-10 mb-8 mt-2'>
          Updates
        </p>
        {data && data.length > 0 ? (
          <div className='w-full flex'>
            <div className='sectionScroll w-11/12'>
              <>
                {data.map((item: any, index: number) => {
                  return (
                    <div key={index} className='cardInternal'>
                      <p className=''>{parse(item.description)}</p>
                    </div>
                  );
                })}
              </>
            </div>
          </div>
        ) : (
          <div className='ml-8 flex items-center'>
            <ErrorIcon sx={{ fontSize: '1.5rem' }} />
            <p className='text-xl font-semibold mb-0'>
              No recent updates has been posted.
            </p>
          </div>
        )}
      </Styles.CardUpdate>
    </CardContent>
  );
};

export default CardUpdates;

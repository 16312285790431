import { Button, Box } from '@mui/material';
import { themeCustom } from '../../../theme';
import React, { useState } from 'react';
import { DrawerComponentUI } from '../sideSheetNew';
import Filter from '../filter';
import { DatePicker, Popover } from 'antd';
// import useStatesGlobalStore from '../../../hooks/useStatesGlobalStore';
// import Filter from './containers/Filter';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import { IAlertType } from '../../../interfaces/activeDeal/index';
import GeneralAlert from '../generalAlert/GeneralAlert';
import { useQueryClient } from 'react-query';

const MobileFilter = ({
  clearFilters,
  filterOptions,
  formStyles,
  isSaveFilterVisible,
  setFilterOptions,
  selectFilters,
  rangeDateFilter,
  showFilterTitle,
  searchFilter,
  simpleSelectFilters,
  stylesFilterSectionSearchAndDate,
  rangeDateFilterMobile,
  saveModule,
  mutate,
  mySet,
}: any) => {
  const { colors } = themeCustom;
  const [viewModal, setViewModal] = useState({
    activeSimpleSelectMySet: false,
    activeSearch: false,
    active: false,
    name: '',
  });

  const [openSetsFilters, setOpenSetsFilters] = React.useState(false);

  // const { filterSetsSave, setFilterSetsSave } = useStatesGlobalStore();

  const buildSaveFilterForm = () => {
    const [nameSets, setNameSets] = React.useState({
      value: '',
      labelName: '',
    });

    const [showAlert, setShowAlert] = React.useState<IAlertType>({
      message: '',
      show: false,
      title: '',
      type: 'success',
    });

    const filter_data = {
      ...filterOptions,
      filterSets: nameSets.value,
    };

    const queryClient = useQueryClient();

    return (
      <>
        {showAlert.show && (
          <GeneralAlert
            alertType={showAlert.type}
            alertMessage={showAlert.message}
            alertTitle={showAlert.title}
            setShowAlert={setShowAlert}
            fontSize={17}
          />
        )}
        <p
          className='text-right text-xs opacity-40 font-bold'
          onClick={() => setOpenSetsFilters(false)}>
          cancel
        </p>
        <form action='' className='flex flex-wrap justify-center gap-4'>
          <input
            type='text'
            required
            name='nameFilter'
            className='w-full p-2 border-black border-2 rounded-xl'
            placeholder='Filter Name'
            onChange={(e) =>
              setNameSets({ value: e.target.value, labelName: e.target.value })
            }
          />
          <button
            className='bg-blue-500 text-white w-full p-2 rounded-xl'
            onClick={(e) => {
              e.preventDefault();
              mutate(
                {
                  module_name: saveModule,
                  filter_data: JSON.stringify(filter_data),
                  filter_name: nameSets.labelName,
                },

                {
                  onSuccess: () => {
                    setShowAlert({
                      show: true,
                      message: 'New filter set created',
                      title: 'Success',
                      type: 'success',
                    });
                    queryClient.invalidateQueries('GET_FILTERS_SET');
                  },
                  onError: (error: any) => {
                    console.log(error);
                  },
                },
              );
              setOpenSetsFilters(false);
            }}>
            Save
          </button>
        </form>
      </>
    );
  };

  const dateFormat = 'DD/MM/YY';
  const { RangePicker } = DatePicker;

  const handleChangeDate = (dateString: any) => {
    setFilterOptions({
      ...filterOptions,
      ['date_from']:
        dateString == null ? '' : dateString[0].toISOString().split('T')[0],
      ['date_until']:
        dateString == null ? '' : dateString[1].toISOString().split('T')[0],
    });
  };

  return (
    <div>
      <div className='flex mb-4'>
        <Button
          data-testid={'mobileFilterButton'}
          onClick={() => {
            setViewModal({
              active: true,
              name: '',
              activeSearch: false,
              activeSimpleSelectMySet: false,
            });
          }}
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            borderRadius: 3,
            bgcolor: colors.white,
            height: '2.5rem',
            width: '33%',
            color: '#4da4fc',
            textTransform: 'none',
            padding: '6px 12px',
            '&:hover': {
              backgroundColor: '#0069d9',
              borderColor: '#0062cc',
              boxShadow: 'none',
              color: colors.white,
            },
          }}>
          <span>Filters</span>
          <img
            className='h-2 w-2'
            src='images/dashboard/blueArrow.png'
            alt='cart icon'
            width='67'
            height='67'
          />
        </Button>
        {rangeDateFilterMobile && (
          <Box
            className='w-[12rem] rounded-lg mx-4 bg-white shadow-lg mb-4'
            sx={{ display: 'flex' }}>
            <picture className=' flex items-center'>
              <img
                className='p-2 mx-2 w-9 h-10'
                src='images/dateFilterRangeMobile.svg'
                alt='calendar icon'
                width='19'
                height='21'
              />
            </picture>
            <RangePicker
              data-testid='filterDateMobile'
              className='w-full rounded-lg fontGTAmericanR'
              format={dateFormat}
              onChange={handleChangeDate}
              placeholder={['Across all time', '']}
            />
          </Box>
        )}

        {simpleSelectFilters && mySet && (
          <>
            <Button
              data-testid={'mobileFilterButton'}
              onClick={() => {
                setViewModal({
                  active: false,
                  name: '',
                  activeSearch: false,
                  activeSimpleSelectMySet: true,
                });
              }}
              sx={{
                marginLeft: '1rem',
                display: 'flex',
                justifyContent: 'space-between',
                borderRadius: 3,
                bgcolor: colors.white,
                height: '2.5rem',
                width: '40%',
                color: '#4da4fc',
                textTransform: 'none',
                padding: '6px 12px',
                '&:hover': {
                  backgroundColor: '#0069d9',
                  borderColor: '#0062cc',
                  boxShadow: 'none',
                  color: colors.white,
                },
              }}>
              <span>My Filter Sets</span>
              <img
                className='h-2 w-2'
                src='images/dashboard/blueArrow.png'
                alt='cart icon'
                width='67'
                height='67'
              />
            </Button>

            {viewModal.activeSimpleSelectMySet && (
              <DrawerComponentUI
                anchor={'bottom'}
                modalBorderTopRightRadius={'30px'}
                viewRender={
                  <Filter
                    clearFilters={clearFilters}
                    filterOptions={filterOptions}
                    formStyles={formStyles}
                    setFilterOptions={setFilterOptions}
                    setViewModal={setViewModal}
                    showFilterTitle={showFilterTitle}
                    simpleSelectFilters={simpleSelectFilters}
                  />
                }
                closeModal={setViewModal}
                width={'100%'}
              />
            )}
          </>
        )}

        {searchFilter && (
          <>
            <div
              className=' bg-white w-14 rounded-xl h-10'
              onClick={() =>
                setViewModal({
                  active: false,
                  activeSearch: true,
                  name: '',
                  activeSimpleSelectMySet: false,
                })
              }>
              <img
                className='mt-2 ml-4'
                src='/images/dashboard/search.png'
                alt='search icon'
                width='21'
                height='22'
              />
            </div>
            {viewModal.activeSearch && (
              <DrawerComponentUI
                anchor={'bottom'}
                modalBorderTopRightRadius={'30px'}
                viewRender={
                  <Filter
                    clearFilters={clearFilters}
                    filterOptions={filterOptions}
                    formStyles={formStyles}
                    setFilterOptions={setFilterOptions}
                    setViewModal={setViewModal}
                    showFilterTitle={showFilterTitle}
                    searchFilters={searchFilter}
                    stylesFilterSectionSearchAndDate={
                      stylesFilterSectionSearchAndDate
                    }
                  />
                }
                closeModal={setViewModal}
                width={'100%'}
              />
            )}
          </>
        )}
      </div>
      {viewModal.active && (
        <DrawerComponentUI
          anchor={'bottom'}
          modalBorderTopRightRadius={'30px'}
          viewRender={
            <Filter
              clearFilters={clearFilters}
              filterOptions={filterOptions}
              formStyles={formStyles}
              // isSaveFilterVisible={isSaveFilterVisible}
              rangeDateFilter={rangeDateFilter}
              selectFilters={selectFilters}
              setFilterOptions={setFilterOptions}
              setViewModal={setViewModal}
              showFilterTitle={showFilterTitle}
              simpleSelectFilters={!mySet && simpleSelectFilters}
              stylesFilterSectionSearchAndDate={
                stylesFilterSectionSearchAndDate
              }
            />
          }
          closeModal={setViewModal}
          width={'100%'}
        />
      )}

      {isSaveFilterVisible && (
        <div className='flex justify-self-end mr-11'>
          <Popover
            onVisibleChange={() => setOpenSetsFilters(true)}
            visible={openSetsFilters}
            placement='bottom'
            content={buildSaveFilterForm}
            trigger='click'>
            <span className='cursor-pointer underline flex gap-3 text-sm mb-1 text-gray-400'>
              <BookmarkBorderIcon />
              Save This Filter Set
            </span>
          </Popover>
        </div>
      )}
    </div>
  );
};

export default MobileFilter;

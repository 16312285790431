import { useQuery } from 'react-query';
import fetchGetIndividualTickets from '../../endPoints/helpCenter/getIndividualTicket';

export interface IResponseService {
  response: any;
  error: any;
  isLoading: boolean;
}

const useGetIndividualTickets = (params: any): IResponseService => {
  const { error, data, isLoading } = useQuery(
    ['GET_INDIVIDUAL_TICKETS', params],
    () => {
      return fetchGetIndividualTickets(params);
    },
  );

  return { error, response: data, isLoading };
};

export default useGetIndividualTickets;

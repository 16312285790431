import {
  IAuthorizedActionsData,
  IAuthorizedActionsResTransform,
} from '../../../interfaces/myAccount';

const achAccountsAuthorizedActionsTransform = (
  data: IAuthorizedActionsData,
): IAuthorizedActionsResTransform => {
  const dataTransformedAddActions = {
    allowed: data?.actions[0]?.allowed,
    details: data?.actions[0]?.details,
    name: data?.actions[0]?.name,
  };
  const dataTransformedEditActions = {
    allowed: data?.actions[1]?.allowed,
    details: data?.actions[1]?.details,
    name: data?.actions[1]?.name,
  };

  return {
    addActions: dataTransformedAddActions,
    editActions: dataTransformedEditActions,
    status: data?.status,
  };
};

export default achAccountsAuthorizedActionsTransform;

/* eslint-disable no-undef */
import cookie from 'react-cookies';

const fetchApiGet = async (params: any) => {
  const paramsPrice = params.price.map((opt: any) => {
    const option = `&range_price[]=${opt}`;

    return option;
  });

  const paramsType = params.payment_type.map((opt: any) => {
    const option = `&payment_type[]=${opt}`;

    return option;
  });

  const response = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}buyers/checks-requests?date_range=${
      params.date_range
    }${paramsPrice.join('&')}${paramsType.join('&')}&search_payments=${
      params.payee_name
    }&page_number=${params.start}&limit=${params.limit}`,
    {
      headers: {
        Authorization: `Bearer ${cookie.load('token')}`,
      },
    },
  ).then((fetchResponse) => fetchResponse.json());

  return response;
};

export default fetchApiGet;

import React from 'react';
import { themeCustom } from '../../../theme';
import { handleGeneralCopyClick } from '../../../helpers';
import { Box, Button, InputBase } from '@mui/material';
import CardContent from '../../../components/UI/cardContent';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import useStatesGlobalStore from '../../../hooks/useStatesGlobalStore';
import useAdditionalIds from '../../../api/services/myAccount/postAdditionalIds';

const BuyingGroupIDCard = ({ setShowAlert }: any) => {
  const { colors } = themeCustom;

  const { buyerInfo, setBuyerInfo } = useStatesGlobalStore();
  const { mutate } = useAdditionalIds();

  const handleCopyClick = (text: string) => {
    handleGeneralCopyClick(
      text,
      {
        show: true,
        message: text,
        title: 'Text Copied!',
        type: 'info',
      },
      {
        show: true,
        title: 'Coping Error',
        type: 'error',
      },
      setShowAlert,
    );
  };

  const generateAditionalsIds = () => {
    mutate(
      {
        subvendor_id: buyerInfo.buyerInternalId,
      },
      {
        onSuccess: (data) => {
          if (data?.status) {
            setBuyerInfo({
              ...buyerInfo,
              additionalIds: [
                ...buyerInfo.additionalIds,
                data.data.subvendor_buyer_id.toString(),
              ],
            });

            setShowAlert({
              show: true,
              message: data?.error ? data?.error : 'Ids Generated Successfully',
              title: data?.error ? 'Error' : 'Success',
              type: data?.error ? 'warning' : 'success',
            });
          }

          setTimeout(() => {
            setShowAlert({
              show: false,
            });
          }, 2000);
        },
        onError: (error) => {
          console.log(error);
        },
      },
    );
  };

  return (
    <CardContent styles={'p-12 lg:pr-44 lg:rounded-3xl'}>
      <h2 className='fontSharpGrotesk22Medium text-base'>
        Your USA Buying Group Main ID
      </h2>
      <div className='flex mt-12 mb-10'>
        <Box
          sx={{
            p: '2px 4px',
            display: 'flex',
            alignItems: 'center',
            width: 160,
            height: 60,
            borderRadius: 0.6,
            backgroundColor: colors.grayInputs,
            border: '1px solid #dedede',
          }}>
          <InputBase
            inputProps={{
              'aria-label': `buyerId`,
            }}
            sx={{
              flex: 1,
              p: 2,
              fontSize: 25,
              input: {
                '&::placeholder': {
                  '-webkit-text-fill-color': `black !important`,
                  fontWeight: 'bold',
                },
              },
            }}
            value={buyerInfo.buyerID}
          />
        </Box>
        <div className='underline text-gray-400 mt-3'>
          <Button
            data-testid='copyBuyerId'
            aria-label='copy buyer id'
            onClick={() => {
              handleCopyClick(buyerInfo.buyerID);
            }}
            startIcon={<ContentCopyIcon sx={{ height: 20, marginLeft: 2 }} />}
            sx={{
              color: colors.grayLabels,
              textTransform: 'none',
              fontWeight: 'bold',
              fontSize: 13,
            }}
          />
        </div>
      </div>
      <h2 className='fontSharpGrotesk22Medium text-base'>Additional IDs</h2>
      <ul>
        {buyerInfo?.additionalIds.map((id: string) => (
          <div className='flex w-10'>
            <li className='text-2xl mb-3 border px-7 py-3'>{id}</li>
            <Button
              aria-label='copy buyer id'
              startIcon={<ContentCopyIcon sx={{ height: 20, marginLeft: 2 }} />}
              sx={{
                color: colors.grayLabels,
                textTransform: 'none',
                fontWeight: 'bold',
                fontSize: 13,
              }}
              onClick={() => {
                handleCopyClick(id);
              }}
            />
          </div>
        ))}
      </ul>
      <div>
        {buyerInfo.additionalIds.length < 3 && (
          <Button variant='contained' onClick={generateAditionalsIds}>
            Generate ID
          </Button>
        )}
      </div>
    </CardContent>
  );
};

export default BuyingGroupIDCard;

import React from 'react';
import * as Styles from './style';
import {
  Box,
  Button,
  Checkbox,
  Chip,
  FormControl,
  InputBase,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  Stack,
} from '@mui/material';
const { colors } = themeCustom;
import { DatePicker } from 'antd';
import { IChipData } from '../../../interfaces/activeDeals';
import { styled } from '@mui/material/styles';
import { themeCustom } from '../../../theme';
import ClearIcon from '@mui/icons-material/Clear';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import { IFilterProps } from '../../../interfaces/filters/index';
import { Popover } from 'antd';
// import useStatesGlobalStore from '../../../hooks/useStatesGlobalStore';
import { useQueryClient } from 'react-query';
import putFilterSetDefaultServices from '../../../api/services/activeDeals/putFilterSetDefaultServices';
import getFilterSetService from '../../../api/services/activeDeals/getFilterSet';
import { IAlertType } from '../../../interfaces/activeDeal/index';
import GeneralAlert from '../generalAlert/GeneralAlert';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const CustomizedInput = styled(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: theme.spacing(2),
    textTransform: 'capitalize',
    fontSize: '13px',
    fontWeight: 'bold',
  },
  '& .MuiInputBase-input': {
    borderTop: { xl: '2px solid #b2b2b2' },
    fontSize: '25px',
    '&:focus': {
      borderColor: '#80bdff',
      color: colors.lightBlueBorder,
      backgroundColor: colors.white,
      fontWeight: 'bold',
    },
  },
}));

const CustomizedSimpleInput = styled(InputBase)(() => ({
  '& .MuiInputBase-input': {
    // borderRadius: 6,
    border: '1px solid #e1e1e1',
    fontSize: 14,
    padding: '12px 26px 4px 10px',
    textTransform: 'capitalize',
    heigth: 44,
  },
}));

const Filter: React.FC<IFilterProps> = ({
  clearFilters,
  filterOptions,
  formStyles,
  setFilterOptions,
  selectFilters,
  searchFilters,
  setViewModal,
  simpleSelectFilters,
  rangeDateFilter,
  stylesFilterSectionSearchAndDate,
  isSaveFilterVisible,
  showFilterTitle,
  saveModule,
  mutate,
  isLoading,
}) => {
  const dateFormat = 'DD/MM/YY';
  const { RangePicker } = DatePicker;

  const [openSetsFilters, setOpenSetsFilters] = React.useState(false);

  // const { filterSetsSave, setFilterSetsSave } = useStatesGlobalStore();

  const filterArray = (filterValues: any, chipToDelete: IChipData) =>
    filterValues?.filter((item: any) => item !== chipToDelete);

  const handleDelete = (chipToDelete: IChipData) => {
    const {
      brands,
      category,
      expires,
      paymentType,
      receivedStatus,
      pendingStatus,
      price,
      priceType,
      productType,
      retailers,
      totalValue,
      value,
      status,
      expired,
    } = filterOptions;

    const newBrandsArray = filterArray(brands, chipToDelete);
    const newCategoryArray = filterArray(category, chipToDelete);
    const newExpiresArray = filterArray(expires, chipToDelete);
    const newReceivedStatusArray = filterArray(receivedStatus, chipToDelete);
    const newPaymentTypeArray = filterArray(paymentType, chipToDelete);
    const newPendingStatusArray = filterArray(pendingStatus, chipToDelete);
    const newPriceArray = filterArray(price, chipToDelete);
    const newPriceTypeArray = filterArray(priceType, chipToDelete);
    const newProductTypeArray = filterArray(productType, chipToDelete);
    const newRetailersArray = filterArray(retailers, chipToDelete);
    const newTotalValueArray = filterArray(totalValue, chipToDelete);
    const newValueArray = filterArray(value, chipToDelete);
    const newStatusArray = filterArray(status, chipToDelete);
    const newExpiredArray = filterArray(expired, chipToDelete);

    setFilterOptions({
      ...filterOptions,
      brands: newBrandsArray,
      category: newCategoryArray,
      expires: newExpiresArray,
      receivedStatus: newReceivedStatusArray,
      paymentType: newPaymentTypeArray,
      pendingStatus: newPendingStatusArray,
      price: newPriceArray,
      priceType: newPriceTypeArray,
      productType: newProductTypeArray,
      retailers: newRetailersArray,
      totalValue: newTotalValueArray,
      value: newValueArray,
      status: newStatusArray,
      expired: newExpiredArray,
    });
  };

  const onSubmit = () => {};

  const handleChange = (e: any) => {
    setFilterOptions({
      ...filterOptions,
      [e.target.name]: e.target.value,
    });
  };

  const handleChangeDate = (dateString: any) => {
    setFilterOptions({
      ...filterOptions,
      ['date_from']:
        dateString == null ? '' : dateString[0].toISOString().split('T')[0],
      ['date_until']:
        dateString == null ? '' : dateString[1].toISOString().split('T')[0],
    });
  };

  const [showAlert, setShowAlert] = React.useState<IAlertType>({
    message: '',
    show: false,
    title: '',
    type: 'success',
  });

  const queryClient = useQueryClient();

  const { response: responseFilterSet } = getFilterSetService({
    module_name: 'active_deals',
  });

  const { mutate: mutateSetDefault } = putFilterSetDefaultServices();

  React.useEffect(() => {}, [selectFilters]);

  const makeDefault = (item: any) => {
    const res = responseFilterSet.data.filter(
      (filter: any) => filter?.filter_name === item,
    );

    mutateSetDefault(
      { id: res[0].id, module_name: saveModule },
      {
        onSuccess: (data: any) => {
          setShowAlert({
            show: true,
            message: data?.filters,
            title: 'Success',
            type: 'success',
          });
          queryClient.invalidateQueries('GET_FILTERS_SET');
        },
        onError: (error: any) => {
          console.error(error);
        },
        onSettled: () => {
          console.log('settled');
        },
      },
    );
  };

  const buildSaveFilterForm = () => {
    const [nameSets, setNameSets] = React.useState({
      value: '',
      labelName: '',
    });

    const queryClient = useQueryClient();

    const filter_data = {
      ...filterOptions,
      filterSets: nameSets.value,
    };

    return (
      <>
        <form action='' className='flex flex-wrap justify-center gap-4'>
          <input
            type='text'
            required
            name='nameFilter'
            className='w-full p-2 border-black border-2 rounded-xl'
            placeholder='Filter Name'
            onChange={(e) =>
              setNameSets({ value: e.target.value, labelName: e.target.value })
            }
          />
          <button
            disabled={isLoading}
            className='bg-blue-500 text-white w-full p-2 rounded-xl'
            onClick={(e) => {
              e.preventDefault();

              mutate(
                {
                  module_name: saveModule,
                  filter_data: JSON.stringify(filter_data),
                  filter_name: nameSets.labelName,
                },

                {
                  onSuccess: () => {
                    setShowAlert({
                      show: true,
                      message: 'New filter set created',
                      title: 'Success',
                      type: 'success',
                    });
                    queryClient.invalidateQueries('GET_FILTERS_SET');
                  },
                  onError: (error: any) => {
                    console.log(error);
                  },
                },
              );

              // setFilterSetsSave([
              //   ...filterSetsSave,
              //   {
              //     saveModule,
              //     filterOptions: {
              //       ...filterOptions,
              //       filterSets: nameSets.value,
              //     },
              //     nameSets,
              //   },
              // ]);
              setOpenSetsFilters(false);
            }}>
            Save
          </button>
        </form>
      </>
    );
  };

  return (
    <Styles.FiltersContainerStyle>
      {showAlert.show && (
        <GeneralAlert
          alertType={showAlert.type}
          alertMessage={showAlert.message}
          alertTitle={showAlert.title}
          setShowAlert={setShowAlert}
          fontSize={17}
        />
      )}

      {filterOptions && (
        <>
          <div className='lg:flex fontGTAmericanR'>
            {showFilterTitle && (
              <h3
                className={`${
                  selectFilters
                    ? 'flex mt-1 mr-6 ml-10 lg:mb-0 text-[13px]'
                    : 'mt-4 flex mr-6 mb-6 ml-3 lg:mb-0 text-[13px]'
                }`}>
                <picture className='mr-2 mt-1 h-10 w-5'>
                  <img
                    src='images/trackingNumbers/filterIcon.png'
                    alt='download icon'
                    width={19}
                    height={15}
                  />
                </picture>
                Filters
              </h3>
            )}
            <div className='xl:hidden mb-5'>
              <div id='title' className='flex justify-between'>
                <h2 className='fontSharpGrotesk22Medium text-2xl'>Filters</h2>
                <Button
                  onClick={() => {
                    setViewModal({
                      active: false,
                      name: '',
                      activeSearch: false,
                    });
                  }}
                  sx={{
                    fontSize: 12,
                    textTransform: 'none',
                    textDecoration: 'underline',
                    color: colors.grayLabels,
                  }}>
                  Cancel
                </Button>
              </div>
              <div id='filterActions' className='flex xl1:block gap-4'>
                <Button
                  data-testid={`clearFiltersBtn`}
                  variant='outlined'
                  size='large'
                  onClick={() => {
                    clearFilters();
                    setViewModal({
                      active: false,
                      name: '',
                      activeSearch: false,
                    });
                  }}
                  sx={{
                    border: '1.5px solid #4da4fc',
                    borderRadius: 3,
                    marginBottom: 3,
                    width: '100%',
                    color: '#4da4fc',
                    textTransform: 'none',
                    padding: '6px 0px',
                    '&:hover': {
                      backgroundColor: '#0069d9',
                      borderColor: '#0062cc',
                      boxShadow: 'none',
                      color: colors.white,
                    },
                  }}>
                  Clear All
                </Button>
                <Button
                  data-testid={`viewResultsBtn`}
                  variant='outlined'
                  size='large'
                  onClick={() => {
                    setViewModal({
                      active: false,
                      name: '',
                      activeSearch: false,
                    });
                  }}
                  sx={{
                    borderRadius: 3,
                    marginBottom: 3,
                    width: '100%',
                    bgcolor: '#178DFF',
                    color: 'white',
                    textTransform: 'none',
                    padding: '6px 0px',
                    '&:hover': {
                      backgroundColor: '#0069d9',
                      borderColor: '#0062cc',
                      boxShadow: 'none',
                      color: colors.white,
                    },
                  }}>
                  View Results
                </Button>
              </div>
            </div>
            <form
              aria-label='filters'
              autoComplete='on'
              className={
                formStyles
                  ? `xl:mb-4 xl:-mt-6 items-center ${formStyles}`
                  : 'xl:flex w-full xl:mb-4 xl:-mt-6 items-center'
              }
              noValidate
              onSubmit={onSubmit}>
              {/* Multiple-checkbox select filter */}

              {selectFilters?.map((item: any, index: any) => (
                <FormControl
                  data-testid={item.name}
                  key={index}
                  sx={{
                    m: 1,
                    minWidth: {
                      xs: '100%',
                      lg: item.width || 120,
                    },
                    maxWidth: { xl: 155 },
                  }}
                  variant='standard'>
                  <InputLabel
                    id='multiple-checkbox-label'
                    className='text-[15px]'
                    sx={{
                      paddingTop: 0.4,
                      color: ` ${
                        filterOptions[item.name]?.length > 0
                          ? '#1890ff'
                          : '#000'
                      }`,
                    }}>
                    {/* {item.title} */}
                  </InputLabel>
                  <Select
                    id={item.name}
                    style={{
                      borderTop: `2px solid ${
                        filterOptions[item.name]?.length > 0
                          ? '#1890ff'
                          : '#000'
                      }`,
                    }}
                    className='mr-7'
                    inputProps={{
                      'aria-label': `Without label`,
                      'data-testid': `${item.name}`,
                    }}
                    input={<CustomizedInput />}
                    label='prueba'
                    labelId='multiple-checkbox-label'
                    multiple
                    data-testid='selectFilter'
                    displayEmpty
                    MenuProps={MenuProps}
                    name={item.name}
                    onChange={handleChange}
                    renderValue={() => item.name}
                    value={filterOptions[item.name]}>
                    {item.menuItem.map((menuItems: any) => (
                      <MenuItem
                        data-testid={menuItems.labelName}
                        key={menuItems.labelName}
                        value={menuItems.value}
                        sx={{ width: 12 }}>
                        <Checkbox
                          checked={
                            filterOptions[item.name]?.indexOf(menuItems.value) >
                            -1
                          }
                        />
                        <ListItemText
                          primary={menuItems.labelName}
                          sx={{ width: 10 }}
                        />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              ))}

              {/* Simple select filter */}
              {simpleSelectFilters &&
                simpleSelectFilters?.map((filter: any, index: any) => (
                  <div className={filter.stylesFirstContainer()} key={index}>
                    <div className={filter.stylesSecondContainer}>
                      <div>
                        <p className='mr-2 opacity-40 mb-0 text-xs'>
                          {filter.title}
                        </p>
                        <div>
                          {filterOptions[filter.name] !== '' &&
                            filter.name === 'filterSets' && (
                              <p
                                className='my-0 cursor-pointer fontGTAmericanR hover:text-blue-500 mb-4 lg:mb-1 text-xs text-center opacity-40'
                                onClick={() =>
                                  makeDefault(filterOptions[filter.name])
                                }>
                                Set As Default
                              </p>
                            )}
                        </div>
                      </div>
                      <FormControl>
                        <Select
                          IconComponent={ExpandMoreIcon}
                          labelId='simpleSelect'
                          displayEmpty
                          input={<CustomizedSimpleInput />}
                          inputProps={{
                            'aria-label': 'Without label',
                            'data-testid': `${filter.name}`,
                          }}
                          name={filter.name}
                          onChange={handleChange}
                          sx={{
                            minWidth: filter.selectWidth,
                            textTransform: 'capitalize',
                          }}
                          value={filterOptions[filter.name] || ''}>
                          {filter?.menuItems?.map((option: any, index: any) => (
                            <MenuItem
                              value={option.value}
                              key={index}
                              className='capitalize'>
                              {option.labelName}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                  </div>
                ))}
              <div className={stylesFilterSectionSearchAndDate}>
                {/* Search filter */}
                {searchFilters?.map((filter: any, index: number) => (
                  <div className={filter.stylesSecondContainer} key={index}>
                    <p className='mr-2 opacity-40 mb-0 mt-1 text-xs'>
                      {filter.placeHolder}
                    </p>
                    <Box
                      sx={{
                        p: '2px 4px',
                        display: 'flex',
                        alignItems: 'center',
                        width: filter.width,
                        borderRadius: 1,
                        height: 42,
                        backgroundColor: colors.lightGray,
                        border: '1px solid #eaeaea',
                      }}>
                      <img
                        className='ml-2'
                        src='images/trackingNumbers/searchIcon.png'
                        alt='download icon'
                        width='18'
                        height='21'
                      />
                      <InputBase
                        name={filter.name}
                        onChange={handleChange}
                        sx={{
                          ml: 1,
                          flex: 1,
                          input: {
                            '&::placeholder': {
                              fontFamily: 'GTAmericaRegular',
                              fontSize: '12px',
                            },
                          },
                        }}
                        // placeholder={filter.placeHolder}
                        inputProps={{ 'aria-label': 'search google maps' }}
                      />
                    </Box>
                  </div>
                ))}

                {/* Range Date filter */}
                {rangeDateFilter && (
                  <div>
                    <p className='mr-2 opacity-40 mb-0 mt-1 text-xs'>
                      Across all time
                    </p>

                    <Box
                      sx={{
                        display: 'flex',
                        border: '1px solid #d9d9d9',
                      }}>
                      <picture className=' flex items-center'>
                        <img
                          className='p-2 mx-2 w-9 h-10'
                          src='images/trackingNumbers/calendarIcon.png'
                          alt='calendar icon'
                          width='19'
                          height='21'
                        />
                      </picture>
                      <RangePicker
                        data-testid='filterDate'
                        className='dateFilterCustom fontGTAmericanR'
                        format={dateFormat}
                        onChange={handleChangeDate}
                        placeholder={['', '']}
                      />
                    </Box>
                  </div>
                )}
              </div>
            </form>
          </div>

          {/* chips-filters and save filter config*/}
          {selectFilters && (
            <div className='hidden xl:flex border-t-[1px]'>
              <Styles.FiltersHrStyle></Styles.FiltersHrStyle>
              <Styles.FiltersSelectedStyle>
                <div className='flex justify-between w-full'>
                  <div id='chipsView' className='flex mb-3'>
                    <span className='mt-1 ml-8 text-[10px]'>Filtered by:</span>
                    <Stack
                      direction='row'
                      spacing={1}
                      className='filterContainerChips flex flex-wrap items-center'>
                      {selectFilters?.map((item: any, index: any) => (
                        <React.Fragment key={index}>
                          {filterOptions[item.name].map(
                            (data: any, index: any) => (
                              <Chip
                                deleteIcon={<ClearIcon />}
                                label={item.isPrice ? `$ ${data}` : data}
                                key={index}
                                size='small'
                                onDelete={() => {
                                  handleDelete(data);
                                }}
                                className='text-black'
                                sx={{
                                  borderRadius: 2,
                                  fontWeight: 'bold',
                                  color: 'black',
                                  fontSize: '11px',
                                }}
                              />
                            ),
                          )}
                        </React.Fragment>
                      ))}
                    </Stack>
                  </div>

                  {isSaveFilterVisible && (
                    <div className='flex justify-self-end mr-7'>
                      <Popover
                        onVisibleChange={() =>
                          setOpenSetsFilters(!openSetsFilters)
                        }
                        visible={openSetsFilters}
                        placement='bottom'
                        content={buildSaveFilterForm}
                        trigger='click'>
                        <span className='cursor-pointer underline flex gap-3 text-sm mb-1 text-gray-400 opacity-40 align-middle'>
                          <BookmarkIcon />
                          Save This Filter Set
                        </span>
                      </Popover>
                    </div>
                  )}
                </div>
              </Styles.FiltersSelectedStyle>
            </div>
          )}
        </>
      )}
    </Styles.FiltersContainerStyle>
  );
};

export default Filter;

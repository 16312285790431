import React, { useState } from 'react';
import { EditOutlined } from '@mui/icons-material';
import { Popover } from 'antd';
import ModalChecksRequests from './ModalChecksRequests';
import { IAlertType } from '../../../interfaces/activeDeal/index';
import GeneralAlert from '../../../components/UI/generalAlert/GeneralAlert';
import useDeletePaymentPayeeAch from '../../../api/services/paymentRequest/deletePaymentPayeesAch';
import getPaymentRequest from '../../../api/services/paymentRequest';
import DeleteIcon from '@mui/icons-material/Delete';
import ModalDeleteCheckRequest from './ModalDeleteCheckRequest';
import BlockIcon from '@mui/icons-material/Block';
import { DrawerComponentUI } from '../../../components/UI/sideSheetNew/index';
import { DetailsPurchase } from '../../purchaseOrders/components/DetailsPurchase';

const RenderActions = ({ item }: any) => {
  const [viewPopover, setViewPopover] = useState<boolean>(false);
  const [viewPopoverDelete, setViewPopoverDelete] = useState<boolean>(false);
  const [viewModal, setViewModal] = useState({ active: false, name: '' });

  const { mutate, isLoading } = useDeletePaymentPayeeAch();
  const { refetch } = getPaymentRequest({
    payee_name: '',
  });

  const [showAlert, setShowAlert] = useState<IAlertType>({
    message: '',
    show: false,
    title: '',
    type: 'success',
  });

  const handleCancel = () => {
    setViewPopoverDelete(false);
  };

  const handleDelete = () => {
    const code = decodeURIComponent(item.id);
    mutate(
      {
        id: encodeURIComponent(code),
      },
      {
        onSuccess: () => {
          setShowAlert({
            title: 'Success',
            show: true,
            type: 'success',
            message: 'Payment request deleted successfully',
          });
          setViewPopoverDelete(false);
          refetch();
        },
      },
    );
  };

  return (
    <>
      {showAlert.show && (
        <GeneralAlert
          alertType={showAlert.type}
          alertMessage={showAlert.message}
          alertTitle={showAlert.title}
          setShowAlert={setShowAlert}
          fontSize={17}
        />
      )}
      {item.transactions.length > 0 && (
        <div className='flex text-xs text-left '>
          <img
            className='ml-1 mb-1'
            src='images/activeDeals/greyEye.svg'
            alt='quick commit icon'
            width='17'
            height='16'
          />
          <p
            className='mb-1 ml-2 text-gray-400 cursor-pointer'
            onClick={() => setViewModal({ active: true, name: '' })}>
            View PO Details
          </p>
          {viewModal.active && (
            <DrawerComponentUI
              width='60rem'
              anchor={'right'}
              viewRender={
                <DetailsPurchase
                  po_number={item?.transactions[0]?.business_order?.po_number}
                  setViewModal={setViewModal}
                />
              }
              closeModal={setViewModal}
            />
          )}
        </div>
      )}
      {item?.status?.name !== 'Pending' && item?.transactions?.length === 0 && (
        <div className='flex text-xs text-left opacity-40'>
          <BlockIcon fontSize='small' />
          <p className='mb-1 ml-2'>No Actions</p>
        </div>
      )}
      {item?.status?.name === 'Pending' && (
        <>
          <div>
            <Popover
              visible={viewPopover}
              placement='bottom'
              trigger='click'
              content={
                <ModalChecksRequests
                  setViewPopover={setViewPopover}
                  setShowAlert={setShowAlert}
                  isUpdate={viewPopover}
                  item={item}
                />
              }>
              <button
                className='gap-4 text-xs cursor-pointer flex'
                data-testid={`updateBtn#${item?.id}`}
                onClick={() => setViewPopover(!viewPopover)}>
                <EditOutlined
                  fontSize='small'
                  className='cursor-pointer text-gray-400'
                />
                <span className='text-gray-400'>Update</span>
              </button>
            </Popover>
          </div>
          <div className='flex'>
            <Popover
              visible={viewPopoverDelete}
              placement='bottom'
              trigger='click'
              data-testid='formRequestPayment'
              content={
                <ModalDeleteCheckRequest
                  functions={{
                    handleCancel: handleCancel,
                    handleDelete: handleDelete,
                  }}
                  isLoading={isLoading}
                  item={item}
                  styles={'p-1'}
                />
              }>
              <button
                className='flex gap-4 text-xs align-center pt-1 cursor-pointer'
                data-testid={`deleteBtn#${item?.id}`}
                onClick={() => setViewPopoverDelete(!viewPopoverDelete)}>
                <DeleteIcon
                  fontSize='small'
                  className='cursor-pointer text-gray-400'
                />
                <span className='text-gray-400'>Delete</span>
              </button>
            </Popover>
          </div>
        </>
      )}
    </>
  );
};

export default RenderActions;

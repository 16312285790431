import React from 'react';
import { Box, Button, Popover } from '@mui/material';
import { EditOutlined } from '@mui/icons-material';
import { useQueryClient } from 'react-query';
import { themeCustom } from '../../../theme';
import useDeleteAchAccount from '../../../api/services/myAccount/deleteAchAccount';
import UpdateAchAccountForm from '../containers/UpdateAchAccountForm';
import ModalDeleteCheckRequest from '../../paymentRequest/containers/ModalDeleteCheckRequest';

const RenderActionsAchAccountsTable = ({
  accountInfo,
  phoneNumberDetails,
  setShowAlert,
}: any) => {
  const { colors } = themeCustom;
  const { mutate, isLoading } = useDeleteAchAccount();
  const queryClient = useQueryClient();
  const [anchorElUpdate, setAnchorElUpdate] = React.useState<HTMLButtonElement | null>(
    null,
  );
  const [anchorElDelete, setAnchorElDelete] =
    React.useState<HTMLButtonElement | null>(null);
  const popOverOpen = Boolean(anchorElUpdate);
  const openDeletePop = Boolean(anchorElDelete);
  const id = openDeletePop ? 'simple-popover-delete' : undefined;

  const handleOpenDeleteConfirmation = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    setAnchorElDelete(event.currentTarget);
  };

  const handleCancel = () => {
    setAnchorElDelete(null);
  };

  const handleOpenUpdateForm = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElUpdate(event.currentTarget);
  };

  const handleCloseUpdateForm = () => {
    setAnchorElUpdate(null);
  };

  const handleDeleteAchAccount = () => {
    mutate(encodeURIComponent(accountInfo?.id), {
      onSuccess: (data: any) => {
        if (data?.status) {
          setAnchorElDelete(null);
          setShowAlert({
            message: '',
            show: true,
            title: 'The ACH Account has been deleted successfully.',
            type: 'success',
          });
          queryClient.invalidateQueries('GET_ACH_ACCOUNTS');
        } else {
          setAnchorElDelete(null);
          setShowAlert({
            message: data?.error,
            show: true,
            title: 'Error on request',
            type: 'warning',
          });
        }
      },
      onError: (error: any) => {
        if (error) {
          setShowAlert({
            message: error,
            show: true,
            title: 'Error on request',
            type: 'warning',
          });
        }
      },
    });
  };

  return (
    <Box
      sx={{
        display: 'block',
      }}>
      <div>
        <Button
          data-testid={`updateBtnTable#${accountInfo?.id}`}
          key='one'
          onClick={handleOpenUpdateForm}
          sx={{
            textTransform: 'none',
            color: colors.grayLabels,
          }}>
          <Box sx={{ color: colors.grayLabels, display: 'flex' }}>
            <EditOutlined />
            <span className='ml-3'>Update</span>
          </Box>
        </Button>
      </div>
      <div className='ml-1'>
        <Button
          key='two'
          data-testid={`deleteBtnTable#${accountInfo?.id}`}
          onClick={handleOpenDeleteConfirmation}
          sx={{
            textTransform: 'none',
            color: colors.grayLabels,
          }}>
          <Box sx={{ color: colors.grayLabels, display: 'flex' }}>
            <img
              className='h-5'
              src='images/trackingNumbers/deleteIcon.png'
              alt='delete icon'
              width='12'
              height='17'
            />
            <span className='ml-5'>Delete</span>
          </Box>
        </Button>
        <Popover
          anchorEl={anchorElDelete}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          id={id}
          onClose={handleCancel}
          open={openDeletePop}
          data-testid='formRequestPayment'>
          <ModalDeleteCheckRequest
            functions={{
              handleCancel: handleCancel,
              handleDelete: handleDeleteAchAccount,
            }}
            isLoading={isLoading}
            item={accountInfo}
            styles={'p-4'}
          />
        </Popover>
      </div>
      {/* <ConfirmationModal
        functions={{
          handleCancel: handleCancel,
          handleDelete: handleDeleteAchAccount,
        }}
        message={'Click on OK to confirm the deletion of this ACH Account.'}
        open={open}
      /> */}
      <UpdateAchAccountForm
        accountInfo={accountInfo}
        anchorEl={anchorElUpdate}
        handleClose={handleCloseUpdateForm}
        open={popOverOpen}
        phoneNumberDetails={phoneNumberDetails}
      />
    </Box>
  );
};

export default RenderActionsAchAccountsTable;

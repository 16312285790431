/* eslint-disable no-undef */
import cookie from 'react-cookies';

const fetchUpdatesInfo = async (params: any) => {
  const response = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}buyers/buyers-updates?` +
      new URLSearchParams(params),
    {
      headers: {
        Authorization: `Bearer ${cookie.load('token')}`,
      },
    },
  ).then((fetchResponse) => fetchResponse.json());

  return response;
};

export default fetchUpdatesInfo;

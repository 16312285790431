import React, { useState } from 'react';
import { Popover, Table } from 'antd';
import '../style.css';
import Filter from '../../../components/UI/filter/index';
import FormExportPurchase from './FormExportPurchase';
import {
  columns,
  selectFilters,
  searchFilter,
  stylesFilterSectionSearchAndDate,
  dateFilter,
} from '../constants/index';
import { DownloadOutlined } from '@ant-design/icons';

import * as Styles from '../../../styles/mobileTable';

// eslint-disable-next-line no-unused-vars
import { IFilters } from '../../../interfaces/purchaseOrders';
import getPurchaseOrder from '../../../api/services/purchaseOrders/index';
import { IDataInternal } from '../../../interfaces/purchaseOrders/indexUpdate';
import MobileFilter from '../../../components/UI/mobileFilter/index';
import globalStoreNoLocalStore from '../../../hooks/globalStoreNoLocalStore';
import PaginationCustom from '../../../components/UI/PaginationCustom';
import useResponsive from '../../../hooks/useResponsive';

const List = () => {
  const [filterOptions, setFilterOptions] = useState<any>({
    //TODO: change to filters
    receivedStatus: [],
    totalValue: [],
    // productType: [],
    pendingStatus: [],
    pOItemNameModel: '',
    date_from: '',
    date_until: '',
  });

  const [listSelected, setListSelected] = useState<IDataInternal[]>([]);

  const [current, setCurrent] = useState(1);
  const [sizeItemsPage, setSizeItemPage] = useState(10);

  const dateTransform =
    filterOptions.date_from !== '' && filterOptions.date_until !== ''
      ? `${filterOptions.date_from} ${filterOptions.date_until}`
      : '';

  const {
    alertSliceMobile,
    alertTurnScreenMobile,
    setAlertSliceMobile,
    setAlertTurnScreenMobile,
  } = globalStoreNoLocalStore();

  React.useEffect(() => {
    if (
      alertSliceMobile.forUser !== 'user' &&
      alertTurnScreenMobile.forUser !== 'user'
    ) {
      setAlertSliceMobile();
      setAlertTurnScreenMobile();
    }
  }, [alertSliceMobile.forUser, alertTurnScreenMobile.forUser]);

  const { isMobile } = useResponsive();

  const { response, isLoading } = getPurchaseOrder({
    receiving_status: filterOptions.receivedStatus,
    range_price: filterOptions.totalValue,
    business_orders_status: filterOptions.pendingStatus,
    search_items: filterOptions.pOItemNameModel,
    date_range: dateTransform,
    limit: sizeItemsPage,
    start: current > 1 && current * sizeItemsPage - sizeItemsPage,
  });

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: IDataInternal[]) => {
      setListSelected(selectedRows);
    },
  };

  const clearFilters = () => {
    setFilterOptions({
      receivedStatus: [],
      totalValue: [],
      // productType: [],
      pendingStatus: [],
      pOItemNameModel: '',
      date_from: '',
      date_until: '',
    });
  };

  return (
    <>
      <div className=' lg:flex justify-between w-full mb-7 mt-11'>
        <h1 className='lg:ml-5 text-2xl lg:text-4xl fontSharpGrotesk22Medium text-black'>
          Purchase Orders
        </h1>
        <Popover
          placement='bottom'
          trigger='click'
          data-testid='formRequestPayment'
          content={<FormExportPurchase list={listSelected} />}>
          <button
            data-testid='formRequestPayment'
            className='flex justify-between items-center lg:w-auto  bg-white w-full  text-blue-400 px-8 py-5 rounded-3xl cursor-pointer active:border-gray-300'>
            <div>
              <DownloadOutlined style={{ fontSize: '24px' }} />
              <span className='ml-5 fontGTAmericanR'>Export</span>
            </div>
            <img
              alt='delete icon'
              className='h-4 w-2 xl:hidden'
              height='17'
              src='images/trackingNumbers/blueRightArrow.png'
              width='12'
            />
          </button>
        </Popover>
      </div>
      <div className='hidden xl:block'>
        <Filter
          filterOptions={filterOptions}
          rangeDateFilter={dateFilter}
          setFilterOptions={setFilterOptions}
          selectFilters={selectFilters}
          searchFilters={searchFilter}
          stylesFilterSectionSearchAndDate={stylesFilterSectionSearchAndDate}
          isSaveFilterVisible={false}
          showFilterTitle={true}
        />
      </div>

      <div className='xl:hidden'>
        <MobileFilter
          clearFilters={clearFilters}
          filterOptions={filterOptions}
          isSaveFilterVisible={true}
          selectFilters={selectFilters}
          setFilterOptions={setFilterOptions}
          showFilterTitle={false}
          rangeDateFilterMobile={dateFilter}
          formStyles={'flex flex-wrap lg:justify-between'}
          searchFilter={searchFilter}
        />
      </div>

      <div style={{ flex: 1, overflowY: 'auto' }}> 
        <Styles.MobileTable>
          <Table
            rowSelection={{
              columnWidth: '3rem',
              checkStrictly: true,
              type: 'checkbox',
              ...rowSelection,
              }}
              columns={columns}
            loading={isLoading}
            dataSource={response?.data}
            scroll={{ x: 'calc(500px + 50%)' }}
            className='xl1:h-[calc(100vh-400px)] xlc:h-[calc(100vh-420px)] xl2:h-[calc(100vh-430px)] xl2_1:h-[calc(100vh-430px)]'
            pagination={false}
            style={{
                minHeight: '320px'
              }}
              />
        </Styles.MobileTable>
      </div>
      <div className='mt-4 mb-4 justify-end w-full flex'>
        <PaginationCustom
          totalItems={response?.meta?.totals?.items}
          current={current}
          setSizeItemPage={setSizeItemPage}
          sizeItemsPage={sizeItemsPage}
          setCurrent={setCurrent}
          mobile={isMobile}
        />
      </div>
    </>
  );
};

export default List;

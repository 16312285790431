import { useQuery } from 'react-query';
import fetchGetTickets from '../../endPoints/helpCenter/getTickets';

export interface IResponseService {
  response: any;
  error: any;
  isLoading: boolean;
}

const useGetTickets = (email: any): IResponseService => {
  const { error, data, isLoading } = useQuery(['GET_TICKETS', email], () =>
    fetchGetTickets(email),
  );

  return { error, response: data, isLoading };
};

export default useGetTickets;

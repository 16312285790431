import { useContext } from 'react';
import { useQuery } from 'react-query';
import fetchDetails from '../../endPoints/purchaseOrders/details';
import transformDataDetailsPurchase, {
  IServiceTransform,
} from '../../transforms/purchaseOrders/detailsPurchase';
import { AuthContext } from '../../../context/useSaveDataSession';

interface IResponseService {
  response: IServiceTransform | undefined;
  error: any;
  isLoading: boolean;
}

const getDetails = (params: any): IResponseService => {
  const { logout } = useContext<any>(AuthContext);

  const { error, data, isLoading } = useQuery(
    ['GET_DETAILS_PURCHASE', params],
    () => {
      return fetchDetails(params);
    },
    {
      onSuccess: (data) => {
        if (data.message === 'Token has expired') {
          logout();
        } else if (data.status === 401) {
          logout();
        }
      },
    },
  );
  if (data) {
    return { error, response: transformDataDetailsPurchase(data), isLoading };
  } else {
    return { error, response: data, isLoading };
  }
};

export default getDetails;

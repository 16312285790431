import { useStore } from '../store/createStoreGlobalsNoUseLocalStore';

const globalStoreNoLocalStore = () => {
  const alertSliceMobile = useStore.useAlertSliceMobile();
  const setAlertSliceMobile = useStore.useSetAlertSliceMobile();
  const clearAlertSliceMobile = useStore.useClearAlertSliceMobile();
  const clearAlertSliceMobileForUser =
    useStore.useClearAlertSliceMobileForUser();

  const alertTurnScreenMobile = useStore.useAlertTurnScreenMobile();
  const setAlertTurnScreenMobile = useStore.useSetAlertTurnScreenMobile();
  const clearAlertTurnScreenMobile = useStore.useClearAlertTurnScreenMobile();
  const clearAlertTurnScreenMobileForUser =
    useStore.useClearAlertTurnScreenMobileForUser();

  return {
    alertSliceMobile,
    alertTurnScreenMobile,

    setAlertSliceMobile,
    setAlertTurnScreenMobile,
    clearAlertSliceMobile,
    clearAlertTurnScreenMobile,
    clearAlertSliceMobileForUser,
    clearAlertTurnScreenMobileForUser,
  };
};

export default globalStoreNoLocalStore;

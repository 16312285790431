/* eslint-disable no-undef */
import cookie from 'react-cookies';

const postDecrementVariation = async (data: any) => {
  const { commitsData, dealId } = data;
  const response = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}buyers/active-deals/commits/${dealId}/send-quantity-request`,
    {
      method: 'POST',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        Authorization: `Bearer ${cookie.load('token')}`,
        'Content-type': 'application/json',
      },
      redirect: 'follow',
      referrerPolicy: 'strict-origin-when-cross-origin',
      body: JSON.stringify(commitsData),
    },
  );

  return response.json();
};

export default postDecrementVariation;

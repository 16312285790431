/* eslint-disable no-undef */
import cookie from 'react-cookies';

const listAchPayments = async () => {
  const response = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}buyers/bank-accounts`,
    {
      headers: {
        Authorization: `Bearer ${cookie.load('token')}`,
      },
    },
  ).then((fetchResponse) => fetchResponse.json());

  return response;
};

export default listAchPayments;

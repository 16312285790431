import React, { useEffect, useState } from 'react';
import { themeCustom } from '../../../theme';
import { Button } from '@mui/material';
import CardContent from '../../../components/UI/cardContent';
import useDeleteOthersSessions from '../../../api/services/myAccount/deleteOthersSessions';
import { IAlertType } from '../../../interfaces/activeDeal';
import GeneralAlert from '../../../components/UI/generalAlert/GeneralAlert';
import { Popover } from 'antd';

const SecurityCard = () => {
  const { colors } = themeCustom;
  const [viewPopover, setViewPopover] = useState<boolean>(false);

  const [showAlert, setShowAlert] = useState<IAlertType>({
    message: '',
    show: false,
    title: '',
    type: 'success',
  });

  const handleCancelPopover = () => {
    setViewPopover(false);
  };

  const mutation = useDeleteOthersSessions();

  const handleClick = () => {
    mutation.mutate();
    setViewPopover(false);
  };

  useEffect(() => {
    if (mutation.isSuccess) {
      setShowAlert({
        show: true,
        title: `The following number of active sessions have been closed: ${mutation.data.deleted_sessions}`,
        message: '',
        type: 'success',
      });
    }
  }, [mutation.isSuccess]);

  return (
    <CardContent
      styles={'rounded-b-3xl lg:rounded-3xl p-12 '}
      id='securityCard'>
      {showAlert?.show && (
        <GeneralAlert
          alertType={showAlert.type}
          alertMessage={showAlert.message}
          alertTitle={showAlert.title}
          setShowAlert={setShowAlert}
        />
      )}
      <h2 className='fontSharpGrotesk22Medium text-lg'>Security</h2>
      <p className='font-semibold text-xs mt-5'>
        Log out of all sessions except this current browser
      </p>
      <Popover
        visible={viewPopover}
        placement='bottom'
        trigger='click'
        content={
          <div className='flex flex-col items-center'>
            <h3>Do you really want to delete the active sessions?</h3>
            <div className='flex gap-3 uppercase mt-4'>
              <h3
                className='text-[#6fb8ff] font-bold cursor-pointer'
                onClick={handleClick}>
                accept
              </h3>
              <h3
                className='text-[#ff6f6fd4] font-bold cursor-pointer'
                onClick={handleCancelPopover}>
                Cancel
              </h3>
            </div>
          </div>
        }>
        <Button
          // disabled
          onClick={() => setViewPopover(true)}
          variant='outlined'
          size='large'
          sx={{
            color: colors.blueLabels,
            border: '1.5px solid',
            borderRadius: 2,
            width: '50%',
            textTransform: 'none',
            padding: '12px 0px',
            fontWeight: 'bold',
            fontSize: 11,
            '&:hover': {
              backgroundColor: '#0069d9',
              borderColor: '#0062cc',
              boxShadow: 'none',
              color: colors.white,
            },
          }}>
          Log out of other sessions
        </Button>
      </Popover>
    </CardContent>
  );
};

export default SecurityCard;

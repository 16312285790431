import { useMutation } from 'react-query';
import postFetch from '../../endPoints/myAccount/getVerificationCode';

const useGetVerificationCode = () => {
  const { mutate } = useMutation(postFetch);

  return { getVerificationCodeMutate: mutate };
};

export default useGetVerificationCode;

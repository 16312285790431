import React, { useState } from 'react';
import * as Styles from '../style';
import { Box, Divider } from '@mui/material';
import Filter from '../../../components/UI/filter';
import {
  payeeNamesSearchFilter,
  payeeNamesTableColumns,
  selectFilterPayeeName,
  stylesSearchFilter,
} from '../constants';
import { Table } from 'antd';
import getPayeeNames from '../../../api/services/myAccount/getPayeeNames';
import { IFilterPayeeNames } from '../../../interfaces/myAccount';
import { IAlertType } from '../../../interfaces/activeDeal';
import GeneralAlert from '../../../components/UI/generalAlert/GeneralAlert';

const DetailAllPayeeNames = () => {
  const [filterOptions, setFilterOptions] = useState<IFilterPayeeNames>({
    name: '',
    status: 0,
    limit: 0,
    start: 0,
    page_number: 0,
  });
  const [showAlert, setShowAlert] = useState<IAlertType>({
    message: '',
    show: false,
    title: '',
    type: 'success',
  });

  const { response, isLoading } = getPayeeNames(filterOptions);

  return (
    <div data-testid='allcomponent'>
      {showAlert?.show && (
        <GeneralAlert
          alertType={showAlert.type}
          alertMessage={showAlert.message}
          alertTitle={showAlert.title}
          setShowAlert={setShowAlert}
          fontSize={17}
        />
      )}
      <h2 className='text-3xl'>Payee Names</h2>
      <Filter
        filterOptions={filterOptions}
        setFilterOptions={setFilterOptions}
        searchFilters={payeeNamesSearchFilter}
        simpleSelectFilters={selectFilterPayeeName}
        stylesFilterSectionSearchAndDate={stylesSearchFilter}
        isSaveFilterVisible={false}
        showFilterTitle={true}
      />
      <Box
        className='p-3'
        sx={{
          mt: 7,
          // p: 4,
          borderRadius: 3,
          border: '1px solid #b2b2b2',
          // marginX: 1,
          overflowY: 'auto',
          height: '37rem',
        }}>
        <div
          className='flex justify-between p-4 pt-0'
          data-testid='titleAllPayeeNames'>
          <h5 className='font-semibold text-lg'>Payee Names</h5>
        </div>
        <Divider
          sx={{
            marginBottom: '2rem',
          }}
        />
        <Styles.TableContainer>
          <Table
            className='tableStyles tableHeadtyles tableBodyStyles'
            columns={payeeNamesTableColumns(setShowAlert)}
            dataSource={response?.data}
            loading={isLoading}
            pagination={false}
          />
        </Styles.TableContainer>
      </Box>
      <a
        className='opacity-20 ml-3'
        target='_blank'
        href='https://icons8.com/icon/sz8cPVwzLrMP/check-mark'>
        Check Mark icon by Icons8
      </a>
    </div>
  );
};

export default DetailAllPayeeNames;

const fetchPostAddNoteTicket = async (data: any) => {
  const formData: any = new FormData();
  if (data.imageFile.name) {
    formData.append('attachments[]', data.imageFile);
  }

  formData.append('body', data.message.body);
  formData.append('private', data.message.private);
  formData.append('incoming', data.message.incoming);

  const options = {
    method: 'POST',
    headers: {
      Authorization: 'Basic UUV6dGUzblpPNUNBZTFlb1hKOlg=',
    },
    body: formData,
  };

  try {
    const response = await fetch(
      `https://usabuyinggroup.freshdesk.com/api/v2/tickets/${data.id}/notes`,
      options,
    );
    const dataResponse = await response.json();
    return dataResponse;
  } catch (error) {
    console.error(error);
  }
};

// const response = await fetch(
//   `https://usabuyinggroup.freshdesk.com/api/v2/tickets/${data.id}/notes`,
//   {
//     method: 'POST',
//     mode: 'cors',
//     cache: 'no-cache',
//     credentials: 'same-origin',
//     headers: {
//       Authorization: `Basic UUV6dGUzblpPNUNBZTFlb1hKOlg=`,
//       'Content-type': 'application/json',
//     },
//     redirect: 'follow',
//     referrerPolicy: 'strict-origin-when-cross-origin',
//     body: JSON.stringify(data.message),
//   },
// );

// return response.json();
// };

export default fetchPostAddNoteTicket;

// Basic QlZKUWl2QVJXN1M4ZEs1MmhPQXc6WA== autorization cuenta moshe

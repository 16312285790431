import React, { useState, useEffect } from 'react';
import {
  dataFaqsGeneral,
  dataFaqsPickUpDropOff,
  dataFaqsMissingPackage,
  dataFaqsPayment,
} from '../../../constants';
import FaqsHelper from '../../../components/FaqsHelper';
import BreadCrumbs from '../../../../../components/UI/breadCrumbs/index';
import { breadCrumbReadFAQs } from '../../../constants/index';

const ReadFaqs: React.FC = () => {
  const [faqsSelected, setFaqsSelected] = useState<string>('General');
  const [faqsList, setFaqsList] = useState(dataFaqsGeneral);
  const [highlightedId, setHighlightedId] = useState<string | null>(null);
  const [collapsedSections, setCollapsedSections] = useState<{
    [key: string]: boolean;
  }>({
    General: true,
    'Pick up/Drop off': true,
    'Missing Package': true,
    Payment: true,
  });

  useEffect(() => {
    switch (faqsSelected) {
      case 'General':
        setFaqsList(dataFaqsGeneral);
        break;
      case 'Pick up/Drop off':
        setFaqsList(dataFaqsPickUpDropOff);
        break;
      case 'Missing Package':
        setFaqsList(dataFaqsMissingPackage);
        break;
      case 'Payment':
        setFaqsList(dataFaqsPayment);
        break;
      default:
        setFaqsList(dataFaqsGeneral);
    }
  }, [faqsSelected]);

  const handleScrollToSection = (id: string) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
      setHighlightedId(id);

      setTimeout(() => {
        setHighlightedId(null);
      }, 3000);
    }
  };

  const toggleSection = (section: string) => {
    const reset = {
      General: true,
      'Pick up/Drop off': true,
      'Missing Package': true,
      Payment: true,
    };
    setCollapsedSections(reset);

    if (collapsedSections[section]) {
      setCollapsedSections((prev) => ({
        ...prev,
        [section]: !prev[section],
      }));
      setFaqsSelected(section);
    }
  };

  return (
    <>
      <div className='flex justify-between mt-5 mb-10'>
        <div className='mt-5 flex gap-4 text-gray-400'>
          <BreadCrumbs links={breadCrumbReadFAQs} />
        </div>
      </div>
      <h3 className='fontSharpGrotesk22Medium mb-10 text-3xl lg:hidden'>
        FAQs
      </h3>
      <div className='lg:hidden'>
        <ul className='leading-10 mt-10 flex flex-wrap'>
          {['General', 'Pick up/Drop off', 'Missing Package', 'Payment'].map(
            (section) => (
              <li
                key={section}
                className={`cursor-pointer mx-1 px-4 rounded-lg text-gray-400 border mt-3 ${
                  faqsSelected === section
                    ? 'bg-blue-500 text-white border-blue-500 font-bold'
                    : 'text-gray-400 border-gray-500 font-bold'
                }`}
                onClick={() => toggleSection(section)}>
                {section}
              </li>
            ),
          )}
        </ul>
      </div>
      <div className='flex flex-wrap lg:gap-25 gap-16 bg-white w-full rounded-3xl py-12 lg:py-20 px-10'>
        <div className='card-nav'>
          <span className='fontSharpGrotesk22Medium text-3xl hidden md:block'>
            FAQs
          </span>
          <div className='bg-gray-100 rounded-3xl px-10 py-10 mt-16 hidden lg:block max-w-[250px]'>
            <span className='text-black font-semibold text-base'>Topics</span>
            <ul className='leading-10 mt-10'>
              {[
                'General',
                'Pick up/Drop off',
                'Missing Package',
                'Payment',
              ].map((section) => (
                <li
                  key={section}
                  className={`cursor-pointer ${
                    faqsSelected === section
                      ? 'font-bold text-black'
                      : 'text-gray-400'
                  }`}
                  onClick={() => toggleSection(section)}>
                  <p className='flex flex-row gap-2 h-3 items-center mb-5'>
                    {section}
                    <img src='images/dashboard/arrow.png' alt='arrow' />
                  </p>
                  {!collapsedSections[section] && (
                    <ul className='mt-[4px] leading-4 hidden lg:block'>
                      {faqsList.map((faq) => (
                        <li
                          key={faq.id}
                          className='cursor-pointer text-[12px] text-justify font-normal text-gray-600 hover:text-black pb-3'
                          onClick={(e) => {
                            e.stopPropagation();
                            handleScrollToSection(faq.id);
                          }}>
                          {faq.title}
                        </li>
                      ))}
                    </ul>
                  )}
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className='active_deals lg:w-4/6'>
          <span className='fontSharpGrotesk22Medium lg:p-5 text-base text-blue-500 hidden lg:block'>
            {faqsSelected}
          </span>
          <hr className='lg:my-8 hidden lg:block' />
          <div className='lg:p-5'>
            {faqsList.map((faq) => (
              <div key={faq.id} id={faq.id} className='pt-4'>
                <div className={highlightedId === faq.id ? 'highlight' : ''}>
                  <FaqsHelper title={faq.title} paragraph={faq.paragraph} />
                </div>
              </div>
            ))}
          </div>
          <hr className='my-6' />
        </div>
      </div>
    </>
  );
};

export default ReadFaqs;

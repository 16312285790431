import React from 'react';
import { Link } from 'react-router-dom';
import CardHelper from '../components/cardHelper';

export const IndexDesktop = ({ infoCards }: any) => {
  return (
    <div className='flex flex-wrap mt-11 w-full'>
      <div className=' w-full rounded-3xl px-16 py-8 flex justify-between bg-white'>
        <span className='fontSharpGrotesk22Medium text-lg w-1/2'>
          What do you need help with?
        </span>
        <div className='flex flex-wrap justify-end gap-8'>
          {infoCards.map((card: any, index: number) => (
            <CardHelper
              key={index}
              route={card.route}
              icon={card.icon}
              title={card.title}
              style={'w-[25rem] p-[4.5rem]'}
              disabled={card.disabled}
            />
          ))}
        </div>
      </div>
      <div className='flex w-full gap-4 mt-10 content-between mb-4'>
        <div className='bg-white w-[50%] p-8 rounded-3xl'>
          <section className='title flex justify-between'>
            <h3 className='fontSharpGrotesk22Medium mb-10 text-lg'>
              Popular Questions
            </h3>
            <span className='text-gray-400 underline'>
              <Link to={'/read-faqs'}>Read all FAQs</Link>
            </span>
          </section>
          <div className='grid grid-cols-2 gap-4'>
            <div className='bg-blue-200 px-4 pt-3 m-auto rounded-xl'>
              <p>How long after a deal must I drop it off?</p>
            </div>
            <div className='bg-blue-200  px-4 pt-3 m-auto rounded-xl'>
              <p>How long after a deal must I drop it off?</p>
            </div>
            <div className='col-span-2 px-4 pt-3 mr-10 ml-3 bg-blue-200 rounded-xl'>
              <p>
                If I am unable to get the product delivered within the time
                frame should I still purchase?
              </p>
            </div>
            <div className='bg-blue-200  px-4 pt-3 m-auto rounded-xl'>
              <p>How long after a deal must I drop it off?</p>
            </div>
            <div className='bg-blue-200  px-4 pt-3 m-auto rounded-xl'>
              <p>How long after a deal must I drop it off?</p>
            </div>
          </div>
        </div>
        <div className='bg-white w-[50%] p-[2rem] rounded-3xl'>
          <section className='title flex justify-between'>
            <h3 className='fontSharpGrotesk22Medium mb-10 text-lg'>
              Quick Start Guide
            </h3>
            <span className='text-gray-400 underline'>
              <Link to={'/read-faqs'}>Read all FAQs</Link>
            </span>
          </section>
          <div className='flex'>
            <div>
              <p className='mr-20'>
                Welcome to the new USA Buying portal: intuitive, beautiful, easy
                to get around. Our Quick Start Guide will show you all the
                essentials!
              </p>
              <Link to='/quick-start-guide'>
                <button className='rounded-xl bg-blue-500 px-10 text-white py-3'>
                  Show me around
                </button>
              </Link>
            </div>
            <img
              className='w-[16rem] h-[9.5rem]'
              src='/images/dashboard.png'
              alt='dashboard'
            />
          </div>
        </div>
      </div>
    </div>
  );
};

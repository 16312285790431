/* eslint-disable no-unused-vars */
import styled from '@emotion/styled';
import { useEffect, useState } from 'react';
import { useCart } from 'react-use-cart';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import ReportGmailerrorredIcon from '@mui/icons-material/ReportGmailerrorred';

const RenderQtyUser = (itemModify: any) => {
  const { updateItem } = useCart();
  const [userQty, setUserQty] = useState<any>({
    details: {
      color: '',
      amount: false,
      id: '',
      modifyInCart: false,
    },
    index: 0,
  });

  const updatePrice = () => {
    let suma = 0;
    itemModify.item.quantityUser?.map((qty: any) => {
      const res = Number(qty.amount);

      suma += res;
    });

    updateItem(itemModify.item.id, {
      ...itemModify.item,
      TotalPrice: suma * itemModify.item.weReimburse,
    });
  };

  const changeValue = (
    e: { target: { value: any } },
    index: any,
    item: any,
  ) => {
    setUserQty({
      details: { ...item, amount: e.target.value },
      index,
    });
  };

  const modifyItemCart = () => {
    const quantityUserUpdate = itemModify.item.quantityUser;
    quantityUserUpdate.splice(userQty.index, 1, {
      color: userQty.details.color,
      amount: userQty.details.amount,
      id: userQty.details.id,
      modifyInCart: true,
    });

    updateItem(itemModify.item.id, {
      ...itemModify.item,
      quantityUser: quantityUserUpdate,
    });
    updatePrice();
  };

  useEffect(() => {
    if (userQty.details.amount !== false) {
      modifyItemCart();
    }
    updatePrice();
  }, [userQty.details.amount]);

  const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: 'rgb(253, 237, 237)',
      color: 'rgb(95, 33, 32)',
      fontSize: 11,
    },
  }));

  return (
    <div className='mt-6 w-full lg:mt-0'>
      {itemModify.item.quantityUser?.map((item: any, index: any) => (
        <div
          className='flex items-center justify-between lg:justify-start lg:w-full w-8/12'
          key={index}>
          <div className='text-black text-sm text-left w-20 flex flex-wrap lg:block lg:flex-nowrap pb-1 overflow-x-auto lg:overflow-x-hidden mb-2 lg:mr-2 lg:w-6/12 capitalize text-[0.7rem] lg:text-[13px] font-semibold fontGTAmericanR'>
            {item.color}
          </div>
          {item.amount > 0 && !item?.modifyInCart ? (
            <LightTooltip
              title={
                <div className='flex'>
                  <ReportGmailerrorredIcon />
                  <p className='text-center mt-1'>
                    You've already committed to this item's variation.
                  </p>
                </div>
              }
              placement='top'>
              <p className='border border-gray-400 w-14 lg:w-3/12 rounded-lg mb-2 p-2 text-left bg-slate-200'>
                {item.amount}
              </p>
            </LightTooltip>
          ) : (
            <input
              className='border border-gray-400 w-14 lg:w-3/12 rounded-lg mb-2 p-2 text-left'
              defaultValue={item.amount}
              aria-label={'input item amount'}
              type='number'
              min={0}
              onChange={(e: any) => changeValue(e, index, item)}
            />
          )}
        </div>
      ))}
    </div>
  );
};

export default RenderQtyUser;

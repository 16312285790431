import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FileOutlined } from '@ant-design/icons';
import { DrawerComponentUI } from '../../../components/UI/sideSheetNew/index';
import { DetailsPurchase } from '../../purchaseOrders/components/DetailsPurchase';

const RenderActions = ({ item }: any) => {
  const [viewModal, setViewModal] = useState({ active: false, name: '' });

  return (
    <>
      <Link
        to={`/active-deal/${item.id}`}
        state={item}
        className='flex text-xs text-left'>
        <img
          className='ml-1 mb-3'
          src='images/activeDeals/greyEye.svg'
          alt='quick commit icon'
          width='17'
          height='16'
        />
        <p className='mb-3 ml-1 fontGTAmericanR opacity-40'>View Item</p>
      </Link>

      {item?.po_numbers.length > 0 && (
        <div>
          <p
            className='flex text-xs text-left opacity-40 cursor-pointer ml-1'
            onClick={() => setViewModal({ active: true, name: '' })}>
            <FileOutlined style={{ fontSize: '1rem' }} />
            <span className='ml-1 pt-1 fontGTAmericanR'>
              View Purchase orders
            </span>
          </p>
          {viewModal.active && (
            <DrawerComponentUI
              anchor={'right'}
              viewRender={
                <DetailsPurchase
                  po_number={item?.po_numbers[0]}
                  setViewModal={setViewModal}
                />
              }
              closeModal={setViewModal}
            />
          )}
        </div>
      )}
    </>
  );
};

export default RenderActions;

import {
  // IMyCommitmentsData,
  IServiceTransform,
} from '../../../interfaces/myCommitments';

const getMyCommitmentsTransform = (data: any): IServiceTransform => {
  const dataTranformed = data?.deals?.data.map((item: any) => {
    const quantityUser: any = [];

    item?.variations?.map((variation: any) => {
      quantityUser.push({
        color: variation.name,
        amount: variation.buyer_commit,
        id: variation.id,
        fullyCommitted: variation.buyer_commit === variation.commit_limit,
      });
    });

    return {
      ...item,
      quantityUser,
    };
  });
  const metaTranform = {
    status: data.status,
    pagination: data?.deals?.pagination,
    totals: data?.deals?.totals,
  };

  return { data: dataTranformed, meta: metaTranform };
};

export default getMyCommitmentsTransform;

import tw, { styled } from 'twin.macro';

export const FiltersContainerStyle = styled.div`
  ${tw`bg-white xl:pt-[1.2rem] xl:px-2 xl:mb-4 w-full`}
  border-radius: 2rem;
  h3 {
    color: grey;
  }

  .button-date {
    ${tw`mt-8 lg:mt-0`}
  }

  .input-search {
    ${tw`lg:ml-14`}
  }

  .dateFilterCustom {
    width: 10rem;
    background-color: #fcfcfc;
  }

  .ant-picker-range-separator {
    display: none;
  }

  .ant-picker-input-active {
    width: 15rem;
  }

  .ant-picker-suffix {
    display: none;
  }

  .ant-picker {
    border-width: 0px;
    padding: 0;
  }
`;

export const FiltersSelectedStyle = styled.div`
  ${tw`flex flex-wrap w-full mt-4`}

  .filterContainerChips {
    ${tw`ml-3`}
  }

  span {
    color: #000;
  }

  svg {
    color: #000 !important;
  }
`;

export const FiltersHrStyle = styled.hr`
  border-top: 2.5px solid #dfdcdc;
`;

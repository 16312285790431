import React from 'react';
import usePriceFormat from '../../../hooks/usePriceFormat';
import * as Styles from './style';
import { Link } from 'react-router-dom';
import { IProps } from '../../../interfaces/dashboard';
import { useCart } from 'react-use-cart';

const SmallCard = ({ data, value }: IProps) => {
  const {
    color,
    componentEdit,
    icon,
    isCurrency,
    title,
    path,
    isTotalPriceCart,
  } = data;

  const { metadata } = useCart();

  return (
    <Styles.CardStyle color={color} data-testid='smallCardContainer'>
      <Link
        to={path ? path : ''}
        className={path ? 'cursor-pointer' : 'cursor-default'}>
        <div className='titleCardDivStyle'>
          <p
            data-testid='titleCard'
            className='fontGTAmericanR xl2:text-[0.8rem] xl3:text-[1rem]'>
            {title}
          </p>
          {componentEdit && (
            <div data-testid='contentComponentEdit'>{componentEdit}</div>
          )}
          <span>{icon}</span>
        </div>
        <hr />
        <p className='moneyValueStyles mt-4 xl2_1:mt-[2rem] text-3xl xl2:text-3xl xl2_1:text-4xl xl3:text-5xl'>
          {(isCurrency ? usePriceFormat(value ? value : 0) : value) ||
            (isTotalPriceCart && usePriceFormat(metadata?.totalItemsPrice))}
        </p>
      </Link>
    </Styles.CardStyle>
  );
};

export default SmallCard;

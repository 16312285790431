import React, { useState } from 'react';
import { Button, Divider } from '@mui/material';
import { themeCustom } from '../../../theme';
import { useCart } from 'react-use-cart';
import usePriceFormat from '../../../hooks/usePriceFormat';
import { DrawerComponentUI } from '../sideSheetNew';
import MobileOptions from '../mobileOptions/MobileOptions';
import RenderQtyUser from '../../../modules/myDealsCart/containers/RenderQtyUser';
import { RenderQty } from '../../../modules/myCommitments/containers/RenderQty';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
// import { Tag } from 'antd';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

const MobileList = ({ data, isActiveDeals, isMyCart, isMyCommitmets }: any) => {
  const { colors } = themeCustom;
  const { addItem } = useCart();

  const [viewModal, setViewModal] = useState({
    active: false,
    name: '',
    dealData: {},
  });

  const VerifyStatus = ({ item }: any) => {
    const [statusCommitment, setStatusCommitment] = useState('none');
    React.useEffect(() => {
      const status: string[] = [];

      if (item.days_to_expire < 0) {
        setStatusCommitment('expired');
        return;
      }

      item.variations.map((variation: any) => {
        if (variation.buyer_commit === variation.received) {
          status.push('received');
        } else if (
          variation.buyer_commit > variation.received &&
          variation.buyer_commit > 0 &&
          variation.received > 0
        ) {
          status.push('partial received');
        } else {
          status.push('not received');
        }
      });

      if (status.includes('partial received')) {
        setStatusCommitment('partial received');
      } else if (
        status.includes('received') &&
        !status.includes('not received') &&
        !status.includes('partial received')
      ) {
        setStatusCommitment('received');
      } else {
        setStatusCommitment('not received');
      }
    }, [statusCommitment, data, isMyCommitmets]);

    return (
      <p
        className={`lg:p-1 lg:w-20 pb-[0.2rem] lg:pb-0 pt-[0.2rem] lg:pt-0 px-[0.2rem] lg:px-0 text-[0.5rem] text-white uppercase rounded-md text-center absolute `}
        data-testid='banner_text_banner'
        style={{
          backgroundColor:
            statusCommitment === 'received'
              ? '#36da72'
              : statusCommitment === 'partial received'
              ? '#8ce8ae'
              : statusCommitment === 'not received'
              ? '#d3d3d3'
              : statusCommitment === 'expired'
              ? '#ff6339'
              : '#f44336',
        }}>
        {statusCommitment}
      </p>
    );
  };

  return (
    <div className='bg-white rounded-3xl lg:hidden'>
      {data?.map((deal: any, index: number) => {
        const transfomImagesActiveDeals = deal?.item?.image?.split('//')[1];
        const transfomImagesCommitments = deal?.image?.split('//')[1];

        return (
          <div id='deal' className='px-6 pt-6' key={index}>
            <div id='header' className='flex justify-between items-start'>
              <div className='flex justify-between gap-1'>
                <div
                  className='w-[90px] h-[90px] float-left p-1 '
                  style={{ boxSizing: 'border-box' }}>
                  <img
                    className='w-full h-full object-contain'
                    src={
                      isMyCommitmets
                        ? `https://${transfomImagesCommitments}`
                        : `https://${transfomImagesActiveDeals}`
                    }
                    alt='product image'
                  />
                </div>
                {deal?.banner_text && (
                  <p
                    className='lg:p-1 lg:w-20 pb-[0.2rem] lg:pb-0 pt-[0.2rem] lg:pt-0 px-[0.2rem] lg:px-0 text-[0.5rem] text-white uppercase rounded-md bg-orange-600 text-center absolute'
                    data-testid='banner_text_banner'>
                    {deal?.banner_text}
                  </p>
                )}
                {isMyCommitmets && <VerifyStatus item={deal} />}
                <p className='text-xs w-[12rem] flex items-center fontGTAmericanR font-semibold truncate-text-3-lines h-[3rem] mt-3'>
                  {isMyCommitmets ? deal.title : deal.item.description}
                </p>
              </div>
              <Button
                data-testid={'optionsButton#' + `${deal.id}`}
                onClick={() => {
                  setViewModal({ active: true, dealData: deal, name: '' });
                }}
                sx={{
                  padding: 0,
                  marginRight: '15px',
                }}>
                <img
                  id='options'
                  className='h-5 w-5 -mt-1 justify-end mr-2'
                  src='images/activeDeals/icons8-dots.png'
                  alt='options icon'
                  width='67'
                  height='67'
                />
              </Button>
            </div>
            <div id='body' className='flex gap-8 mt-1'>
              <div>
                <span className='dealInfoSpanStyle text-[0.7rem] text-gray-400'>
                  Expires
                </span>
                <p className='dealInfoPStyle text-xs'>
                  {isMyCommitmets ? deal.expiration_date : deal.dealExpires}
                </p>
              </div>
              <div>
                <span className='dealInfoSpanStyle text-[0.7rem] text-gray-400'>
                  Price
                </span>
                <p className='dealInfoPStyle text-xs'>
                  {usePriceFormat(deal?.variations[0]?.list_price || 0)}
                </p>
              </div>
              {/* TODO:PENDING BACK DATA */}
              <div>
                <span className='dealInfoSpanStyle text-[0.7rem] text-gray-400'>
                  We Reimburse
                </span>
                <div className='flex'>
                  <p className='dealInfoPStyle text-xs'>
                    {usePriceFormat(deal.price)}
                  </p>
                  {/* <p className='dealInfoPStyle text-xs ml-4'>$100 price</p> */}

                  {Number(deal.price) >
                    Number(deal.variations[0].list_price) && (
                    <p
                      style={{
                        borderRadius: 10,
                        fontSize: '8px',
                        width: '4.5rem',
                        paddingTop: '4px',
                        textAlign: 'center',
                        backgroundColor: '#81BFFD',
                        color: '#fff',
                        alignItems: 'center',
                        marginLeft: '2px',
                      }}>
                      {usePriceFormat(
                        Number(deal.price) -
                          Number(deal.variations[0].list_price) || 0,
                      )}
                      <ArrowUpwardIcon fontSize='inherit' />
                      Price
                    </p>
                  )}

                  {Number(deal.price) <
                    Number(deal.variations[0].list_price) && (
                    <p
                      style={{
                        borderRadius: 10,
                        fontSize: '8px',
                        width: '4.5rem',
                        paddingTop: '4px',
                        textAlign: 'center',
                        backgroundColor: '#ff6339',
                        color: '#fff',
                        alignItems: 'center',
                        marginLeft: '2px',
                      }}>
                      {usePriceFormat(
                        Number(deal.variations[0].list_price) -
                          Number(deal.price) || 0,
                      )}
                      <ArrowDownwardIcon fontSize='inherit' />
                      Price
                    </p>
                  )}
                </div>
              </div>
            </div>
            {isActiveDeals && (
              <>
                {deal.enabled ||
                deal.is_fully_committed ||
                deal.days_to_expire < 0 ? (
                  <p className='text-xs flex justify-center cursor-default items-center'>
                    <div
                      className='w-[30px] h-[30px] p-1'
                      style={{ boxSizing: 'border-box' }}>
                      <img
                        className=' w-full h-full object-contain'
                        src='images/activeDeals/blockedDealIcon.svg'
                        alt='blocked deal icon'
                        // width='16'
                        // height='22'
                      />
                    </div>
                    <span className='ml-5 opacity-70'>
                      {(deal.is_fully_committed || deal.locked) &&
                        'No More Commitments Allowed'}
                      {deal.enabled &&
                        !deal.is_fully_committed &&
                        "You've already committed to this deal"}
                      {deal.days_to_expire <= 0 &&
                        !deal.locked &&
                        !deal.is_fully_committed &&
                        'Deal Expired'}
                    </span>
                  </p>
                ) : (
                  <Button
                    data-testid={`addToCartBtn#${deal.id}`}
                    disabled={
                      (deal?.days_to_expire >= 0 ? false : true) ||
                      deal?.enabled ||
                      deal?.locked
                    }
                    variant='outlined'
                    size='large'
                    onClick={() => {
                      addItem(deal);
                    }}
                    sx={{
                      border: '1.5px solid #4da4fc',
                      borderRadius: 3,
                      marginBottom: 3,
                      width: '100%',
                      color: '#4da4fc',
                      textTransform: 'none',
                      padding: '6px 0px',
                      '&:hover': {
                        backgroundColor: '#0069d9',
                        borderColor: '#0062cc',
                        boxShadow: 'none',
                        color: colors.white,
                      },
                    }}>
                    <img
                      className='h-5 w-5 mr-2'
                      src='images/activeDeals/blueShoppingCartIcon.png'
                      alt='options icon'
                      width='67'
                      height='67'
                    />
                    Add to Deals Cart
                  </Button>
                )}
              </>
            )}
            <div className='flex lg:hidden'>
              {isMyCart && (
                <>
                  <p className='mr-9 text-[0.7rem] text-gray-400 mt-8'>
                    Quantity
                  </p>
                  <RenderQtyUser item={deal} />
                </>
              )}
            </div>
            <div className='lg:hidden'>
              {isMyCommitmets && (
                <div className=''>
                  {/* <p className='mr-4 text-[0.7rem] text-gray-400 mt-8'>
                  Quantity
                </p> */}
                  <div className='flex'>
                    <div className='mt-5'>
                      <RenderQty item={deal} />
                    </div>
                  </div>
                </div>
              )}
            </div>
            <Divider />
          </div>
        );
      })}
      {viewModal.active && (
        <DrawerComponentUI
          anchor={'bottom'}
          modalBorderTopRightRadius={'30px'}
          viewRender={
            <MobileOptions
              deal={viewModal.dealData}
              isActiveDeals={isActiveDeals}
              isMyCart={isMyCart}
              isMyCommitmets={isMyCommitmets}
              setViewModal={setViewModal}
            />
          }
          closeModal={setViewModal}
          width={'100%'}
        />
      )}
    </div>
  );
};

export default MobileList;

import { useMutation } from 'react-query';
import fetchPostUpdateInfoUserAddress from '../../endPoints/myAccount/postUpdateInfoUserAddress';

const postUpdateInfoUserAddress = () => {
  const { mutate } = useMutation(fetchPostUpdateInfoUserAddress);

  return { mutate };
};

export default postUpdateInfoUserAddress;

/* eslint-disable no-unused-vars */
import { useEffect, useState } from 'react';
import { Button, Alert } from '@mui/material';
import useQuickCommit from '../../../api/services/activeDeals/quickCommit';
import { IAlertType } from '../../../interfaces/activeDeal/index';
import GeneralAlert from '../../../components/UI/generalAlert/GeneralAlert';
import getMyCommitments from '../../../api/services/myCommitments/getMyCommitments';
import usePostDecrementVariation from '../../../api/services/myCommitments/postDecrementVariation';
import useStatesGlobalStore from '../../../hooks/useStatesGlobalStore';

export const RenderQty = ({ item }: any) => {
  const {callRefetch, setCallRefetch } = useStatesGlobalStore();
  
  const [variationQty, setVariationQty] = useState({
    id: '',
    qty: '',
  });

  const [showAlert, setShowAlert] = useState<IAlertType>({
    message: '',
    show: false,
    title: '',
    type: 'success',
  });
  const [newValue, setnewValue] = useState(0);

  const { mutate, isLoading } = useQuickCommit();

  const { mutate: mutateDecrement } = usePostDecrementVariation();

  const { response, refetch } = getMyCommitments({});

  const handleChange = (e: any, variationId: any, limit: any, lockedByTime: boolean, amount: any) => {
    setVariationQty({ ...variationQty, id: variationId, qty: e.target.value });
    if (Number(e.target.value) === limit) {
      setShowAlert({
        show: true,
        message: `The maximum commitment is ${limit} units`,
        title: 'Warning',
        type: 'warning',
      });
    } else if ((lockedByTime && Number(e.target.value) < amount) || 
    (lockedByTime && Number(e.target.value) < newValue)) {
      setShowAlert({
        show: true,
        message: `You cannot decrease the commit quantity`,
        title: 'Warning',
        type: 'warning',
      });
    }
  };

  const handleDecrementCommit = (id: string) => {
    mutateDecrement(
      { commitsData: { quantity: variationQty.qty }, dealId: id },
      {
        onSuccess: () => {
          setShowAlert({
            show: true,
            message: `Commitment Decreased Successfully`,
            title: 'Success',
            type: 'success',
          });
          setnewValue(Number(variationQty.qty));
          setVariationQty({ id: '', qty: '' });
          refetch();
          setCallRefetch(!callRefetch);
        },
      },
    );
  };

  const handleQuickCommit = () => {
    mutate(
      { commitsData: { commits: [variationQty] }, dealId: item.id },
      {
        onSuccess: (data) => {
          if (data?.status) {
            setShowAlert({
              show: true,
              message: data?.error
                ? data?.error
                : 'Commitment Increased Successfully',
              title: data?.error ? 'Error' : 'Success',
              type: data?.error ? 'warning' : 'success',
            });
            setnewValue(Number(variationQty.qty));
            refetch();
            setVariationQty({ id: '', qty: '' });
          } else if (data?.error) {
            setShowAlert({
              show: true,
              message: data?.error,
              title: 'Error',
              type: 'error',
            });
          }
        },
        onError: (error: any) => {
          setShowAlert({
            show: true,
            message: error,
            title: 'Error',
            type: 'error',
          });
        },
      },
    );
  };

  const validateEventFocus = (
    edit: any,
    limit: any,
    decrement: any,
    buyerCommit: any,
    locked: boolean,
  ) => {
    if (locked) {
      setShowAlert({
        show: true,
        message: `This variation is locked.`,
        title: 'Warning',
        type: 'warning',
      });
      return;
    } else if (buyerCommit === limit && !decrement) {
      setShowAlert({
        show: true,
        message: `The maximum commitment is ${limit}, and it is not allowed to decrease.`,
        title: 'Warning',
        type: 'warning',
      });
      return;
    }
    else if (buyerCommit === limit) {
      setShowAlert({
        show: true,
        message: `The maximum commitment is ${limit} units`,
        title: 'Warning',
        type: 'warning',
      });
      return;
    }
  };

  return (
    <>
      {showAlert.show && (
        <GeneralAlert
          alertType={showAlert.type}
          alertMessage={showAlert.message}
          alertTitle={showAlert.title}
          setShowAlert={setShowAlert}
          fontSize={17}
        />
      )}
      {item?.variations?.map((variation: any) => (
        <div key={variation?.id}>
          <div className='lg:flex w-full items-center justify-center'>
            <p className='text-black text-sm text-[13px] text-left lg:w-1/3 capitalize font-semibold fontGTAmericanR'>
              {variation.name}
            </p>

            <div className='flex items-center gap-2 lg:gap-5 mb-4 lg:w-[15rem]'>
              <input
                onFocus={() =>
                  validateEventFocus(
                    variation.is_buyer_commit_editable,
                    variation.commit_limit,
                    variation.allow_buyer_to_decrease_term,
                    variation.buyer_commit,
                    variation.locked,
                  )
                }
                disabled={
                  !variation.is_buyer_commit_editable ||
                  isLoading ||
                  item.days_to_expire === 0
                   ||
                  variation.quantity_request?.status?.name === 'Pending'
                }
                id={variation.id}
                name={variation.name}
                className={`border border-gray-400 lg:w-4/12 rounded-lg p-2 text-left ${
                  item.days_to_expire === 0 && 'bg-slate-200'
                }`}
                data-testid={`quickCommitInput#${variation.id}`}
                defaultValue={variation.buyer_commit}
                aria-label={'input item amount'}
                type='number'
                min={variation.locked_by_time ? (newValue > 0 ? newValue : variation.buyer_commit) : 0}
                max={variation.commit_limit}
                onChange={(e: any) =>
                  handleChange(e, variation.id, variation.commit_limit, variation.locked_by_time, variation.buyer_commit)
                }
              />
              <Button
                disabled={
                  (item.days_to_expire > 0 ? false : true) ||
                  variationQty.id !== variation.id ||
                  isLoading ||
                  !variation.is_buyer_commit_editable ||
                  variation.locked || 
                  (variation.locked_by_time && variationQty.qty < variation.buyer_commit) ||
                  (variation.locked_by_time  && Number(variationQty.qty) < newValue)
                }
                variant='outlined'
                onClick={
                  (variationQty.qty < variation.buyer_commit) || (Number(variationQty.qty) < newValue)
                    ? () => handleDecrementCommit(variation.commit_id)
                    : () => handleQuickCommit()
                }
                data-testid={`buttonMyCommitments#${variation.id}`}
                sx={{
                  border: '1.5px solid',
                  borderRadius: 2,
                  textTransform: 'none',
                  fontWeight: 'bold',
                  '&:hover': {
                    backgroundColor: '#0069d9',
                    borderColor: '#0062cc',
                    boxShadow: 'none',
                    color: 'white',
                  },
                }}>
                Commit
              </Button>
              <div className='flex lg:hidden'>
                <div className='mx-4'>
                  <p className='text-center text-[0.7rem] text-gray-400'>
                    Qty Received
                  </p>
                  <p className='text-center '>{variation.received}</p>
                </div>
                <div>
                  <p className='text-center text-[0.7rem] text-gray-400'>
                    Qty Waiting
                  </p>
                  <p className='text-center mb-0'>
                    {parseInt(variation.buyer_commit) >= 0
                      ? variation.buyer_commit - variation.received
                      : 0}
                  </p>
                </div>
              </div>
            </div>
          </div>
          {variation.quantity_request &&
            variation.quantity_request.status.name === 'Pending' && (
              <Alert severity='warning' className='mb-3'>
                {`You have a pending request to reduce QTY to
            ${variation.quantity_request.data.quantity}`}
              </Alert>
            )}
        </div>
      ))}
    </>
  );
};

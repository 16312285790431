import React from 'react';
import { Button, Box, Divider, Typography } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import { themeCustom } from '../../../theme';
import usePriceFormat from '../../../hooks/usePriceFormat';
// import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { Table } from 'antd';
import { columnsDetails } from '../constants';
import * as Styles from '../style';
import getDetails from '../../../api/services/purchaseOrders/details';

const { colors } = themeCustom;

interface IProps {
  po_number: any;
  setViewModal: any;
}

export const DetailsPurchase = ({ po_number, setViewModal }: IProps) => {
  const { response, isLoading } = getDetails(po_number);

  return (
    <>
      <div id='header' className='p-4' data-testid='test-purchase'>
        <div className='flex justify-between'>
          <p className='font-semibold'>PO Number</p>
          <Button
            sx={{
              textTransform: 'none',
              color: 'black',
              textDecoration: 'underline',
              '&:hover': {
                textDecoration: 'underline',
              },
            }}
            onClick={() => setViewModal(false)}>
            <Box sx={{ color: colors.grayLabels }}>
              <CancelIcon />
              close
            </Box>
          </Button>
        </div>
        <h3 className='text-2xl font-medium'>
          {response?.data?.po?.po_number}
        </h3>
      </div>
      <Divider />
      <Box sx={{ mt: 4, p: 2, display: 'flex' }}>
        <div id='labels' className='space-y-[1.7rem]'>
          <div className='flex'>
            <Typography
              variant='subtitle1'
              component='div'
              sx={{ color: colors.grayLabels, width: '8rem' }}>
              Date
            </Typography>

            <span className='underline'>{response?.data?.po?.date}</span>
          </div>
          <div className='flex'>
            <Typography
              variant='subtitle1'
              component='div'
              sx={{ color: colors.grayLabels, width: '8rem' }}>
              Purchase ID
            </Typography>

            <span className='underline'>{response?.data?.po?.purchase_id}</span>
          </div>
          {/* <div className='flex'>
            <Typography
              variant='subtitle1'
              component='div'
              sx={{ color: colors.grayLabels, width: '8rem' }}>
              Box Count
            </Typography>
          </div> */}
          <div className='flex'>
            <Typography
              variant='subtitle1'
              component='div'
              sx={{ color: colors.grayLabels, width: '8rem' }}>
              Total Value
            </Typography>

            <data value={response?.data?.po?.amount}>
              {usePriceFormat(response?.data?.po?.amount || 0)}
            </data>
          </div>
          <div className='flex'>
            <Typography
              variant='subtitle1'
              component='div'
              sx={{ color: colors.grayLabels, width: '8rem' }}>
              Pay Status
            </Typography>

            <span>{response?.data?.po?.status}</span>
          </div>
        </div>
      </Box>
      <div>
        {response?.data?.boxes.map((box: any, index) => (
          <div onTouchEndCapture={(e) => e.stopPropagation()}>
            <Box
              className='overflow-x-auto h-[30rem]'
              sx={{
                mt: 7,
                p: 4,
                borderRadius: 3,
                border: '1px solid #b2b2b2',
                marginX: 1,
              }}>
              <div className='flex flex-wrap justify-between p-4 pt-0'>
                <h3 className='bold text-xl'>Box # {index + 1}</h3>
                {/* <h5 className='font-semibold text-base'>PO Details</h5> */}
                {/* <Button
              sx={{
                color: colors.grayLabels,
                textDecoration: 'underline',
                textTransform: 'none',
                '&:hover': {
                  textDecoration: 'underline',
                  backgroundColor: 'white',
                },
              }}>
              <FileDownloadOutlinedIcon />
              <span className='ml-2'>Export</span>
            </Button> */}
              </div>
              <div className='flex justify-between w-96 lg:w-3/4 mb-4'>
                <div>
                  <p className='text-slate-400'>Tracking Number</p>
                  <span>{box.tracking_number}</span>
                </div>
                <div>
                  <p className='text-slate-400'>Total Value</p>
                  <data value={box.value}>{box.value}</data>
                </div>
                <div>
                  <p className='text-slate-400'>Date</p>
                  <span>{box.date}</span>
                </div>
                {/* <div>
                  <p className='text-slate-400'>status</p>
                  <span className='text-lime-500'>
                    {response?.data?.po?.status}
                  </span>
                </div> */}
              </div>
              <Divider />
              <Styles.TableContainer>
                <Table
                  className='tableStyles tableHeadtyles tableBodyStyles'
                  loading={isLoading}
                  columns={columnsDetails}
                  pagination={false}
                  dataSource={box.items}
                  // scroll={{ y: 1300 }}
                />
              </Styles.TableContainer>
            </Box>
          </div>
        ))}
      </div>
    </>
  );
};

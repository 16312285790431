import React from 'react';

const CardContent = ({ children, styles, id }: any) => {
  return (
    <div
      className={`bg-white ${styles}`}
      id={id}
      data-testid='mainCardContainer'>
      {children}
    </div>
  );
};

export default CardContent;

import React, { useEffect, useState } from 'react';
import { Button, Collapse, Divider } from '@mui/material';
import { Link } from 'react-router-dom';
import { useCart } from 'react-use-cart';
import { themeCustom } from '../../../theme';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import QuickCommit from './containers/QuickCommit';
import { IAlertType } from '../../../interfaces/activeDeal';
import GeneralAlert from '../generalAlert/GeneralAlert';
import { FileOutlined } from '@ant-design/icons';
import { DrawerComponentUI } from '../sideSheetNew/index';
import { DetailsPurchase } from '../../../modules/purchaseOrders/components/DetailsPurchase';
import { validateExpires } from '../../../helpers';
import { Tag } from 'antd';

const { colors } = themeCustom;

const MobileOptions = ({
  deal,
  isActiveDeals,
  isMyCart,
  isMyCommitmets,
  setViewModal,
}: any) => {
  const { addItem, removeItem } = useCart();
  const [open, setOpen] = React.useState(false);
  const [showAlert, setShowAlert] = useState<IAlertType>({
    message: '',
    show: false,
    title: '',

    type: 'success',
  });

  const [viewModalDetailsPurchaseCommit, setViewModalDetailsPurchaseCommit] =
    useState({ active: false, name: '' });

  const [stores, setStores] = useState<any>([]);

  useEffect(() => {
    if (isMyCommitmets) {
      const storesList: any[] = [];
      deal.variations.map((variation: any) => {
        variation.stores.map((store: any) => {
          storesList.push(store);
        });
      });

      setStores(storesList);
    } else {
      setStores(deal.realStores);
    }
  }, [deal]);

  const handleOpenQuickCommit = () => {
    setOpen(!open);
  };

  const handleRemove = () => {
    removeItem(deal.id);
    setViewModal({ active: false });
    setShowAlert({
      message: 'Item removed from cart',
      show: true,
      title: '',
      type: 'success',
    });
  };

  const transfomImagesActiveDeals = deal?.item?.image?.split('//')[1];
  const transfomImagesCommitments = deal?.image?.split('//')[1];

  return (
    <div data-testid='mobileOptionsView'>
      {showAlert.show && (
        <GeneralAlert
          alertType={showAlert.type}
          alertMessage={showAlert.message}
          alertTitle={showAlert.title}
          setShowAlert={setShowAlert}
          fontSize={17}
        />
      )}
      <header>
        <div className='flex gap-8 pb-4'>
          <h2 className='text-lg fontSharpGrotesk22Medium'>Action Items</h2>
          {/* {deal?.days_to_expire < 0 && (
            <span className='bg-red-500 px-2 pt-[0.1rem] pb-[0.2rem] rounded-lg text-white'>
              Expired
            </span>
          )} */}

          {validateExpires(deal?.days_to_expire) === 1 ? (
            <Tag style={{ borderRadius: 10, fontSize: '10px' }} color='#cd201f'>
              Expired!
            </Tag>
          ) : validateExpires(deal?.days_to_expire) === 0 ? (
            <Tag
              style={{ borderRadius: 10, fontSize: '10px', padding: '7px' }}
              color='#F86238'>
              Expires Soon!
            </Tag>
          ) : (
            ''
          )}
        </div>
        <div className='flex gap-2'>
          <img
            width='80rem'
            className='float-left mr-1'
            src={
              isMyCommitmets
                ? `https://${transfomImagesCommitments}`
                : `https://${transfomImagesActiveDeals}`
            }
            alt='product image'
          />
          <div className='text-xs flex items-center fontGTAmericanR'>
            {isMyCommitmets ? deal.title : deal?.item?.description}
          </div>
        </div>
      </header>
      <Divider
        sx={{ bgcolor: colors.grayLabels, marginY: 2, opacity: '0.5' }}
      />
      <div id='body'>
        <section id='showpNow'>
          <div className='flex gap-4 h-5'>
            <img
              className='ml-1 h-4'
              src='images/activeDeals/arrowIcon.svg'
              alt='quick commit icon'
              width={16}
              height={16}
            />
            <h4 className='-mt-1'>ShopNow</h4>
          </div>
          <div className='flex flex-wrap gap-2 ml-9 mt-2'>
            {stores.map((store: any, index: any) => {
              const transfomImages = store?.icon?.split('//')[1];
              return (
                index < stores.length && (
                  <a
                    key={index}
                    href={store.link}
                    target='_blank'
                    rel='noreferrer'
                    id={'variation-store-' + store.id}>
                    <img
                      src={
                        !store.icon.includes('no-icon.jpg')
                          ? `https://${transfomImages}`
                          : 'images/no-image.png'
                      }
                      className='rounded-md w-8 h-8'
                      alt={store.name}
                    />
                  </a>
                )
              );
            })}
          </div>
        </section>
        <Divider sx={{ bgcolor: colors.grayLabels, marginY: 2 }} />
        <section id='viewItem' className=''>
          <button
            className='my-1 w-full'
            data-testid={'viewItemBtn#' + `${deal.id}`}>
            <Link
              to={`/active-deal/${deal?.id}`}
              className='flex justify-between w-full'
              state={deal}>
              <div className='flex gap-3 items-center'>
                <img
                  className='w-6 h-3'
                  src='images/activeDeals/eyeIcon.svg'
                  alt='eye icon'
                  width='19'
                  height='11'
                />
                <h4>View Item</h4>
              </div>
              <img
                className='h-5 w-3 mr-2'
                src='images/dashboard/arrow.png'
                alt='options icon'
                width='7'
                height='13'
              />
            </Link>
          </button>
        </section>

        <Divider sx={{ bgcolor: colors.grayLabels, marginY: 2 }} />
        {isMyCommitmets && (
          <>
            {deal.po_number.length && (
              <div>
                <button
                  className='flex cursor-pointer w-full justify-between'
                  onClick={() => {
                    setViewModalDetailsPurchaseCommit({
                      active: true,
                      name: '',
                    });
                  }}>
                  <div className='flex gap-3'>
                    <FileOutlined style={{ fontSize: '1rem' }} />
                    <h4 className='ml-1'>View Purchase orders</h4>
                  </div>
                  <img
                    className='h-5 w-3 mr-2'
                    src='images/dashboard/arrow.png'
                    alt='options icon'
                    width='7'
                    height='13'
                  />
                </button>
                {viewModalDetailsPurchaseCommit.active && (
                  <DrawerComponentUI
                    anchor={'right'}
                    viewRender={
                      <DetailsPurchase
                        po_number={deal.po_number[0]}
                        setViewModal={setViewModalDetailsPurchaseCommit}
                      />
                    }
                    closeModal={setViewModalDetailsPurchaseCommit}
                  />
                )}
              </div>
            )}
          </>
        )}
        {isActiveDeals && (
          <>
            <Button
              data-testid='addDealsToCart'
              className='disabled:opacity-40'
              disabled={
                deal?.enabled ||
                deal?.is_fully_committed ||
                deal?.days_to_expire < 0
              }
              variant='outlined'
              size='large'
              onClick={() => {
                addItem(deal);
              }}
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                border: '0',
                borderRadius: 3,
                width: '100%',
                color: 'black',
                textTransform: 'none',
                padding: '6px 0px',
                '&:hover': {
                  backgroundColor: 'white',
                  borderColor: 'white',
                  border: 0,
                  boxShadow: 'none',
                  color: 'black',
                },
                '&:disabled': {
                  border: 'none',
                },
              }}>
              <div className='flex gap-4 items-center'>
                <img
                  className='h-5 w-5'
                  src='images/activeDeals/blackShoppingCartIcon.svg'
                  alt='options icon'
                  width={19}
                  height={15}
                />
                <h4>Add to Deals Cart</h4>
              </div>

              <img
                className='h-5 w-3 mr-2'
                src='images/dashboard/arrow.png'
                alt='options icon'
                width='7'
                height='13'
              />
            </Button>
            <Divider sx={{ bgcolor: colors.grayLabels, marginY: 2 }} />
            <section id='quickCommit' className='mt-1'>
              {deal?.enabled ||
              deal?.is_fully_committed ||
              deal?.days_to_expire < 0 ? (
                <p className='text-xs flex justify-start cursor-default items-center'>
                  <img
                    className='ml-1'
                    src='images/activeDeals/blockedDealIcon.svg'
                    alt='blocked deal icon'
                    width='16'
                    height='22'
                  />
                  <span className='ml-5 opacity-70'>
                    {(deal?.is_fully_committed || deal?.locked) &&
                      'No More Commitments Allowed'}
                    {deal?.enabled &&
                      !deal?.is_fully_committed &&
                      "You've already committed to this deal"}
                    {deal.days_to_expire < 0 &&
                      !deal?.locked &&
                      !deal?.is_fully_committed &&
                      'Deal Expired'}
                  </span>
                </p>
              ) : (
                <button
                  disabled={
                    deal?.enabled ||
                    deal?.is_fully_committed ||
                    deal?.days_to_expire < 0
                  }
                  data-testid={'quickCommitButton#' + `${deal.id}`}
                  className='w-full flex justify-between disabled:opacity-40'
                  onClick={handleOpenQuickCommit}>
                  <div className='flex items-center'>
                    <img
                      src='images/activeDeals/quickCommitBlackIcon.png'
                      alt='quick commit icon'
                      width='17'
                      height='16'
                    />
                    <h4 className='ml-5'>Quick Commit</h4>
                  </div>
                  <div>{open ? <ExpandLess /> : <ExpandMore />}</div>
                </button>
              )}
              <Collapse in={open} timeout='auto' unmountOnExit>
                <QuickCommit
                  deal={deal}
                  setShowAlert={setShowAlert}
                  setViewModal={setViewModal}
                />
              </Collapse>
            </section>
          </>
        )}

        {isMyCart && (
          <section id='viewItem' className=''>
            <button className='my-1 w-full' onClick={() => handleRemove()}>
              <div className='flex justify-between w-full'>
                <div className='flex gap-3 items-center'>
                  <img
                    className='w-4'
                    src='images/trackingNumbers/deleteIcon.png'
                    alt='eye icon'
                    width='19'
                    height='11'
                  />
                  Remove
                </div>
                <img
                  className='h-5 w-3 mr-2'
                  src='images/dashboard/arrow.png'
                  alt='options icon'
                  width='7'
                  height='13'
                />
              </div>
            </button>
          </section>
        )}
      </div>
    </div>
  );
};

export default MobileOptions;

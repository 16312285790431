import { IPaymentRequest, IData } from '../../../interfaces/paymentRequests';

export interface IServiceTransform {
  data: IData[];
  meta: {
    status: boolean;
    totals: number;
  };
}

const transformDataPaymentRequest = (
  data: IPaymentRequest,
): IServiceTransform => {
  const dataTransformed = data.data;
  const metaTranform = {
    status: data.status,
    totals: data?.totals?.items,
  };

  return { data: dataTransformed, meta: metaTranform };
};

export default transformDataPaymentRequest;

import React from 'react';
import { Drawer } from '@mui/material';
import { TemplateSideSheet } from './containers/TemplateSideSheet';

type Anchor = 'bottom' | 'right';

export const DrawerComponentUI = ({
  anchor,
  modalBorderTopRightRadius,
  closeModal,
  viewRender,
  width,
}: any) => {
  const [state, setState] = React.useState<any>({
    bottom: true,
    right: true,
  });

  const [positionTouch, setPositionTouch] = React.useState<any>(0);

  const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
    (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === 'keydown' &&
        ((event as React.KeyboardEvent).key === 'Tab' ||
          (event as React.KeyboardEvent).key === 'Shift')
      ) {
        return;
      }

      setState({ ...state, [anchor]: open });
    };

  const closeDrawer = () => {
    toggleDrawer(anchor, false);
    closeModal({ active: false });
  };

  const closeMove = (e: any) => {
    setTimeout(() => {
      if (positionTouch !== 0 && e.changedTouches[0].clientY > positionTouch) {
        closeDrawer();
        setPositionTouch(0);
      }
    }, 500);
  };

  return (
    <Drawer
      onTouchStart={(e) => setPositionTouch(e.changedTouches[0].clientY)}
      onTouchEnd={(e) => {
        closeMove(e);
      }}
      PaperProps={{
        elevation: 0,
        style: {
          backgroundColor: 'transparent',
          transition: 'all 5s ease-in-out',
        },
      }}
      anchor={anchor}
      open={state[anchor]}
      onClose={closeDrawer}>
      <TemplateSideSheet
        anchor={anchor}
        modalBorderTopRightRadius={modalBorderTopRightRadius}
        componentRender={viewRender}
        toggleDrawer={toggleDrawer}
        width={width}
      />
    </Drawer>
  );
};

import React from 'react';
import { dataTableFields } from '../constants';
import { Table } from 'antd';
// import { PaginationProps } from 'antd/es/pagination';

const List = ({
  deals,
  loading,
  isDashboard,
}: // current,
// totalItems,
// sizeItemsPage,
// setSizeItemPage,
// setCurrent,
any) => {
  // const onShowSizeChange: PaginationProps['onShowSizeChange'] = (
  //   current,
  //   pageSize,
  // ) => {
  //   setSizeItemPage(pageSize);
  //   setCurrent(1);
  // };

  // const onChange: PaginationProps['onChange'] = (page) => {
  //   setCurrent(page);
  // };

  return (
    <div style={{ flex: 1, overflowY: 'auto' }}>
      <Table
        columns={dataTableFields}
        rowClassName={(data): any =>
          (data.enabled || data.locked || data.days_to_expire < 0) &&
          'disabled-row'
        }
        dataSource={deals}
        scroll={{ x: 1200 }}
        loading={loading}
        pagination={false}
        className='xl1:h-[calc(100vh-400px)] xlc:h-[calc(100vh-400px)] xl2:h-[calc(100vh-410px)]'
        style={{
          minHeight: '350px'
        }}
        title={(): any =>
          isDashboard && (
            <h2 className='fontSharpGrotesk22Medium ml-8 pt-6 pb-0 mb-0 text-lg'>
              Top Deals
            </h2>
          )
        }
      />
      {/* <div className='mt-4 mb-4 justify-end w-full flex'>
        <Pagination
          current={current}
          pageSize={sizeItemsPage}
          showSizeChanger
          onShowSizeChange={onShowSizeChange}
          defaultCurrent={1}
          total={totalItems}
          onChange={onChange}
        />
      </div> */}
    </div>
  );
};

export default List;
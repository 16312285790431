import React from 'react';
// import { Navigate } from 'react-router-dom';
import useStatesGlobalStore from '../hooks/useStatesGlobalStore';

const RequiredAuth = ({ children }: any) => {
  const { authInfo } = useStatesGlobalStore();

  if (authInfo.state === 'sessionDenied' || authInfo.length === 0) {
    const redirect =  encodeURIComponent(window.location.href)
    window.location.href = `${process.env.REACT_APP_URL_NEW_BUYERS}?redirect=${redirect}`
    return ;
  }

  return <>{children}</>;
};

export default RequiredAuth;

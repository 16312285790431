export const themeCustom = {
  colors: {
    blue: '#6fb8ff',
    blueLabels: '#2f95fc',
    lightBlue: '#bedfff',
    lightOrange: '#fed5c9',
    orange: '#fd9c81',
    dullGreen: '#d9ecff',
    dullBlue: '#d3ffe3',
    dullOrange: '#ffd5c9',
    dullGray: '#f2f2f5',
    lightGray:"#fcfcfc",
    lightBlueBorder: '#4da4fc',
    white: '#fff',
    black: '#000',
    grayLabels: '#b2b2b2',
    grayInputs: '#fcfcfc',
  },
};

import { IStatus } from '../../../interfaces/paymentRequests';
import RenderActionsPayeesNamesTable from '../components/RenderActionsPayeesNamesTable';
import { PlusCircleOutlined } from '@ant-design/icons';

export const addressForm = [
  {
    label: 'Address 1',
    name: 'address_1',
    type: 'text',
    width: 650,
    widthLaptops: 400,
    widthMobile: 290,
    mr: 9,
    unlocked: true,
  },
  {
    label: 'Address 2',
    name: 'address_2',
    type: 'text',
    width: 650,
    widthLaptops: 400,
    widthMobile: 290,
    mr: 9,
    unlocked: true,
  },
  {
    label: 'City',
    name: 'city',
    type: 'text',
    width: 290,
    widthLaptops: 290,
    widthMobile: 290,
    mr: 9,
    unlocked: true,
  },
  {
    autoCompleteIndicator: 'country',
    label: 'Country',
    name: 'country',
    type: 'text',
    width: 290,
    widthLaptops: 290,
    widthMobile: 290,
    mr: 9,
    unlocked: true,
  },
  {
    label: 'State',
    name: 'state',
    type: 'text',
    width: 290,
    widthLaptops: 290,
    widthMobile: 290,
    mr: 9,
    unlocked: true,
  },
  {
    label: 'Zip Code',
    name: 'zip_code',
    type: 'number',
    width: 290,
    widthLaptops: 290,
    widthMobile: 290,
    mr: 9,
    unlocked: true,
  },
];

export const changePasswordForm = [
  {
    label: 'Type your current password',
    name: 'current_password',
    autoCompleteIndicator: 'off',
    type: 'password',
    testID: 'currentPassword',
    width: 470,
    widthLaptops: 290,
    widthMobile: 290,
    mr: 9,
  },
  {
    label: 'Type your new password',
    ariaDescribed: 'passwordHint',
    autoCompleteIndicator: 'off',
    minLength: 8,
    maxLength: 10,
    minLengthMessage: 'Min length is 8',
    maxLengthMessage: 'Max length is 10',
    name: 'password',
    pattern: /^(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[#$@!%&*?]).{8,}$/,
    type: 'password',
    testID: 'newPasswordTest',
    width: 470,
    widthLaptops: 290,
    widthMobile: 290,
    mr: 9,
  },
  {
    ariaDescribed: 'passwordHint',
    autoCompleteIndicator: 'off',
    label: 'Retype your new password',
    minLength: 8,
    maxLength: 10,
    minLengthMessage: 'Min length is 8',
    maxLengthMessage: 'Max length is 10',
    name: 'confirm_password',
    pattern: /^(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[#$@!%&*?]).{8,}$/,
    type: 'password',
    testID: 'confirmNewPassword',
    width: 470,
    widthLaptops: 290,
    widthMobile: 290,
    mr: 9,
  },
];

export const addACHAccountForm = [
  {
    label: 'Account Holder Name',
    maxLength: 22,
    maxLengthMessage: 'Max length is 22',
    name: 'account_name',
    placeholder: 'Name',
    testID: 'account_name_test_id',
    type: 'text',
    width: 650,
    widthLaptops: 290,
    widthMobile: 290,
  },
  {
    label: 'Routing Number',
    name: 'routing_number',
    placeholder: '0',
    testID: 'routing_number',
    type: 'number',
    width: 650,
    widthLaptops: 290,
    widthMobile: 290,
  },
  {
    label: 'Bank Account Number',
    name: 'account_number',
    testID: 'account_number',
    placeholder: '0',
    type: 'number',
    width: 290,
    widthLaptops: 290,
    // mr: 9,
  },
  {
    label: 'Bank Account Type',
    name: 'accountType',
    disabled: true,
    placeholder: '0',
    type: 'text',
    width: 290,
    widthLaptops: 290,
  },
];

export const updateACHAccountForm = [
  {
    label: 'Account Holder Name',
    autoCompleteIndicator: 'off',
    maxLength: 22,
    maxLengthMessage: 'Max length is 22',
    name: 'account_name',
    placeholder: 'Name',
    testID: 'account_name_test_id_update',
    type: 'text',
    width: 550,
    widthLaptops: 290,
    widthMobile: 290,
  },
  {
    label: 'Routing Number',
    autoCompleteIndicator: 'off',
    name: 'routing_number',
    placeholder: '0',
    testID: 'routing_number',
    type: 'number',
    width: 550,
    widthLaptops: 290,
    widthMobile: 290,
  },
  {
    label: 'Bank Account Number',
    autoCompleteIndicator: 'off',
    name: 'account_number',
    testID: 'account_number',
    placeholder: '0',
    type: 'number',
    width: 550,
    widthLaptops: 290,
    widthMobile: 290,
  },
  {
    label: 'Bank Account Type',
    name: 'accountType',
    disabled: true,
    placeholder: '0',
    type: 'text',
    width: 550,
    widthLaptops: 290,
    widthMobile: 290,
  },
];

export const addPayeeNameForm = [
  {
    label: 'Payee Name',
    name: 'name',
    testID: 'namePayeeName',
    width: 650,
    widthLaptops: 290,
    widthMobile: 290,
    placeholder: 'Name',
  },
];

export const updatePayeeNameForm = [
  {
    label: 'Payee Name',
    name: 'name',
    testID: 'nameUpdate',
    width: 550,
    widthLaptops: 290,
    widthMobile: 290,
    placeholder: 'Name',
  },
];

export const links = [
  { link: 'myInfo', label: 'My Info' },
  { link: 'myAddress', label: 'Address' },
  { link: 'payments', label: 'Payments' },
  { link: 'changePassword', label: 'Change Password' },
  { link: 'personalReferralLink', label: 'Referrals' },
];

export const paymentMethods = [
  {
    divider: true,
    label: 'Add an ACH Account',
    name: 'addACHAccount',
    testID: 'addAchAccountButton',
    icon: <PlusCircleOutlined style={{ fontSize: '24px' }} />,
  },
  {
    divider: false,
    label: 'Add a Payee Name',
    name: 'addPayeeName',
    testID: 'payeeNametestID',
    icon: (
      <img
        src='./images/sideBar/account.png'
        alt='icon'
        width={23}
        height={18}
      />
    ),
  },
];

export const achAccountsSearchFilter = [
  {
    name: 'search',
    placeHolder: 'Account Name, Routing Number, Account Number',
    stylesSecondContainer: 'mr-24',
    width: 310,
  },
];

export const payeeNamesSearchFilter = [
  {
    name: 'name',
    placeHolder: 'Payee Name',
    stylesSecondContainer: 'block',
    width: 300,
  },
];

export const selectFilterPayeeName = [
  {
    name: 'status',
    selectWidth: 230,
    stylesFirstContainer: () => 'mt-4',
    stylesSecondContainer: 'flex items-center mr-7 mb-8 lg:mb-0',
    title: 'Status',
    menuItems: [
      {
        value: '',
        labelName: 'All',
      },
      {
        value: 1,
        labelName: 'Approved',
      },
      {
        value: 2,
        labelName: 'Denied',
      },
      {
        value: 3,
        labelName: 'Pending',
      },
    ],
  },
];

export const stylesSearchFilter = 'lg:flex justify-end mt-2';

export const payeeNamesTableColumns: any = (setShowAlert: any) => {
  return [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      fixed: 'left',
    },
    {
      title: 'Default',
      dataIndex: 'default',
      key: 'default',
      render: (value: any) => (
        <div className='h-4 w-4' data-testid='renderImgDefault'>
          {value === '1' ? (
            <img
              alt='check icon'
              src='https://img.icons8.com/emoji/48/000000/check-mark-emoji.png'
            />
          ) : (
            <img
              alt='x icon'
              src='https://img.icons8.com/ios-filled/50/000000/x.png'
            />
          )}
        </div>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (status: IStatus) => <div>{status.name}</div>,
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (item: any) => (
        <RenderActionsPayeesNamesTable
          payeeNameInfo={item}
          setShowAlert={setShowAlert}
        />
      ),
    },
  ];
};
